import React, { Fragment, useState } from 'react';
import { SEARCH_SCHOOLS, ADD_STUDENTS_FROM_CLASSES_TO_ANOTHER_CLASS } from '../../services/graphql';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Search } from 'react-feather';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { GET_SCHOOL_QUERY } from '../../../../../../../../services/school/graphql';

import cx from 'classnames';
import styles from './AssignToEvent.module.scss';
import ListItem
    from '../../../../../../../../components/ListItem';

const AssignToEvent = ({ classData, closeAssign, school, setClassesMovedToEvent }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [formErrors, setFormErrors] = useState([]);

    const [searchSchools] = useLazyQuery(SEARCH_SCHOOLS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.schoolsSearch) {
                setResults(data.schoolsSearch);
            }
        }
    });

    const search = (e) => {
        e.preventDefault();
        searchSchools({ variables: { text: searchTerm } })
    }

    const [addStudentsFromClassToAnotherClass, { loading }] = useMutation(ADD_STUDENTS_FROM_CLASSES_TO_ANOTHER_CLASS, {
        update: (_, { data }) => {
            if (data.addStudentsFromClassesToAnotherClass.errors && data.addStudentsFromClassesToAnotherClass.errors.length) {
                setFormErrors(data.addStudentsFromClassesToAnotherClass.errors);
                return
            }
            if (data.addStudentsFromClassesToAnotherClass.targetStudentsClass && data.addStudentsFromClassesToAnotherClass.targetStudentsClass.id) {
                closeAssign();
                setClassesMovedToEvent(classData);
            }
        },
        refetchQueries: [{ query: GET_SCHOOL_QUERY, variables: { schoolId: school.id } }],
        awaitRefetchQueries: true
    })

    const onSelectClass = e => {
        if (selectedClasses.indexOf(e.target.value) !== -1) {
            setSelectedClasses(selectedClasses.filter(s => s !== e.target.value));
        } else {
            setSelectedClasses([...selectedClasses, e.target.value]);
        }
    }


    if (selectedSchool) {
        return (
            <div className='u-m-base-3'>
                <h3 className='u-m-base-3'> 2/2: Select classes </h3>
                <div className={styles.school}>
                    <div>
                        <h3>{selectedSchool.name}</h3>
                        <h4>{selectedSchool.address}</h4>
                    </div>
                    <Button
                        outline
                        className='u-m-left-auto'
                        onClick={() => {
                            setSelectedSchool(null);
                            setSearchTerm('');
                            setResults([]);
                        }}
                    >Change</Button>
                </div>
                <div className={cx('u-display-flex u-m-base-2', styles.klsWrapper)}>
                    {
                        selectedSchool.classes && selectedSchool.classes.sort((a, b) => a.name.localeCompare(b.name)).map(kls => {
                            return (
                                <div key={`cls-${kls.id}`} className={styles.kls}>
                                    <div className='basic-form__group basic-form__group--check u-m-base-1'>
                                        <input
                                            type='checkbox'
                                            className="basic-form__check-box"
                                            value={kls.id}
                                            onChange={(e) => onSelectClass(e)}
                                            checked={selectedClasses.indexOf(kls.id) > -1}
                                            id={`kls_${kls.id}`}
                                        />
                                        <label className="basic-form__check-label" htmlFor={`kls_${kls.id}`}><h4><b>{kls.name}</b> ({kls.description})</h4></label>
                                    </div>
                                    <p className='u-m-left-3 u-p-left-1'>
                                        {kls.teachers.slice(0, 2).map(t => t.displayName).join(', ')}
                                        {kls.teachers.length > 2 && `, +${kls.teachers.length - 2} more`}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
                {formErrors && <FormErrors errors={formErrors} />}
                <Button
                    disabled={selectedClasses.length === 0}
                    onClick={() => addStudentsFromClassToAnotherClass({
                        variables: {
                            studentsClassesIds: selectedClasses,
                            targetStudentsClassId: classData.id
                        }
                    })}
                >{loading ? 'Adding...' : 'Add selected classes'}</Button>
            </div>
        );
    }

    return (
        <Fragment>
            <h3 className='u-m-base-3'> 1/2: Add pupils from another school to {classData.name} </h3>
            <div className={styles.form}>
                <h3 className='u-m-base-2'>Find school</h3>
                <form className={styles.formSearch} onSubmit={search}>
                    <input
                        name='search'
                        className='basic-form__text-box'
                        type='text'
                        placeholder='Type to search by school name'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className={styles.formSearchBtn}><Search size='18' /></button>
                </form>
                {results.length > 0 && (
                    <ul>
                        {results.map(result => (
                            <ListItem
                                short
                                key={`result-${result.id}`}
                                subText={`${result.name} (${result.city})`}
                                onAdd={() => setSelectedSchool(result)}
                            />
                        ))}
                    </ul>
                )}
            </div>
        </Fragment>
    );
}

export default AssignToEvent;
