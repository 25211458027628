import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from '@reach/router';
import { useMutation} from '@apollo/react-hooks';
import * as Yup from 'yup';

import { Button, FormErrors } from '@axeedge/go-teacher-components';

import { GET_BOOK_TYPES_QUERY } from '../../../../services/pack/graphql';
import { ADD_BOOK_TYPE_MUTATION } from '../../services/graphql';

const AddPack = () => {
    const [formErrors, setFormErrors] = useState([]);
    const schema = Yup.object().shape({
        name: Yup.string().required('Please enter number a pack name'),
        description: Yup.string().required('Please enter a pack description')
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: 'onBlur'
    });

    const [addPack, { loading: addingPack }] = useMutation(ADD_BOOK_TYPE_MUTATION, {
        refetchQueries: [{ query: GET_BOOK_TYPES_QUERY }],
        onCompleted: ({ addBookTemplateCategory }) => {
            if (addBookTemplateCategory.errors && addBookTemplateCategory.errors.length !== 0) {
                setFormErrors(addBookTemplateCategory.errors);
                return;
            } else if (addBookTemplateCategory.bookTemplateCategory.id) {
                navigate(`/pack/${addBookTemplateCategory.bookTemplateCategory.id}`);
            }
        }
    });

    const onSubmit = values => {
        const { name, description, isSingleChapter } = values;
        
        addPack({
            variables: {
                name,
                description,
                isSingleChapter
            }
        });
    }

    return (
        <Fragment>
            <h1 className='title u-m-top-2 u-m-base-2'>Add New Book Type</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    <input
                        name='name'
                        placeholder='Name'
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                    
                </div>
                {errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
                <div className='basic-form__group'>
                    <textarea
                        name='description'
                        placeholder='Description'
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                    
                </div>
                {errors.description && <p className='basic-form__hint'>{errors.description.message}</p>}
                <div className='basic-form__group basic-form__group--check'>
                    <input
                        name='isSingleChapter'
                        className='basic-form__check-box'
                        ref={register}
                        type='checkbox'
                    />
                    <label className='basic-form__check-label' htmlFor='isSingleChapter'>Single Chapter (e.g. for Anthology books)</label>
                </div>
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className='u-display-flex u-align-center'>
                    <Button type='submit' className='u-m-right-1'>{addingPack ? 'Saving...' : 'Add Book type'}</Button>
                    <Button type='button' outline onClick={() => navigate('/')}>Cancel</Button>
                </div>
            </form>
        </Fragment>
    );
}

export default AddPack;
