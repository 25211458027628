import React from 'react';
import { Link } from '@reach/router';
import { ArrowLeft } from 'react-feather';

import styles from './BackLink.module.scss';

const BackLink = ({ text, to }) => {
    return (
        <Link 
            to={to}
            className={styles.backLink}
        >
            <ArrowLeft className={styles.backLinkIcon} />{text}
        </Link>
    )
}

export default BackLink;
