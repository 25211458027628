import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import { Button, Modal } from '@axeedge/go-teacher-components';
import ClassItem from './components/ClassItem';
import AddClass from './components/AddClass';
import AssignToEvent from './components/AssignToEvent';
import Tabs from '../../../../../../components/Tabs';
import Tab from '../../../../../../components/Tab';
import schoolStyles from '../../../SchoolHome/SchoolHome.module.scss';
import ArchivedClasses from './ArchivedClasses';

const TABS = {
    active: 1,
    archived: 2
}

const Classes = ({ school }) => {

    const [showAddClassModal, setShowAddClassModal] = useState(false);
    const [showAssignToEvent, setShowAssignToEvent] = useState(null);
    const [classesMovedToEvent, setClassesMovedToEvent] = useState(null);

    const [selectedTab, setSelectedTab] = useState(TABS.active);

    if (showAssignToEvent) {
        return <AssignToEvent setClassesMovedToEvent={setClassesMovedToEvent} closeAssign={() => setShowAssignToEvent(null)} school={school} classData={showAssignToEvent} />
    }

    return (
        <Fragment>
            <Fragment>
                <div className={schoolStyles.subViewTopBar}>
                    <Tabs className='u-m-top-2'>
                        <Tab selected={selectedTab === TABS.active} onClick={() => setSelectedTab(TABS.active)}>Active Classes</Tab>
                        <Tab selected={selectedTab === TABS.archived} onClick={() => setSelectedTab(TABS.archived)}>Archived Classes</Tab>
                    </Tabs>
                    {/* <Button onClick={() => setShowAddClassModal(true)} primary>Create Class</Button> */}
                </div>
                {selectedTab === TABS.active ?
                    <>
                        {school.classes && school.classes.length > 0 ? (
                            <>
                                {_.sortBy(school.classes, c => { return c.name.toLowerCase() }).map(studentsClass => {
                                    return <ClassItem
                                        classesMovedToEvent={classesMovedToEvent}
                                        setClassesMovedToEvent={setClassesMovedToEvent}
                                        setShowAssignToEvent={setShowAssignToEvent}
                                        key={`class-${studentsClass.id}`}
                                        classData={studentsClass}
                                        school={school}
                                        teachers={school.teachers}
                                    />
                                })}
                            </>
                        ) : <p>No classes</p>}
                    </> : <ArchivedClasses school={school} />
                }

            </Fragment>
            {
                showAddClassModal && (
                    <Modal closeModal={() => setShowAddClassModal(false)}>
                        <AddClass schoolId={school.id} closeModal={() => setShowAddClassModal(false)} />
                    </Modal>
                )
            }
        </Fragment>
    );
}

export default Classes;
