
import { gql } from 'apollo-boost';

export const GET_STATS_QUERY = gql`
query bookStats($bookTemplateCategoryId: ID) {
    bookStats(bookTemplateCategoryId: $bookTemplateCategoryId) {
        id
        key
        value
    }
}
`;

export const GET_BOOKS_QUERY = gql`
query bookList($search: String!, $status: Int, $bookTemplateCategoryId: ID, $page: Int ) {
    bookList(search: $search, status: $status, bookTemplateCategoryId: $bookTemplateCategoryId, page: $page) {
        items {
            id
            bookCode
            title
            status
            bookTemplate {
                id
                name
            }
            orderStats {
                id
                classOrder
                parentOrders
            }
            studentsClass {
                id
                name
                school {
                    id
                    name

                }
                teachers {
                    id
                    name
                    displayName
                }
            }
        }
        total
    }
}
`;