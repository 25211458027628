
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useEffectOnce } from 'react-use';

import { Loader } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';

import { AuthContext } from '../../../services/user/AuthProvider';
import { LOGGED_IN } from '../services/graphql';
import Ssl from './Ssl';
import { Router } from '@reach/router';


import Dashboard from '../../Dashboard';
import Schools from '../../Schools';
import Trials from '../../Trials';
import SchoolHome from '../../School/scenes/SchoolHome';
import AddSchool from '../../School/scenes/AddSchool';
import EditSchool from '../../School/scenes/EditSchool';

import InvoicesHome from '../../Invoices/scenes/InvoicesHome';
import InvoicesDashboard from '../../Invoices/scenes/InvoicesDashboard';

import ViewInvoice from '../../Invoices/scenes/ViewInvoice';
import OrdersHome from '../../Orders/scenes/OrdersHome';
import ViewOrder from '../../Orders/scenes/ViewOrder';

import PacksHome from '../../Packs/scenes/PacksHome';
import PackHome from '../../Pack/scenes/PackHome';
import AddPack from '../../Pack/scenes/AddPack';
import Book from '../../Book';
import Books from '../../Books';
import FindParent from '../../FindParent';
import WriteToRaise from '../../WriteToRaise';
import WtrListSchools from '../../WriteToRaise/WtrListSchools';
import Users from '../../Users';

import SetPassword from '../../Login/scenes/SetPassword';
import ResetSent from '../../Login/scenes/ResetSent';
import Login from '../../Login';
import LoggedInRouter from './LoggedInRouter';
import Header from '../../../components/Header';
import styles from '../Home.module.scss';
import { APP_NAME } from '../../../services/constants';
import RouteCapture from './RouteCapture';
import WritingBees from '../../WritingBees';
import ManageBee from '../../WritingBees/ManageBee';
import InvoicesBySource from '../../Invoices/scenes/InvoicesBySource';
import WondeSchools from '../../Schools/WondeSchools';

const RouterAuthQuery = () => {
    const { data, error, loading } = useQuery(LOGGED_IN);
    const auth = useContext(AuthContext);

    const [ready, setReady] = useState(false);


    useEffect(() => {
        if (ls(getAppTokenName(APP_NAME)) && !auth.currentUser && ls('go_educator_user') && ls('go_educator_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('go_educator_user')));
        }
    }, [auth]);

    useEffectOnce(() => {
        if (ls(getAppTokenName(APP_NAME)) && !auth.currentUser && ls('go_educator_user') && ls('go_educator_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('go_educator_user')));
        }
        setReady(true);
    });

    const renderContent = () => {
        if (error) {
            return <p>{error.message}</p>
        }

        if (loading) {
            return <Loader />
        }

        if (data && ready) {
            return (
                <Router>
                    <RouteCapture path='/'>
                        {auth.currentUser ? (
                            <LoggedInRouter path='/' default>
                                <Dashboard path='/' default />

                                <SchoolHome path='/school/:schoolId' />
                                <EditSchool path='/school/:schoolId/edit' />
                                <AddSchool path='/school/new/india' india={true} />
                                <ManageBee path='/writingBees/' />

                                {auth.currentUser.countryAdmin !== 'IND' && (
                                    <>
                                        <AddSchool path='/school/new' />
                                        <PacksHome path='/packs' />
                                        <Book path='/book/:bookTemplateCategoryId/:bookId' />
                                        <Books path='/books/' />
                                        <AddPack path='/pack/new' />
                                        <PackHome path='/pack/:bookTemplateCategoryId' />
                                        <Schools path='/schools' />
                                        <WondeSchools path='/schools/wonde' />

                                        <WriteToRaise path='/writeToRaise' />
                                        <WtrListSchools path='/wtrListSchools' />
                                        <Users path='/users' />
                                        <Trials path='/trials' />
                                        <WritingBees path='/writingBees' />
                                        <InvoicesHome path='/invoicesOld' />
                                        <ViewInvoice path='/invoices/:invoiceId' />

                                        <InvoicesDashboard path='/invoices' />
                                        <InvoicesBySource path='/invoicesBySource/:source' />

                                        <OrdersHome path='/orders' />
                                        <ViewOrder path='/orders/:orderId' />
                                        <FindParent path='/findParent' />
                                    </>
                                )}
                               
                            </LoggedInRouter>
                        ) :
                            <Fragment>
                                <Login path='/' default />
                                <SetPassword path='/setpassword/:token/*' />
                                <SetPassword path='/invite/:token' />
                                <ResetSent path='/reset_sent' />
                            </Fragment>
                        }
                    </RouteCapture>
                </Router>
            )
        }
    }

    return (
        <Fragment>
            <Ssl />
            <Header user={auth.currentUser} />
            <div className={styles.appContainer} >
                {renderContent()}
            </div>
        </Fragment>
    );
}

export default RouterAuthQuery;