import React, { Fragment, useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import * as Yup from 'yup';
import ls from 'local-storage';
import { navigate } from '@reach/router';

import { Button, Modal } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';

import { UPDATE_PASSWORD_MUTATION } from '../services/graphql';
import ForgotPassword from './ForgotPassword';
import { AuthContext } from '../../../services/user/AuthProvider';

import { APP_NAME } from '../../../services/constants';

const SetPassword = ({ token, '*': reset }) => {

    const [modalForgotPass, setModalForgotPass] = useState(false);
    const { setCurrentUser } = useContext(AuthContext);
    const client = useApolloClient();

    const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD_MUTATION, {
        onCompleted: data => {
            if (data.updatePassword.token) {
                ls(getAppTokenName(APP_NAME), data.updatePassword.token);
                ls('go_educator_user', JSON.stringify(data.updatePassword.user));
                setCurrentUser({
                    ...data.updatePassword.user,
                    reset: true
                });
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            }
            if (data.updatePassword.errors && data.updatePassword.errors.length !== 0) {
                setModalForgotPass(data.updatePassword.errors);
            }
        }
    });

    const yupTest = () => {
        let pass = false;
        if (Yup) {
            if (Yup.object) {
                if (Yup.object().shape) {
                    if (Yup.string) {
                        if (Yup.string().required) {
                            if (Yup.string().oneOf) {
                                if (Yup.ref) {
                                    pass = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return pass;
    }

    const schema = yupTest() ? Yup.object().shape({
        password: Yup.string()
            .required('Please enter password'),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    }) : null;

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { password, passwordConfirmation } = values;
        updatePassword({
            variables: {
                token,
                password,
                passwordConfirmation
            }
        })
    };

    return (
        <div className='login'>
            <div className='u-m-top-3'>
                <h2 className='u-m-base-3 u-text-center'>
                    {!!reset ? 'Reset your password' : 'Account activation:  Set your password'}
                </h2>
                <p className='u-m-base-3 u-text-center'>{!!reset ? 'To reset your password and login to your account please complete the form below' : 'To complete your activation, please set the password for your new account'}:</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="basic-form__group">
                        <input
                            name="password"
                            placeholder={!!reset ? 'New password' : 'Password'}
                            className="basic-form__text-box"
                            type="password"
                            ref={register}
                        />
                    </div>
                    {errors.password && <p className="basic-form__hint">{errors.password.message}</p>}
                    <div className="basic-form__group">
                        <input
                            name="passwordConfirmation"
                            placeholder={!!reset ? 'Confirm new password' : 'Confirm password'}
                            className="basic-form__text-box"
                            type="password"
                            ref={register}
                        />
                    </div>
                    {errors.passwordConfirmation && <p className="basic-form__hint">{errors.passwordConfirmation.message}</p>}
                    <div className='u-display-flex u-justify-center'>
                        <Button type="submit">{loading ? 'Saving' : 'Set password and login'}</Button>
                    </div>
                </form>
            </div>
            {
                modalForgotPass && (
                    <Modal closeModal={() => setModalForgotPass(false)}>
                        <ForgotPassword invalid_token={modalForgotPass} closeModal={() => setModalForgotPass(false)} />
                    </Modal>
                )
            }
        </div>
    )
}

export default SetPassword;
