import React, { useState, useEffect} from "react";
import { CheckCircle, Users } from "react-feather";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { GET_SCHOOL_QUERY, ADD_TO_BEE, GET_CONTEST } from "./services/graphql";
import { GET_SCHOOL_QUERY as MAIN_SCHOOL_QUERY } from "../../services/school/graphql";
import { Loader, Button } from "@axeedge/go-teacher-components";
import cx from 'classnames';
import styles from './WritingBee.module.scss';

const AddToBee = ({ school, close }) => {

    const [selectedClass, setSelectedClass] = useState(null);
    const [maxStudents, setMaxStudents] = useState(0);
    const [formErrors, setFormErrors] = useState([])
    const { data, loading, error } = useQuery(GET_SCHOOL_QUERY, {
        variables: {
            schoolId: school.id
        }
    });

    const [getContest, { data: contestData, loading: contestLoading, error: contestError }] = useLazyQuery(GET_CONTEST, {
        fetchPolicy: 'network-only',
    });

    const [addClassToBee, { loading: addingSchool, error: addingError }] = useMutation(ADD_TO_BEE, {
        awaitRefetchQueries: true,
        variables: {
            schoolId: school.id,
            studentsClassId: selectedClass,
            contestId: contestData && contestData.activeContest.id,
            studentsCounter: maxStudents
        },
        refetchQueries: [{ 
            query: MAIN_SCHOOL_QUERY,
            variables: {
                schoolId: school.id
            }
        }],
        update: (_, { data }) => {
            if (data.enrollSchoolToContest.errors && data.enrollSchoolToContest.errors.length !== 0) {
                setFormErrors(data.enrollSchoolToContest.errors);
                return;
            } else {
                close()
            }
        }
    });


    useEffect(() => {
        if (data && data.school) {
            const stNumbers = data.school.classes.map((stClass) => stClass.students.length);
            const maxStudents = Math.max(...stNumbers);
            setMaxStudents(maxStudents);
        }
    }, [data]);


    const addToBee = () => {
        addClassToBee()
    }

    useEffect(() => {
        getContest()
    }, [])


    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.school) {


        return (
            <>

                <h1>National Writing Bee 2023 (India)</h1>

                <p><b>Select class</b></p>
                <ul className={styles.classes}>
                    {data.school.classes.map(stClass => (
                        <li key={stClass.id} className={cx(styles.classesItem, {[styles.classesItemActive]: selectedClass === stClass.id})} onClick={() => setSelectedClass(stClass.id)}>
                            <p className={styles.classesItemMain}><CheckCircle /> {stClass.name}</p>
                            <span className={styles.classesItemSide}><Users  /> {stClass.students.length}</span>
                        </li>
                    ))}
                </ul>
                <hr className="separator" />
                <p className="u-m-base-3">
                    <b>Maximum students: </b> 
                    <input
                        min={maxStudents}
                        type='number' 
                        value={maxStudents}
                        onChange={(e) => setMaxStudents(e.target.value)}
                        className={styles.stMaxInput} 
                    />
                </p>
                <Button disabled={!selectedClass} onClick={() => addToBee()}>Add to bee</Button>
            </>
        )
    }
    return null;
}

export default AddToBee;