import React from 'react';
import styles from './ErrorPage.module.scss';

const ErrorPage = () => {
    return (
        <div className="auth-page__container">
            <div className={styles.errorPage}>
                <h1 className={styles.errorPageTitle}>Oops...!</h1>
                <p className={styles.errorPageText}>Something has gone wrong</p>
                <a className={styles.errorPageButton} href="/">
                    Back to dashboard
                </a>
            </div>
        </div>
    );
};


export default ErrorPage;