import React, { useState } from 'react';
import { Edit3, Watch, X } from "react-feather";
import styles from '../Users.module.scss';
import EditUser from "./EditUser";
import { Button, Modal, FormErrors } from "@axeedge/go-teacher-components";
import SaveConfirm from '../../../components/SaveConfirm';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { DELETE_SUPERADMIN, GET_COUNTRY_ADMINS, GET_SUPERADMINS, RESEND_INVITE_EMAIL } from "../services/graphql";

const AdminItem = ({ user, currentUser }) => {


    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [showDelete, setShowDelete] = useState(null);
    const [showEdit, setShowEdit] = useState(null);
    const [formErrors, setFormErrors] = useState([]);


    const [resendSuperadminInviteEmail, { loading: resending }] = useLazyQuery(RESEND_INVITE_EMAIL, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data && data.resendSuperadminInviteEmail) {
                setShowSaveConfirm(true);
            }
        }
    });

    const [deleteSuperAdminUser, { loading: deleting }] = useMutation(DELETE_SUPERADMIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.deleteSuperAdminUser.errors && data.deleteSuperAdminUser.errors.length !== 0) {
                setFormErrors(data.deleteSuperAdminUser.errors);
                return;
            }
            if (data.deleteSuperAdminUser.deleted) {
                setShowDelete(false);
            }
        },
        refetchQueries: [{ query: GET_SUPERADMINS }, { query: GET_COUNTRY_ADMINS }]
    });

    return (
        <>
            <li className={styles.userListItem} user={user}>
                <div className={styles.userListItemMain}>
                    {!user.active && <Watch />} {user.firstName} {user.lastName}

                    {!user.active &&
                        <>
                            {showSaveConfirm ? <SaveConfirm classes='u-d-inline-block u-m-left-2' message='Email sent!' hideConfirm={() => setShowSaveConfirm(false)} /> :
                                <button
                                    onClick={() => resendSuperadminInviteEmail({
                                        variables: {
                                            userId: user.id
                                        }
                                    })}
                                    className='btn-reset link-underline u-text-tertiary u-m-left-2'
                                >{resending ? 'sending...' : 'Resend invite'}</button>
                            }
                        </>
                    }
                </div>

                <button className="btn-reset u-m-right-2" onClick={() => setShowEdit(true)}><Edit3 size="20" /></button>
                {currentUser.id !== user.id &&
                    <button className="btn-reset" onClick={() => setShowDelete(true)}><X /></button>
                }

            </li>

            {showEdit && (
                <Modal closeModal={() => setShowEdit(false)}>
                    <EditUser user={user} currentUser={currentUser} close={() => setShowEdit(false)} />
                </Modal>
            )}

            {showDelete && (
                <Modal closeModal={() => setShowDelete(false)}>
                    <div className="u-text-center">
                        <h2 className="u-m-base-3">Are you sure?</h2>
                        {formErrors && <FormErrors errors={formErrors} />}
                        <Button disabled={deleting} className='u-m-right-2' onClick={() => deleteSuperAdminUser({
                            variables: {
                                userId: user.id
                            }
                        })}>{deleting ? 'Deleting' : 'Yes'}</Button>
                        <Button outline onClick={() => setShowDelete(false)}>No</Button>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default AdminItem;