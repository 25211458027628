import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from '@reach/router';

import { BOOK_ORDER_TYPE, BOOK_ORDER_STATUS } from '@axeedge/go-shared-utils';
import { Loader } from '@axeedge/go-teacher-components';

import { GET_ORDER } from '../../services/graphql';
import BackButton from '../../../../components/BackButton';

import OrderStatus from './components/OrderStatus';
import { getPackStudentsCounter, getTotalOrderedCopies, getOrderCopies, getOrderStatusLabel } from '../../services/utils';

import { AlertTriangle, AlertCircle, CheckCircle } from 'react-feather';
import styles from './ViewOrder.module.scss';
import cx from 'classnames';

const SCHOOL_ORDER_TYPE = 2;//todo shared packages

const ViewOrder = ({ orderId }) => {

    const { data, error, loading } = useQuery(GET_ORDER, {
        variables: {
            printBookOrderId: orderId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }
    if (data && data.printBookOrder) {

        const { printBookOrder: order } = data;
        const details = JSON.parse(order.orderDetails);

        const parentClassOrder = order.classBook?.printBookOrders.find(o => o.orderType === BOOK_ORDER_TYPE.class);

        const renderClassOrderInfo = () => {
            if (parentClassOrder) {
                if (parentClassOrder.status === BOOK_ORDER_STATUS.new) {
                    return <div className={cx(styles.orderStatusPanel, styles.orderStatusPanelNew)}><p className={styles.orderStatusPanelMain}><AlertTriangle /> {parentClassOrder.orderNumber} {getOrderStatusLabel(parentClassOrder.status)}</p></div>
                }
                return <div className={cx(styles.orderStatusPanel, styles.orderStatusPanelDone)}><p className={styles.orderStatusPanelMain}><CheckCircle /> {parentClassOrder.orderNumber} {getOrderStatusLabel(parentClassOrder.status)}</p></div>
            }
            return <div className={styles.orderStatusPanel}><p className={styles.orderStatusPanelMain}><AlertCircle /> No class order</p></div>
        }

        const renderOrderHeaderPanel = () => {
            if (order.status === BOOK_ORDER_STATUS.pending) {
                return (
                    <div className={styles.orderStatusPanel}>
                        <div className={styles.orderStatusPanelMain}><AlertTriangle className='u-m-right-2' /> Book Not published</div>
                        <div>Book status: In Progress</div>
                    </div>
                )
            } else {
                if (parentClassOrder && parentClassOrder.status === BOOK_ORDER_STATUS.new) {
                    return (
                        <div className={styles.orderStatusPanel}>
                            <div className={styles.orderStatusPanelMain}><AlertTriangle className='u-m-right-2' /> Class Order Not processed</div>
                            <div><Link to={`/orders/${parentClassOrder.id}`}>View order</Link></div>
                        </div>
                    )
                }
                if (!parentClassOrder) {
                    return (
                        <div className={styles.orderStatusPanel}>
                            <div className={styles.orderStatusPanelMain}><AlertTriangle className='u-m-right-2' /> No Class Order</div>
                        </div>
                    )
                }
            }
        }


        return (
            <div>
                <BackButton
                    onClick={() => window.history.back()}
                    text='Book Orders'
                />
                <div className={styles.order}>
                    {order.orderType === BOOK_ORDER_TYPE.parent && renderOrderHeaderPanel()}

                    <div className={styles.orderMain}>
                        <div className={styles.orderMainCol}>
                            <h3 className='u-m-top-3'>{order.orderNumber}</h3>
                            <p className='u-m-base-1'>Stripe Ref: {order.paymentIntentId}</p>
                            {
                                order.orderType === BOOK_ORDER_TYPE.parent && <p className='u-m-base-1'>Email: {order.email}</p>
                            }

                            {order.classBook && (
                                <>
                                    <h3 className='u-m-top-3'>{order.classBook.bookCode}: {order.classBook.title}</h3>
                                    <p>{order.classBook.studentsClass.name}</p>
                                    <p>{order.classBook.studentsClass.school.name}</p>
                                </>
                            )}


                            <h3 className='u-m-top-3'>Shipping address</h3>
                            <p>{order.orderType === BOOK_ORDER_TYPE.class ? order.classBook.studentsClass.teachers.length > 0 ? order.classBook.studentsClass.teachers[0].displayName : 'Teacher Name Not Available' : details.buyerName}</p>
                            {order.orderType === SCHOOL_ORDER_TYPE && <p>{order.school.name}</p>}

                            {order.orderType === BOOK_ORDER_TYPE.class && <p>{order.classBook.studentsClass.school.name}</p>}
                            
                            <p>{order.shippingToStreet}</p>
                            <p>{order.shippingToAddress2}</p>
                            <p>{order.shippingToCity}</p>
                            <p>{order.shippingToPostcode}</p>

                            {order.invoiceRequest &&
                                <h2 className='u-m-top-3 u-m-base-2'>Total copies: {order.orderType === BOOK_ORDER_TYPE.class ? `${getTotalOrderedCopies(order)}/${getPackStudentsCounter(order)}` : details?.copies}</h2>
                            }
                            <ul className={styles.orderItemized}>
                                {
                                    order.orderType === BOOK_ORDER_TYPE.class ? getOrderCopies(order).map((item, i) => (
                                        <li key={`item-${item.id}`}>
                                            <div>{i + 1}: {item.bookCode}-{item.student ? item.student.storeCode : '0000'} {item.student ? item.student.name : 'Generic'}</div>
                                            <div>{item.copies}</div>
                                        </li>
                                    )) : order.orderType === BOOK_ORDER_TYPE.parent && (
                                        <li>
                                            <div>{order.classBook.bookCode}-{details.studentCode} {details.studentName}</div>
                                            <div>{details.copies}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>

                        <div className={styles.orderMainCol}>
                            <OrderStatus parentClassOrder={parentClassOrder} order={order} />
                            {
                                order.orderType === BOOK_ORDER_TYPE.parent && (
                                    <Fragment>
                                        <h4 className='u-m-base-1'>Class order:</h4>
                                        {renderClassOrderInfo()}
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export default ViewOrder;