import { gql } from 'apollo-boost';


export const WTR_SCHOOLS = gql`
query writeToRaiseSchools {
    writeToRaiseSchools {
        id
        name
        wtrBooksPublishedCount
        wtrBooksPurchasedCount
        wtrBooksStartedCount
        wtrParticipatingClassesCount
        wtrParticipatingPupilsCount
    }
}
`;

export const WTR_SCHOOL_CSV = gql`
query schoolWtrCsv ($schoolId: String!) {
    schoolWtrCsv (schoolId: $schoolId)
}
`;

export const WTR_SCHOOLS_WITH_BOOKS = gql`
query writeToRaiseSchoolsWithBooks {
    writeToRaiseSchoolsWithBooks {
        id
        name
        wtrSales
        wtrParentsSales
        printBookOrders {
            id
            orderType
        }
        wtrBooks {
            id
            bookCode
            status
            wtrSales {
                id
                classSales
                parentSales
                total
            }
            studentsClass {
                id
                name
                teachers {
                    id
                    name
                    email
                }
            }
        }
    }
}
`;

export const WTR_STATS = gql`
query writeToRaiseStats {
    writeToRaiseStats {
        id
        key
        value
    }
}
`;

export const WTR_INTERESTS = gql`
query writeToRaiseShownInterest {
    writeToRaiseShownInterest {
        id
        school {
            id
            name
        }
        firstName
        lastName
        email
        pupilsCount
        source
    }
}
`;


export const CREATE_SCHOOL_PRINT_BOOK_ORDER = gql`
mutation createSchoolPrintBookOrder($schoolId: ID!) {
    createSchoolPrintBookOrder(schoolId: $schoolId) {
        errors
        printBookOrder {
            id
            orderType
        }
    }
}
`;



