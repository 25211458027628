// Queries and mutations unique to the Packs module
import { gql } from 'apollo-boost';

export const DELETE_TEMPLATE_CATEG_MUTATION = gql`
mutation deleteBookTemplateCategory($bookTemplateCategoryId: ID!) {
    deleteBookTemplateCategory(bookTemplateCategoryId: $bookTemplateCategoryId) {
        deleted
        errors
    }
}
`;