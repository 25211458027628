import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { WTR_SCHOOLS_WITH_BOOKS } from "./services/graphql";
import { Loader } from "@axeedge/go-teacher-components";
import WtrSchool from "./wtrSchool";
import styles from './WriteToRaise.module.scss';

const WtrListSchools = () => {

    const { data, loading, error } = useQuery(WTR_SCHOOLS_WITH_BOOKS);


    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.writeToRaiseSchoolsWithBooks) {

        const { writeToRaiseSchoolsWithBooks } = data;

        return (
            <ul className={styles.schoolReports}>
                {writeToRaiseSchoolsWithBooks.map(school => <WtrSchool key={school.id} school={school} />)}
            </ul>
        )
    }
    return null;
}

export default WtrListSchools;