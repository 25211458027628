import { gql } from 'apollo-boost';

export const GET_BOOK_TYPE_QUERY = gql`
query pack($bookTemplateCategoryId: ID!) {
    bookTemplateCategory(bookTemplateCategoryId: $bookTemplateCategoryId) {
        id
        description
        name
        isSingleChapter
        bookTemplates {
            id
            name
        }
    }
}
`;

export const ADD_BOOK_TYPE_MUTATION = gql`
mutation addBookTemplateCategory($name: String!, $description: String!, $isSingleChapter: Boolean!) {
    addBookTemplateCategory(name: $name, description: $description, isSingleChapter: $isSingleChapter) {
        bookTemplateCategory {
            id
            name
            description
            isSingleChapter
            bookTemplates {
                id
                name
                chapters {
                    id
                    title
                }
            }
        }
        errors
    }
}
`;

export const DELETE_BOOK_MUTATION = gql`
mutation deleteBookTemplate($bookTemplateId: ID!) {
    deleteBookTemplate(bookTemplateId: $bookTemplateId) {
        deleted
        errors
    }
}
`;