// Queries and mutations unique to the Packs module
import { gql } from 'apollo-boost';


export const GET_SUPERADMINS = gql`
query superAdmins {
    superAdmins {
        id
        firstName
        lastName
        email
        active
        token
        countryAdmin
    }
}`;


export const GET_COUNTRY_ADMINS = gql`
query countryAdmins {
    countryAdmins {
        id
        firstName
        lastName
        email
        active
        token
        countryAdmin  
    }
}
`;

export const RESEND_INVITE_EMAIL = gql`
query resendSuperadminInviteEmail($userId: ID!) {
    resendSuperadminInviteEmail(userId: $userId) 
}
`;

export const DELETE_SUPERADMIN = gql`
    mutation deleteSuperAdminUser($userId: ID!) {
        deleteSuperAdminUser(userId: $userId) {
            deleted
            errors
        }
    }

`

export const CREATE_SUPERADMIN = gql`
mutation createSuperAdminUser($firstName: String!, $lastName: String! $email: String!, $country: String) {
    createSuperAdminUser(firstName: $firstName, lastName: $lastName, email: $email, country: $country) {
        user {
            id
            firstName
            lastName
            email
            active
            token
            countryAdmin
        }
        errors
    }
}
`;

export const EDIT_SUPERADMIN = gql`
mutation editSuperAdminUser($userId: ID!, $firstName: String!, $lastName: String! $email: String!, $country: String) {
    editSuperAdminUser(userId: $userId, firstName: $firstName, lastName: $lastName, email: $email, country: $country) {
        user {
            id
            firstName
            lastName
            email
            active
            token
            countryAdmin
        }
        errors
    }
}
`;