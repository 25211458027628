import React from 'react';
import styles from './InvoiceItem.module.scss';
import cx from 'classnames';
import { Link } from '@reach/router';

const InvoiceItem = ({ invoice }) => {

    return(
        <Link to={`/invoices/${invoice.id}`} className={styles.item}>
            <div className={styles.itemInfo}>
                {invoice.externalId && <h3>QB: {invoice.externalId}</h3>}
                <p>{invoice.school.name}</p>
            </div>
            <span className={cx('label label-muted', styles.itemLabel)}>{invoice.source}</span>
        </Link>
    )
}

export default InvoiceItem;