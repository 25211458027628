import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { Loader } from '@axeedge/go-teacher-components';

import { DELETE_BOOK_MUTATION, GET_BOOK_TYPE_QUERY } from '../../services/graphql';
import BackLink from '../../../../components/BackLink';
import ButtonLink from '../../../../components/ButtonLink';
import ListItem from '../../../../components/ListItem';

const PackHome = ({ bookTemplateCategoryId }) => {
    const { data, error, loading } = useQuery(GET_BOOK_TYPE_QUERY, {
        variables: {
            bookTemplateCategoryId
        }
    });

    const [deleteBook] = useMutation(DELETE_BOOK_MUTATION, {
        update: (_, { data }) => {
            if (data.deleteBookTemplate.errors && data.deleteBookTemplate.errors.length) {
                alert(data.deleteBookTemplate.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_BOOK_TYPE_QUERY, variables: { bookTemplateCategoryId } }],
        awaitRefetchQueries: true
    })

    const onDeleteBook = book => {
        let goAhead = window.confirm(`Are you sure you want to delete ${book.name}?`);
        if (goAhead) {
            deleteBook({
                variables: {
                    bookTemplateId: book.id
                }
            })
        }
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.bookTemplateCategory) {
        return (
            <div className="site-admin__view site-admin__pack">
                <BackLink to='/' text='Main Menu' />
                <h1 className="u-m-base-2">{data.bookTemplateCategory.name}</h1>
                <p className="u-m-base-2">{data.bookTemplateCategory.description}</p>
                <div className="u-m-base-4">
                    {
                        data.bookTemplateCategory.bookTemplates && data.bookTemplateCategory.bookTemplates.length ? data.bookTemplateCategory.bookTemplates.map(book => {
                            return (
                                <ListItem 
                                    key={`book=${book.id}`}
                                    linkPath={`/book/${bookTemplateCategoryId}/${book.id}`}
                                    mainText={book.name}
                                    onDelete={() => onDeleteBook(book)}
                                />
                            )
                        }) : <p>No Books</p>
                    }
                    
                </div>
                <ButtonLink to={`/book/${bookTemplateCategoryId}/new`}>Add book</ButtonLink>
            </div>
        );
    }

    return null;
}

export default PackHome;
