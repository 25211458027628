import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { WTR_INTERESTS } from "./services/graphql";
import { Loader } from '@axeedge/go-teacher-components';
import { Link } from "@reach/router";

import styles from './WriteToRaise.module.scss';

const WtrShownInterest = () => {


    const { data, loading, error } = useQuery(WTR_INTERESTS);

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.writeToRaiseShownInterest) {

        const { writeToRaiseShownInterest } = data;

        return (
            <>
                <table className={styles.wtrTable}>
                    <thead>
                        <tr>
                            <th className={styles.wtrTableName}></th>
                            <th>Pupils count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {writeToRaiseShownInterest.map(interest => (
                            <tr key={interest.id}>
                                <td className={styles.wtrTableName}>
                                    {interest.school ?
                                        <Link to={`/school/${interest.school.id}`}>{interest.school.name}</Link> :
                                        <>{interest.email} ({interest.firstName} {interest.lastName})</>
                                    }
                                </td>
                                <td>{interest.pupilsCount}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </>
        )
    }
    return null;
}

export default WtrShownInterest