import React from 'react';
import { XCircle } from 'react-feather';
import cx from 'classnames';

import styles from './SideModal.module.scss';

const SideModal = ({ children, closeModal, isVisible }) => {
    return (
        <div
            onClick={(event) => {
                if (event.target.classList.contains('overlay')) {
                    closeModal();
                }
            }}
        >
            <div className={cx('overlay',styles.sideModalOverlay)}>
                <div className={cx(styles.sideModal)}>
                    <button className={styles.modalCloseButton} onClick={() => closeModal()}><XCircle /></button>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default SideModal;