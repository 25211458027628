import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link } from '@reach/router';
import { Loader, Modal } from '@axeedge/go-teacher-components';
import BackLink from '../../../../components/BackLink';

import { GET_INVOICE } from '../../services/graphql';
import InvoiceStatus from './components/InvoiceStatus';
import InvoiceSummaryWrite from './components/InvoiceSummaryWrite';
import EditDetails from './components/EditDetails';
import ViewReadInvoice from './components/ViewReadInvoice';

import InvoiceNotes from './components/InvoiceNotes';

import { getItemizedPurchases, getInvStatusLabel } from '../../services/utils';
import styles from './ViewInvoice.module.scss';
import moment from 'moment';

const ViewInvoice = ({close, invoiceId, isModal, viewBySource, viewBySearch }) => {

    const [editDetails, setEditDetails] = useState();

    const { data, error, loading } = useQuery(GET_INVOICE, {
        variables: { invoiceRequestId: invoiceId }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.invoiceRequest) {
        const { invoiceRequest: invoice } = data;
        
        const previous = invoice.school.schoolSubscriptions.filter(x => {
            return x.invoiceRequest;
        }).filter(x => {
            return (x.invoiceRequest.id !== invoice.id) && (x.app && x.app.name === 'BoomReader')
        })

        return (
            <div className='u-m-base-3'>
                {!isModal && <BackLink to='/invoices' text="Invoices" />}
                
                {invoice.source === 'GoRead' || invoice.source === 'Data Dashboard Access' || invoice.source === 'Wonde Integration' ? <ViewReadInvoice close={close} viewBySource={viewBySource} viewBySearch={viewBySearch} previous={previous} invoice={invoice} /> :
                    <div className={styles.invoice}>
                        <div className={styles.invoiceSummary}>
                            <div className={styles.invoiceSummaryCol}>
                                <div className='u-m-base-3'><span className='label label-muted'>{getInvStatusLabel(invoice.status)}</span></div>

                                <InvoiceStatus invoice={invoice} viewBySource={viewBySource} viewBySearch={viewBySearch} />
                                <h3 className='u-m-base-2'>Invoice to:</h3>
                                <p>{invoice.contactPerson}</p>
                                {invoice.contactIsFinancialManager && <p>Financial Manager</p>}
                                <p className='u-m-top-1'>{invoice.school.name}</p>
                                <p>{invoice.school.address}</p>
                                <p>{invoice.school.address2}</p>
                                <p>{invoice.school.city}</p>
                                <p className='u-m-base-2'>{invoice.school.zip}</p>
                                <p>Email: {invoice.contactEmail}</p>
                                <p>Phone: {invoice.contactPhone}</p>
                                <p className='u-m-top-1'>Local Authority: {invoice.school.localAuthority || 'Not added'}</p>
                                <p><button onClick={() => setEditDetails(true)} className='btn-reset u-text-tertiary link-underline'>edit</button></p>
                            </div>

                            <div className={styles.invoiceSummaryCol}>
                                <div className="card-muted">
                                    {
                                        invoice.source === 'GoWrite' || invoice.source === "BoomWriter Packs" ? (
                                            <InvoiceSummaryWrite invoice={invoice} />
                                        ) : (
                                            <>
                                                {previous.length > 0 &&
                                                    <>
                                                        <h3>Previous Subscriptions</h3>
                                                        <div className="u-m-top-1">
                                                            {previous.map(sub => {
                                                                return (
                                                                    <div key={sub.id} className={styles.prevSub}>
                                                                        <p><span className="u-bold">{sub.academicYear}</span> {sub.isTrial && '(trial)'}<br />
                                                                            Started: {moment(new Date(sub.invoiceRequest.orderedAt.substring(0, 10))).format('DD MMM YYYY')}
                                                                        </p>
                                                                        <p style={{ marginLeft: 'auto' }}>Students: <span className="u-bold">{sub.invoiceRequest ? sub.invoiceRequest.studentsCounter : 'na'}</span></p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                                <InvoiceNotes invoice={invoice} />
                            </div>
                        </div>

                        {
                            invoice.source === 'GoWrite' || invoice.source  === "BoomWriter Packs" && (
                                <Fragment>
                                    <h2 className='u-m-base-2'>Itemized</h2>
                                    <div className={styles.invoiceItemized}>
                                        {
                                            getItemizedPurchases(invoice.purchasedPacks).map((group, i) => (
                                                <div className={styles.invoiceItemizedGroup} key={`item-${i}`}>
                                                    {group.classes.map(klass => (
                                                        <div className={styles.invoiceItemizedRow} key={`${klass.id}`}>
                                                            <div>{klass.offerName}</div>
                                                            <div>
                                                                {klass.orderId && <Link to={`/orders/${klass.orderId}`} className={styles.invoiceOrderLink}>view order</Link>}
                                                                {klass.studentsCounter || 0}/{klass.maxStudents}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Fragment>
                            )
                        }
                    </div>
                }
                {
                    editDetails && (
                        <Modal closeModal={() => setEditDetails(false)}>
                            <EditDetails invoice={invoice} setEditDetails={setEditDetails} />
                        </Modal>
                    )
                }
            </div>
        )
    }
    return null
}

export default ViewInvoice;