import { gql } from 'apollo-boost';

export const UPDATE_PASSWORD_MUTATION = gql`
mutation updatePassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    updatePassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
        user {
            id
        }
        errors
    }
}
`;

export const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
        reset
        errors
    }
}
`;

export const IMPERSONATE_QUERY = gql`
query loginAs($id: ID!, $userType: String!) {
    loginAs(id: $id, userType: $userType)
}
`;
