import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { navigate } from '@reach/router';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { RESET_PASSWORD_MUTATION } from '../services/graphql';

const ForgotPassword = ({ closeModal, invalid_token }) => {

    const [formErrors, setFormErrors] = useState([]);
    const tokenError = invalid_token && invalid_token[0].toLowerCase().includes("token");

    const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
        onCompleted: data => {
            if (data.resetPassword.errors && data.resetPassword.errors.length !== 0) {
                setFormErrors(data.resetPassword.errors);
                return;
            }
            if (data.resetPassword.reset) {
                navigate('/reset_sent')
            }
        }
    });

    const schema = Yup.object().shape({
        email: Yup.string().required('Enter your email')
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: 'onBlur'
    });

    const onSubmit = values => {
        resetPassword({
            variables: {
                email: values.email
            }
        })
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!tokenError &&
                    <>
                    <h1 className='light u-text-primary u-mb-2'>Reset my password</h1>
                    <p className='u-m-base-2'>It happens to us all! Enter your email address below and we'll send you a link:</p>
                    </>
                }
                {(invalid_token && tokenError) ?
                    <>
                        <h1 className='light u-text-primary u-mb-2 auth-page__title'>Ooops</h1>
                        <p className="u-text-center u-smaller-text u-m-base-2">Please make sure you click the activation link in the most recent email we sent you as the link is unique to each email we send. Enter your email address below if you need a fresh link.</p>
                        <p className="u-text-center u-smaller-text u-m-base-2">
                            If you have any questions contact us at <a href="mailto:support@goapps.app">{{ contactEmail: 'support@goapps.app' }}</a> or visit {{ pageName: 'UKGoApps' }} on Facebook.
                        </p>
                    </>
                    :
                    <>
                        {invalid_token && <FormErrors errors={invalid_token} />}
                    </>
                }
                <div className='basic-form__group'>
                    <input
                        name='email'
                        placeholder='Email'
                        className='basic-form__text-box'
                        type='email'
                        ref={register}
                    />
                </div>
                {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}

                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}

                <Button className='u-m-right-1 u-m-top-2' disabled={loading} type='submit'>{loading ? 'Loading' : 'Send link'}</Button>
                <Button className='u-m-top-2' type='button' onClick={() => closeModal()} outline>Cancel</Button>
            </form>
        </Fragment>
    )
}

export default ForgotPassword;
