import { gql } from 'apollo-boost';

export const TRIAL_SCHOOLS = gql`
query invoiceRequests{
    invoiceRequests(isTrial: true) {
        id
        studentsCounter
        orderedAt
        contactPerson
        contactEmail
        school {
            id
            name
            studentsCounter
        }
    }
}
`;

export const TRIALS_CSV = gql`
query trialInvoicesList{
    trialInvoicesList
}`