import React, { useState, useContext } from "react";
import { useQuery } from '@apollo/react-hooks';
import { GET_SUPERADMINS, GET_COUNTRY_ADMINS } from "./services/graphql";
import { Button, Modal, Loader } from "@axeedge/go-teacher-components";
import AddUser from "./components/AddUser";
import styles from './Users.module.scss';
import { AuthContext } from '../../services/user/AuthProvider';
import AdminItem from "./components/AdminItem";

const Users = () => {

    const { currentUser } = useContext(AuthContext);

    const [showAddUser, setShowAddUser] = useState(false);
    const { data, error, loading } = useQuery(GET_SUPERADMINS);
    const { data: data2, error: error2, loading: loading2 } = useQuery(GET_COUNTRY_ADMINS);


    if (error || error2) {
        return <p>{error.message}</p>
    }
    if (loading || loading2) {
        return <Loader />
    }

    if (data && data2) {

        const { superAdmins } = data;
        const {countryAdmins} = data2;

        return (
            <>
                <div className={styles.header}>
                    <h1>Super Admins</h1>
                    <Button onClick={() => setShowAddUser(true)}>Invite new</Button>
                </div>
                <ul className={styles.userList}>
                    {superAdmins.map(user =>
                        <AdminItem currentUser={currentUser} user={user} key={user.id} />
                    )}
                </ul>

                <h2 className="u-m-top-3">Country Admins</h2>
                <ul className={styles.userList}>
                    {countryAdmins.map(user =>
                        <AdminItem currentUser={currentUser} user={user} key={user.id} />
                    )}
                </ul>

                {showAddUser && (
                    <Modal closeModal={() => setShowAddUser(false)}>
                        <AddUser close={() => setShowAddUser(false)} />
                    </Modal>
                )}
            </>
        )
    }
    return null;
}

export default Users;