import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { navigate } from '@reach/router';
import { GET_COUNTRIES } from '../../../Invoices/services/graphql';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';

import { getRoleLabel, usaOnlyRoles } from '../../../../services/utils';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';

import styles from './AddSchool.module.scss';
import { CREATE_PROMO_SCHOOL_MUTATION } from './services/graphql';
import { ADD_TEACHER_MUTATION } from '../../../../services/school/graphql';

const AddSchool = ({ india }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [newTeacher, setNewTeacher] = useState(null);
    const [newSchool, setNewSchool] = useState(null);

    const [countries, setCountries] = useState([]);
    const [firstCountry, setFirstCountry] = useState(null);

    const { data, loading: loadingCountries, error } = useQuery(GET_COUNTRIES, {
        onCompleted: (data) => {
            setCountries(data.countriesList);
        }
    });

    useEffect(() => {
        if (countries.length > 0) {
            setFirstCountry(india ? countries.find(c => c.code === 'IND') : countries.find(c => c.code === 'GBR'));
        }
    }, [countries]);


    const [createPromoSchool, { loading }] = useMutation(CREATE_PROMO_SCHOOL_MUTATION, {
        update: (_, { data }) => {
            if (data.createPromoSchool.errors && data.createPromoSchool.errors.length !== 0) {
                setFormErrors(data.createPromoSchool.errors);
                return;
            }
            if (data.createPromoSchool.school && data.createPromoSchool.school.id) {
                setNewSchool(data.createPromoSchool.school);
                addAdminToSchool({
                    variables: {
                        firstName: newTeacher.firstName,
                        lastName: newTeacher.lastName,
                        displayName: newTeacher.displayName,
                        email: newTeacher.email,
                        isSchoolAdmin: true,
                        schoolId: data.createPromoSchool.school.id,
                        forceActivation: true,
                        roleId: newTeacher.roleId
                    }
                });
            }
        }
    });

    const [addAdminToSchool, { loading: teacherLoading }] = useMutation(ADD_TEACHER_MUTATION, {
        update: (_, { data }) => {
            if (data.addTeacher.errors && data.addTeacher.errors.length !== 0) {
                setFormErrors(data.addTeacher.errors);
                return;
            }
            if (data?.addTeacher.teacher.id) {
                navigate(`/school/${newSchool.id}`);
            }
        }
    })

    const schema = Yup.object().shape({
        name: Yup.string().required('Please enter a school name'),
        zip: Yup.string().required('Please enter zip/postcode'),
        city: Yup.string().required('Please enter city'),
        address: Yup.string().required('Please enter address'),
        country: Yup.string().required('Please select a country'),
        schoolPhoneNumber: Yup.string().required('Please enter phone number'),
        roleId: Yup.string().required('Please add a role'),
        firstName: Yup.string().required('Please enter first name ,e.g. Penny'),
        lastName: Yup.string().required('Please enter last name, e.g. Lane'),
        displayName: Yup.string().required('Please enter a display name ,e.g. Mrs Lane'),
        email: Yup.string().email().required('Please enter an email address, e.g. pennylane@yourschool.ac.uk'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: 'onSubmit'
    });

    const onSubmit = values => {
        const { name, address, city, zip, address2, country, schoolPhoneNumber, firstName, lastName, displayName, email, roleId } = values;
        setNewTeacher({
            firstName,
            lastName,
            displayName,
            email,
            roleId: +roleId
        });

        createPromoSchool({
            variables: {
                name,
                address,
                city,
                zip,
                address2,
                country,
                schoolPhoneNumber,
                weekStart: 1,
                roleId: +roleId,
            }
        });
    }


    if (loadingCountries) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data) {
        return (
            <div className={styles.container}>
                <h1 className='u-m-base-1'>Create New School</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='basic-form__group-full'>
                        <label htmlFor="name" className='basic-form__text-label'>Official school name</label>
                        <input
                            name='name'
                            placeholder='School Name'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.name && <p className='basic-form__hint u-m-top-0'>{errors.name.message}</p>}


                    <h2 className='light u-m-base-1 u-m-top-2'>School Address</h2>
                    <p className='u-m-base-2'>We may use this to confirm the schools' identity. Any books will be sent to this address</p>
                    <div className='basic-form__group-full'>
                        <label htmlFor="address" className='basic-form__text-label'>Street Address </label>
                        <input
                            name='address'
                            placeholder='Street Address'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.address && <p className='basic-form__hint u-m-top-0'>{errors.address.message}</p>}

                    <div className='basic-form__group-full'>
                        <label htmlFor="address2" className='basic-form__text-label'>Address 2</label>
                        <input
                            name='address2'
                            placeholder='Address 2'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    <div className='basic-form__group-full'>
                        <label htmlFor="city" className='basic-form__text-label'>Town/City</label>
                        <input
                            name='city'
                            placeholder='Town/City'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.city && <p className='basic-form__hint u-m-top-0'>{errors.city.message}</p>}

                    <div className='basic-form__group-full'>
                        <label htmlFor="zip" className='basic-form__text-label'>Postcode</label>
                        <input
                            name='zip'
                            placeholder='Postcode'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.zip && <p className='basic-form__hint u-m-top-0'>{errors.zip.message}</p>}


                    {countries.length > 0 &&
                        <div className='basic-form__group-full'>
                            <label className='basic-form__text-label' htmlFor='country'>Country</label>
                            {india ? <>
                                <p className='basic-form__text-box'>{firstCountry?.name}</p>
                                <input ref={register} name='country' defaultValue={firstCountry?.code} type='hidden' />
                            </>
                                :
                                <select
                                    name="country"
                                    ref={register}
                                    className="basic-form__text-select"
                                >
                                    <option value=''>Please select country</option>
                                    {firstCountry && (
                                        <option value={firstCountry.code}>{firstCountry.name}</option>
                                    )}
                                    <option disabled>──────────</option>
                                    {countries.map((country, i) => (
                                        <option key={`c-${country.code}`} value={country.code}>{country.name}</option>
                                    ))}
                                </select>
                            }
                        </div>
                    }
                    {errors.country && <p className='basic-form__hint u-m-top-0'>{errors.country.message}</p>}


                    <div className='basic-form__group-full'>
                        <label htmlFor="schoolPhoneNumber" className='basic-form__text-label'>Telephone</label>
                        <input
                            name='schoolPhoneNumber'
                            placeholder='e.g. School Office Number'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.schoolPhoneNumber && <p className='basic-form__hint u-m-top-0'>{errors.schoolPhoneNumber.message}</p>}

                    <p>We may call this number to confirm the school identity.</p>
                    <hr className='separator' />
                    <h2 className='light u-m-base-1 u-m-top-2'>Add the School Administrator</h2>
                    <p className='u-m-base-2'>You've been invited to join this school as a member of staff or volunteer:</p>
                    <div className='basic-form__group-full'>
                        <label className='basic-form__text-label' htmlFor='firstName'>First name</label>
                        <input
                            name='firstName'
                            placeholder='First Name e.g. Penny'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.firstName && <p className='basic-form__hint u-m-top-0'>{errors.firstName.message}</p>}
                    <div className='basic-form__group-full'>
                        <label className='basic-form__text-label' htmlFor='lastName'>Last name</label>
                        <input
                            name='lastName'
                            placeholder='Last Name e.g. Lane'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.lastName && <p className='basic-form__hint u-m-top-0'>{errors.lastName.message}</p>}
                    <div className='basic-form__group-full'>
                        <label className='basic-form__text-label' htmlFor='displayName'>Display name</label>
                        <input
                            name='displayName'
                            placeholder='Display Name e.g. Mrs Lane'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.displayName && <p className='basic-form__hint u-m-top-0'>{errors.displayName.message}</p>}
                    <div className='basic-form__group-full'>
                        <label className='basic-form__text-label' htmlFor='email'>Admins work email</label>
                        <input
                            name='email'
                            placeholder='Email e.g. pennylane@yourschool.ac.uk'
                            className='basic-form__text-box'
                            ref={register}
                            type='email'
                        />
                    </div>
                    {errors.email && <p className='basic-form__hint u-m-top-0'>{errors.email.message}</p>}


                    <p>Select the teacher's role at the school</p>
                    <div className='basic-form__group-full u-m-base-2'>
                        <select name="roleId" ref={register} className="basic-form__text-select">
                            <option value=''>Select your role</option>
                            {
                                Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                    return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                })
                            }
                        </select>
                        {errors.roleId && <p className="basic-form__hint u-m-top-0">{errors.roleId.message}</p>}
                    </div>

                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                    <div className='u-display-flex u-align-center'>
                        <Button type='submit' className='u-m-right-1'>{loading || teacherLoading ? 'Saving...' : 'Add School'}</Button>
                        <Button type='button' outline onClick={() => navigate('/')}>Cancel</Button>
                    </div>
                </form>
            </div>

        )
    }
    return null;
}

export default AddSchool;

