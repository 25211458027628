import React, { useState, useEffect } from 'react';
import BackLink from '../../../../components/BackLink';
import { Loader } from '@axeedge/go-teacher-components';
import { INVOICE_STATUS } from '@axeedge/go-shared-utils';

import { useQuery } from '@apollo/react-hooks';
import { GET_INVOICES } from '../../services/graphql';
import InvoiceItem from './components/InvoiceItem';
import { getInvStatusLabel, groupInvoices } from '../../services/utils';
import styles from './InvoicesHome.module.scss';

const InvoicesHome = () => {

    const { data, error, loading } = useQuery(GET_INVOICES);

    const [invoiceGroups, setInvoiceGroups] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (data && data.invoiceRequests) {
            setInvoiceGroups(groupInvoices(data.invoiceRequests))
        }
    }, [data]);

    const filterInvoice = (invoice) => {

        const searchBy = searchTerm.length !== 0
            && (
                (invoice.externalId && invoice.externalId.toLowerCase().includes(searchTerm.toLowerCase()))
                || (invoice.school.localAuthority && invoice.school.localAuthority.toLowerCase().includes(searchTerm.toLowerCase()))
                || (invoice.school.name.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        return searchBy || searchTerm.length === 0;
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }
    if (invoiceGroups) {
        return (
            <div className='u-m-top-3'>
                <div className='u-display-flex u-justify-between u-align-center'>
                    <BackLink to='/' text='Main Menu' />
                    <div>
                        <input
                            type="text"
                            className='basic-form__text-box'
                            value={searchTerm}
                            placeholder='Type to search by QB ref'
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </div>

                </div>
                <div className={styles.invoices}>
                    {
                        invoiceGroups.map(group => {

                            const sortedGroup = group.categ === INVOICE_STATUS.new || group.categ === INVOICE_STATUS.trial ? group.invoices : group.invoices.sort((a, b) => {
                                if (a.externalId === b.externalId) {
                                    return 0
                                } else if (a.externalId === null || b.externalId === null) {
                                    return a.externalId ? -1 : 1;
                                }
                                return a.externalId.localeCompare(b.externalId);
                            });

                            return (
                                <div key={`group-${group.categ}`} className={styles.invoicesCol}>
                                    <h3 className={styles.invoicesColTitle}>{getInvStatusLabel(group.categ)}</h3>
                                    {sortedGroup.map(invoice => {
                                        return filterInvoice(invoice) ? <InvoiceItem key={invoice.id} invoice={invoice} /> : null
                                    }
                                    )}
                                </div>
                            );

                        })
                    }
                </div>
            </div>
        )
    }
    return null
}

export default InvoicesHome;