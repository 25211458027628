import React, { useContext } from 'react';
import DefaultDashboard from './scenes/DefaultDashboard';
import IndiaDashboard from './scenes/IndiaDashboard';
import { AuthContext } from '../../services/user/AuthProvider';


const Dashboard = () => {

    const { currentUser } = useContext(AuthContext);

    return (
        
        <>
            {currentUser?.countryAdmin === 'IND' ? <IndiaDashboard /> : <DefaultDashboard />}
        </>

    )

}
export default Dashboard;