import { gql } from 'apollo-boost';

export const GET_INVOICES = gql`
query invoiceRequests{
    invoiceRequests{
        id        
        externalId
        status
        source
        amount
        school {
            id
            name
            localAuthority
        }
    }
}
`

export const GET_INVOICE_STATS = gql`
query invoiceStats{
    invoiceStats {
        id
        key
        value
    }
}
`;

export const GET_UNPROCESSED_INVOICES = gql`
query unprocessedInvoiceRequests($startFrom: Int, $source: Int){
    unprocessedInvoiceRequests(startFrom: $startFrom, source: $source) {
        id        
        externalId
        status
        source
        amount
        orderedAt
        academicYear
        schoolHasTwoOrMoreBoomreaderSubscription
        subscription {
            id
            duration
        }
        renewal {
            id
            schoolSubscription {
                id
                duration
            }
        }
        school {
            id
            name
            address
            address2
            city
            zip
            billingProfile {
                id
                address
                address2
                city
                country {
                    id
                    name
                    code
                }
                zip
                companyName
            }
        }
    }
}
`

export const SEARCH_INVOICES = gql`
query searchInvoiceRequests($search: String!, $app: Int){
    searchInvoiceRequests(search: $search, app: $app) {
        id        
        externalId
        status
        source
        amount
        orderedAt
        academicYear
        schoolHasTwoOrMoreBoomreaderSubscription
        subscription {
            id
            duration
        }
        renewal {
            id
            schoolSubscription {
                id
                duration
            }
        }
        school {
            id
            name
            address
            address2
            city
            zip
            billingProfile {
                id
                address
                address2
                city
                country {
                    id
                    name
                    code
                }
                zip
                companyName
            }
        }
    }
}
`


export const GET_INVOICE = gql`
query invoiceRequest($invoiceRequestId: ID!){
    invoiceRequest(invoiceRequestId: $invoiceRequestId) {
        id
        amount
        wondeAmount
        dataDashboardAccessAmount
        discount
        status
        source
        externalId
        VAT
        purchaseOrderReferenceNumber
        schoolHasTwoOrMoreBoomreaderSubscription
        renewal {
            id
            schoolSubscription {
                id
                expiresAt
                academicYear
                status
                isTrial
                duration
            }
        }
        school {
            id
            name
            address
            address2
            city
            zip
            localAuthority
            billingProfile {
                id
                address
                address2
                city
                state {
                    id
                    code
                }
                country {
                    id
                    name
                    code
                }
                zip
                companyName
                contactPerson
                contactPhone
                contactEmail
            }
            schoolSubscriptions {
                id
                expiresAt
                academicYear
                status
                app {
                    id
                    name
                }
                isTrial
                duration
                invoiceRequest{
                    id
                    studentsCounter
                    orderedAt
                }
                createdBy {
                    id
                    firstName
                    lastName
                    email
                }
            }
        }
        subscription {
            id
            expiresAt
            academicYear
            status
            app {
                id
                name
            }
            isTrial
            duration
            invoiceRequest{
                id
                studentsCounter
                orderedAt
            }
        }
        issuedAt
        orderedAt
        studentsCounter
        academicYear
        paidAt
        contactEmail
        contactIsFinancialManager
        contactPerson
        contactPhone
        comments {
            id
            author {
                id
                firstName
                lastName
            }
            body
            date
        }
        purchasedPacks {
            id
            academicYear
            instances
            maxNumberOfStudents
            offerDetails
            price
            purchasedAt
            status
            packOffer {
                id
                defaultNumberOfStudentsPerInstance
                instances
                name
                price
                description
            }
        }
    }
}
`

/* start, end, number of students, goread only */

export const MARK_INVOICE_ISSUED = gql`
mutation markInvoiceRequestAsIssued($invoiceRequestId: ID!, $externalId: String!){
    markInvoiceRequestAsIssued(invoiceRequestId: $invoiceRequestId, externalId:$externalId){
        invoiceRequest {
            id
            externalId
            status

        }
        errors
    }
}
`

export const DELETE_INVOICE = gql`
mutation deleteInvoiceRequest($invoiceRequestId: ID!){
    deleteInvoiceRequest(invoiceRequestId: $invoiceRequestId){
        deleted
        errors
    }
}
`

export const EDIT_INVOICE = gql`
mutation editInvoiceRequest($invoiceRequestId: ID!, $schoolId: ID!, $externalId: String, $status: Int, $contactEmail: String, $contactPhone: String, $contactPerson: String, $contactIsFinancialManager: Boolean){
    editInvoiceRequest(invoiceRequestId: $invoiceRequestId, schoolId: $schoolId, externalId: $externalId, status: $status, contactEmail: $contactEmail, contactPhone: $contactPhone, contactPerson: $contactPerson, contactIsFinancialManager: $contactIsFinancialManager){
        invoiceRequest {
            id
            status
            externalId
            contactPhone
            contactPerson
            contactEmail
        }
        errors
    }
}
`

export const UPDATE_INVOICE_STUDENTS_AND_DURATION = gql`
mutation updateInvoiceStudentsCounterAndDuration($invoiceRequestId: ID!, $studentsCounter:Int!, $duration: Int!) {
    updateInvoiceStudentsCounterAndDuration(invoiceRequestId: $invoiceRequestId, studentsCounter: $studentsCounter, duration: $duration) {
        errors
        invoiceRequest {
            id
            studentsCounter
        }
    }
}
`;

export const EDIT_BILLING_PROFILE = gql`
mutation editSchoolBillingProfile($schoolId: ID!, $companyName: String, $address: String!, $address2: String, $city: String!, $country: String!, $state: String, $zip: String!, $contactPerson: String!, $contactEmail: String!, $contactPhone: String!){
    editSchoolBillingProfile(schoolId: $schoolId, companyName: $companyName, address: $address, address2: $address2, city: $city, country: $country, state: $state, zip: $zip, contactPerson: $contactPerson, contactEmail: $contactEmail, contactPhone: $contactPhone) {
        school {
            id
        }
        errors
    }
}
`
export const GET_COUNTRIES = gql`
query countriesList {
    countriesList {
        id
        code
        name
    }
}
`;


export const ADD_INVOICE_COMMENT = gql`
mutation addInvoiceComment($invoiceRequestId: ID!, $body: String!){
    addInvoiceComment(invoiceRequestId: $invoiceRequestId, body: $body){
        invoiceRequest {
            id
            status
            externalId
            contactPhone
            contactPerson
            contactEmail
            comments {
                id
                author {
                    id
                    firstName
                    lastName
                }
                body
                date
            }
        }
        errors
    }
}
`

export const MARK_INVOICE_PAID = gql`
mutation markInvoiceRequestAsPaid($invoiceRequestId: ID!) {
    markInvoiceRequestAsPaid(invoiceRequestId: $invoiceRequestId) {
        invoiceRequest {
            id
        }
        errors
    }
}
`