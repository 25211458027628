import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation} from '@apollo/react-hooks';
import * as Yup from 'yup';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { GET_SCHOOL_QUERY } from '../../../../../../../../services/school/graphql';
import { EDIT_CLASS_MUTATION } from '../../services/graphql';

const EditClass = ({ classItem, closeModal, schoolId }) => {
    const [formErrors, setFormErrors] = useState([]);
    const schema = Yup.object().shape({
        description: Yup.string().required('Please enter year group'),
        name: Yup.string().required('Please enter a class name'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: 'onBlur'
    });

    const [editClass, { loading: savingClass }] = useMutation(EDIT_CLASS_MUTATION, {
        update: (_, { data }) => {
            if (data.editStudentsClass.errors && data.editStudentsClass.errors.length !== 0) {
                setFormErrors(data.editStudentsClass.errors);
                return;
            }

            if (data.editStudentsClass.studentsClass.id) {
                closeModal();
            }
        },
        refetchQueries: [{ query: GET_SCHOOL_QUERY, variables: { schoolId } }]
    });

    const onSubmit = values => {
        const { name, description } = values;
        editClass({
            variables: {
                name,
                description,
                schoolId,
                studentsClassId: classItem.id
            }
        });
    }

    return (
        <div className='site-admin__modal__content'>
            <h1 className='title u-m-base-2'>Edit Class</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    <input
                        name='name'
                        placeholder='Class name'
                        defaultValue={classItem.name}
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
                <div className='basic-form__group'>
                    <input
                        name='description'
                        placeholder='Year group'
                        defaultValue={classItem.description}
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.description && <p className='basic-form__hint'>{errors.description.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className='u-display-flex u-align-center'>
                    <Button type='submit' className='u-m-right-1'>{savingClass ? 'Saving...' : 'Save Class'}</Button>
                    <Button type='button' outline onClick={() => closeModal()}>Cancel</Button>
                </div>
            </form>
        </div>
    );
}

export default EditClass;
