import React, { useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { getAppTokenName } from '@axeedge/go-shared-utils';
import { FormErrors, Button, Modal } from '@axeedge/go-teacher-components';

import { AuthContext } from '../../services/user/AuthProvider';
import { LOGIN } from './services/graphql';
import ForgotPassword from './scenes/ForgotPassword';
import { APP_NAME } from '../../services/constants';

const Login = () => {

    const [formErrors, setFormErrors] = useState([]);
    const client = useApolloClient();
    const auth = useContext(AuthContext);

    const [modalForgotPass, setModalForgotPass] = useState(false);


    const [login, { loading: loggingIn }] = useMutation(LOGIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.superadminLogIn.errors && data.superadminLogIn.errors.length !== 0) {
                setFormErrors(data.superadminLogIn.errors);
                return;
            }
            if (data.superadminLogIn.user && data.superadminLogIn.user.id && data.superadminLogIn.token) {
                ls(getAppTokenName(APP_NAME), data.superadminLogIn.token);
                ls('go_educator_user', JSON.stringify(data.superadminLogIn.user));
                auth.setCurrentUser(data.superadminLogIn.user);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    const schema = Yup.object().shape({
        email: Yup.string().email()
            .required('Please enter your school code'),
        password: Yup.string()
            .required('Please enter your password')
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { email, password } = values;
        login({
            variables: {
                email,
                password
            }
        });
    }

    return (
        <div className="login u-m-top-3">
            <h1 className="u-m-base-3">Login</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group">
                    <input
                        name="email"
                        placeholder="Email"
                        className="basic-form__text-box"
                        ref={register}
                        type="email"
                    />
                </div>
                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="password"
                        placeholder="Password"
                        className="basic-form__text-box"
                        autoComplete="off"
                        ref={register}
                        type="password"
                    />
                </div>
                {errors.password && <p className="basic-form__hint">{errors.password.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className="u-display-flex u-align-center">
                    <Button type="submit" primary>{loggingIn ? 'Logging in...' : 'Login'}</Button>
                </div>

                <p onClick={() => setModalForgotPass(true)} className='link link-underline u-m-top-3'>I've forgotten my password</p>

            </form>

            {
                modalForgotPass && (
                    <Modal closeModal={() => setModalForgotPass(false)}>
                        <ForgotPassword closeModal={() => setModalForgotPass(false)} />
                    </Modal>
                )
            }
        </div>
        
    );
}

export default Login;
