import React, { useState } from "react";
import { Button, FormErrors } from "@axeedge/go-teacher-components";
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { CREATE_SUPERADMIN, GET_SUPERADMINS, GET_COUNTRY_ADMINS } from '../services/graphql';

const AddUser = ({ close }) => {

    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        firstName: Yup.string()
            .required('Please enter a first name for the admin.'),
        lastName: Yup.string()
            .required('Please enter a last name for the admin.'),
        email: Yup.string().email()
            .required('Please enter a valid email address.'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = values => {
        const { firstName, lastName, email, indiaOnly } = values;
        createSuperAdminUser({
            variables: {
                firstName,
                lastName,
                email,
                ...(indiaOnly && { country: 'IND' })
            }
        })
    }

    const [createSuperAdminUser, { loading }] = useMutation(CREATE_SUPERADMIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.createSuperAdminUser.errors && data.createSuperAdminUser.errors.length !== 0) {
                setFormErrors(data.createSuperAdminUser.errors);
                return;
            }
            if (data.createSuperAdminUser.user && data.createSuperAdminUser.user.id) {
                close()
            }
        },
        refetchQueries: [{ query: GET_SUPERADMINS }, {query: GET_COUNTRY_ADMINS}]
    });

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Add new user</h2>
                <div className="basic-form__group">
                    <input
                        name="firstName"
                        placeholder="First Name"
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="lastName"
                        placeholder="Last Name"
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="email"
                        placeholder="Email"
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}

                <div className='basic-form__group basic-form__group--check u-m-base-3'>
                    <input
                        type='checkbox'
                        className="basic-form__check-box"
                        id={'india'}
                        name='indiaOnly'
                        ref={register}
                    />
                    <label className="basic-form__check-label" htmlFor={'india'}>India only SuperAdmin</label>
                </div>
                {formErrors && <FormErrors errors={formErrors} />}
                <Button disabled={loading}>{loading ? 'Saving' : 'Add user'}</Button>
            </form>
        </>
    )
}

export default AddUser;