import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styles from '../../Dashboard.module.scss';
import ListItem from "../../../../components/ListItem";
import ButtonLink from "../../../../components/ButtonLink";
import { DELETE_SCHOOL_MUTATION } from "../../../Schools/services/graphql";
import SearchSchools from "../../../Schools/components/searchSchools";
import { FormErrors } from "@axeedge/go-teacher-components";
import SaveConfirm from "../../../../components/SaveConfirm";
import { CheckCircle } from "react-feather";

const IndiaDashboard = () => {

    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);


    const [formErrors, setFormErrors] = useState([]);

    const [deleteSchool] = useMutation(DELETE_SCHOOL_MUTATION, {
        update: (_, { data }) => {
            if (data.deleteSchool.errors && data.deleteSchool.errors.length > 0) {
                setFormErrors(data.deleteSchool.errors);
                return;
            }
            if (data.deleteSchool.deleted) {
                setShowSaveConfirm(true);
            }
        },
    });

    useEffect(() => {
        if (toDelete) {
            const updatedResults = results.filter(r => r.id !== toDelete.id);
            setResults(updatedResults);
            setToDelete(null);
        }
    }, [toDelete]);

    const onDeleteSchool = school => {
        let goAhead = window.confirm(`Are you sure you want to delete ${school.name}?`);
        if (goAhead) {
            setToDelete(school);
            deleteSchool({
                variables: {
                    schoolId: school.id
                }
            });
        }
    }

    return (
        <>
            <div className={styles.pageHeaderIndia}>
                <h1 className={styles.pageHeaderIndiaTitle}>India Schools</h1>
                <div>
                    <span className="u-m-right-2">
                        <ButtonLink to='/writingBees/'>Manage Bee</ButtonLink>
                    </span>
                    <ButtonLink to='/school/new/india'>Add A School</ButtonLink>
                </div>
            </div>

            <SearchSchools india={true} setResults={setResults} setNoResults={setNoResults} />
            {formErrors && formErrors.length > 0 && <FormErrors errors={formErrors} />}

            {showSaveConfirm && <SaveConfirm message='School deleted!' hideConfirm={() => setShowSaveConfirm(false)} />}
            {results.length > 0 ?
                <ul className="u-m-top-2">
                    {results.map(school => (
                        <ListItem
                            {...(school.name === 'India 4' && { sideLabel: <span className="label label-bee"><CheckCircle size="16" />BEE</span> })}
                            key={`school-${school.id}`}
                            linkPath={`/school/${school.id}`}
                            mainText={<>{school.name} <span className='light body-text'>{school.schoolCode}</span></>}
                            subText={<><b>{school.city}</b> <span className='light smaller-text'>{school.address} {school.address2} &ndash; {school.zip}</span></>}
                            onDelete={() => onDeleteSchool(school)}
                        />
                    ))}
                </ul> : noResults && <p className="u-m-top-3"><b>No schools found</b></p>}
        </>
    )

}

export default IndiaDashboard;