import React, { useEffect, useRef, useState } from 'react';

import BookSidebar from '../BookSidebar';
import BookSettings from '../BookSettings';
import BookChapter from '../BookChapter';
import styles from './BookHolder.module.scss'

const BookHolder = ({ backLink, book: bookData, isNew }) => {
    const [book, setBook] = useState(bookData);
    const [selectedChapter, setSelectedChapter] = useState(-1);
    const bookRef = useRef(bookData);

    useEffect(() => {
        if (selectedChapter > bookData.chapters.length -1) {
            setSelectedChapter(sc => sc - 1);
        }
        if (bookData.chapters.length !== bookRef.current.chapters.length) {
            bookRef.current = bookData;
            setBook(bookData);
        }
        if (bookData.name !== bookRef.current.name || bookData.description !== bookRef.current.description || bookData.foreword !== bookRef.current.foreword) {
            bookRef.current = bookData;
            setBook(bookData);
        }
    }, [bookData, selectedChapter])

    const softSaveNewBook = book => {
        setBook(book);
        setSelectedChapter(sc => sc + 1);
    }

    return (
        <div className={styles.book}>
            <div className={styles.bookContent}>
                <h1 className='u-m-base-2'>{isNew ? 'Add a book' : 'Edit book' }</h1>
                {
                    selectedChapter === -1 ?
                    <BookSettings backLink={backLink} book={book} onSaveNewBook={softSaveNewBook} /> :
                    <BookChapter chapter={book.chapters[selectedChapter]} backLink={backLink} book={book} />
                }
            </div>
            <BookSidebar book={book} selectedChapter={selectedChapter} onSelectChapter={setSelectedChapter} />
        </div>
    );
}

export default BookHolder;