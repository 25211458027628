import React, { useState } from 'react';
import { CheckCircle } from 'react-feather';
import { useEffectOnce } from 'react-use';
import cx from 'classnames';

import styles from './SaveConfirm.module.scss';

const SaveConfirm = ({ hideConfirm, message, classes }) => {

    const [fadeIn, setFadeIn] = useState(false);
    useEffectOnce(() => {
        setTimeout(() => {
            hideConfirm();
        }, 5000)
        setTimeout(() => {
            setFadeIn(false);
        }, 4500)
        setTimeout(() => {
            setFadeIn(true);
        }, 100)
    })

    return (
        <p className={cx(styles.saveConfirm,  classes, { [styles.fadeIn]: fadeIn }, { [styles.fadeOut]: !fadeIn })}><CheckCircle className={styles.saveConfirmIcon} /> {message || 'Changes saved!'}</p>
    );
}

export default SaveConfirm;
