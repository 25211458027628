import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation} from '@apollo/react-hooks';
import * as Yup from 'yup';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { GET_SCHOOL_QUERY } from '../../../../../../../../services/school/graphql';
import { ADD_CLASS_MUTATION } from '../../services/graphql';

const AddClass = ({ schoolId, closeModal }) => {
    const [formErrors, setFormErrors] = useState([]);
    const schema = Yup.object().shape({
        description: Yup.string().required('Please enter year group'),
        name: Yup.string().required('Please enter a class name'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: 'onBlur'
    });

    const [addClass, { loading: addingClass }] = useMutation(ADD_CLASS_MUTATION, {
        update: (cache, { data }) => {
            if (data.createStudentsClass.errors && data.createStudentsClass.errors.length !== 0) {
                setFormErrors(data.createStudentsClass.errors);
                return;
            }
            const schoolQuery = cache.readQuery({ query: GET_SCHOOL_QUERY, variables: { schoolId } });
            if (schoolQuery) {
                cache.writeQuery({
                    query: GET_SCHOOL_QUERY,
                    variables: { schoolId },
                    data: {
                        school: {
                            ...schoolQuery.school, 
                            classes: [...schoolQuery.school.classes, data.createStudentsClass.studentsClass]
                        }
                    }
                });
                closeModal();
            }
        }
    });

    const onSubmit = values => {
        const { name, description } = values;
        addClass({
            variables: {
                name,
                description,
                schoolId
            }
        });
    }

    return (
        <div className='site-admin__modal__content'>
            <h1 className='title u-m-base-2'>Create Class</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    <input
                        name='name'
                        placeholder='Class name'
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
                <div className='basic-form__group'>
                    <input
                        name='description'
                        placeholder='Year group'
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.description && <p className='basic-form__hint'>{errors.description.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className='u-display-flex u-align-center'>
                    <Button type='submit' className='u-m-right-1'>{addingClass ? 'Saving...' : 'Create'}</Button>
                    <Button type='button' outline onClick={() => closeModal()}>Cancel</Button>
                </div>
            </form>
        </div>
    );
}

export default AddClass;
