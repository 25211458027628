import React, { useContext } from 'react';
import { navigate } from "@reach/router";
import { useApolloClient } from '@apollo/react-hooks';
import ls from 'local-storage';

import { AuthContext } from '../services/user/AuthProvider';

const LogoutButton = ({ children, classes }) => {
    const client = useApolloClient();
    const { setCurrentUser } = useContext(AuthContext);
    return (
        <button
            className={classes}
            onClick={() => {
                ls.clear();
                setCurrentUser(null);
                client.writeData({
                    data: {
                        loggedIn: false
                    }
                });
                navigate('/');
            }}
        >
            {children}
        </button>
    );
}

export default LogoutButton;