import React, { useState, Fragment } from 'react';
import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { CANCEL_PRINT_BOOK_ORDER, UNCANCEL_PRINT_BOOK_ORDER } from '../../../../services/graphql';
import moment from 'moment';

const CancelOrder = ({ order,  closeCancel  }) => {

    const [formErrors, setFormErros] = useState([]);

    const [cancelOrder, { loading}] = useMutation(CANCEL_PRINT_BOOK_ORDER, {
        update: (_, { data }) => {
            if (data.cancelPrintBookOrder.errors && data.cancelPrintBookOrder.errors.length !== 0) {
                setFormErros(data.cancelPrintBookOrder.errors);
                return;
            }
            closeCancel();
        }
    });
    const [unCancelOrder, { loading: uncancelling}] = useMutation(UNCANCEL_PRINT_BOOK_ORDER, {
        update: (_, { data }) => {
            if (data.uncancelPrintBookOrder.errors && data.uncancelPrintBookOrder.errors.length !== 0) {
                setFormErros(data.uncancelPrintBookOrder.errors);
                return;
            }
            closeCancel();
        }
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: Yup.object().shape({
            confirmCancel: Yup.bool().oneOf([true], 'Confirm by checking this field')
        })
    });
    const onSubmit = ({ refundId }) => {
        cancelOrder({
            variables: {
                printBookOrderId: order.id,
                refundId
            }
        });
    }

    return(
        <Modal closeModal={closeCancel}>
            {order.isCancelled ? (
                <Fragment>
                    <h1 className='u-m-base-3'>UnCancel order</h1>
                    <p className='u-m-base-3'>Order was cancelled on: {moment(new Date(order.cancelledAt)).format("DD/MM/YYYY")} </p>
                    <Button
                        onClick={() => unCancelOrder({
                            variables: { printBookOrderId: order.id}
                        })}
                    >{uncancelling ? 'Saving...' : 'Uncancel order'}</Button>
                    {formErrors && <FormErrors errors={formErrors} />}
                </Fragment>
            ) : (
                <Fragment>
                    <h1 className='u-m-base-3'>Cancel order</h1>
                    <p className='u-m-base-3'><b>Refund will not be automatically given</b></p>
                    <p>Action any applicable refunds on Stripe and enter the transaction reference below so we can link up the 2 events.</p>
                    <form onSubmit={handleSubmit(onSubmit)} className='u-m-top-3 u-m-base-3'>              
                        <div className='u-m-base-2'>
                            Stripe refund ref:
                            <input
                                type='text'
                                className='basic-form__text-box'
                                ref={register}
                                name='refundId'
                            />
                        </div>
                        <div className="basic-form__group u-m-top-2 icon-title">
                            <input
                                id="cancel"
                                className='u-m-right-2'
                                type="checkbox"
                                name="confirmCancel"
                                ref={register}
                            />
                            <label htmlFor="cancel" className='basic-form__label'>Cancel this order</label>
                        </div>
                        {errors.confirmCancel && <p className='basic-form__hint'>{errors.confirmCancel.message}</p>}                
                        {formErrors && <FormErrors errors={formErrors} />}
                        <Button outline>{loading  ? 'Saving ':'Cancel order'}</Button>
                    </form>
                </Fragment>
            )}
  
        </Modal>
    )
}

export default CancelOrder;