import { gql } from 'apollo-boost';

export const GET_ORDERS = gql`
query printBookOrdersQuery($bookCode: String, $status: Int, $orderType: Int, $search: String, $cancelled: String, $page: Int){
    printBookOrdersQuery(bookCode: $bookCode, status: $status, orderType: $orderType, search: $search, cancelled: $cancelled, page: $page){
        id
        orderType
        orderNumber
        orderedAt
        status
        storeCode
        isCancelled
        classBook {
            id
            bookCode
        }
    }
}
`;



export const GET_ORDER = gql`
query printBookOrder($printBookOrderId: ID!){
    printBookOrder(printBookOrderId: $printBookOrderId) {
        id
        orderType
        orderNumber
        orderDetails
        orderedAt
        isCancelled
        cancelledAt
        refundId
        email
        paymentIntentId
        sendToDestinationAt
        sendToPrinterAt
        shippingToAddress2
        shippingToCity
        shippingToPostcode
        shippingToStreet
        status
        trackingNumber
        invoiceRequest {
            id
            purchasedPacks{
                id
                status
            }
        }
        school {
            id
            name
        }
        classBook {
            id
            bookCode
            status
            title
            printBookOrders(orderType: 0) {
                id
                status
                orderType
                orderNumber
            }
            studentsClass {
                id
                name
                students {
                    id
                    name
                    storeCode
                }
                school {
                    id
                    name
                }
                teachers {
                    id
                    displayName
                }
                description
            }
        }
    }
}
`;



export const PRINT_FEED_URL = gql`
query bookOrderPrintFeedUrl($printBookOrderId: ID!){
    bookOrderPrintFeedUrl(printBookOrderId: $printBookOrderId)
}
`;

export const INDIVIDUAL_FEED_URL = gql`
query individualBookOrderPrintFeedUrl($printBookOrderId: ID!){
    individualBookOrderPrintFeedUrl(printBookOrderId: $printBookOrderId)
}
`;

export const SCHOOL_FEED_URL = gql`
query schoolPrintFeedUrl($schoolId: ID!){
    schoolPrintFeedUrl(schoolId: $schoolId)
}
`;



export const SEND_ORDER_TO_PRINTER = gql`
mutation sendPrintBookOrderToPrinter($printBookOrderId: ID!) {
    sendPrintBookOrderToPrinter(printBookOrderId: $printBookOrderId) {
        errors
        printBookOrder {
            id
            status
            sendToPrinterAt
        }
    }
}`;

export const SEND_ORDER_TO_DESTINATION = gql`
mutation sendPrintBookOrderToDestination($printBookOrderId: ID!, $trackingNumber: String) {
    sendPrintBookOrderToDestination(printBookOrderId: $printBookOrderId, trackingNumber: $trackingNumber) {
        errors
        printBookOrder {
            id
            status
            sendToDestinationAt
        }
    }
}`;


export const CANCEL_PRINT_BOOK_ORDER = gql`
mutation cancelPrintBookOrder($printBookOrderId: ID!, $refundId: String) {
    cancelPrintBookOrder(printBookOrderId: $printBookOrderId, refundId: $refundId) {
        errors
        printBookOrder {
            id
            status
            isCancelled
            cancelledAt
            refundId
        }
    }
}
`;

export const UNCANCEL_PRINT_BOOK_ORDER = gql`
mutation uncancelPrintBookOrder($printBookOrderId: ID!) {
    uncancelPrintBookOrder(printBookOrderId: $printBookOrderId) {
        errors
        printBookOrder {
            id
            status
            isCancelled
            cancelledAt
            refundId
        }
    }
}
`;