import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { navigate } from '@reach/router';

import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { GET_COUNTRIES } from '../../../Invoices/services/graphql';
import { GET_SCHOOL_QUERY, EDIT_SCHOOL_MUTATION } from '../../../../services/school/graphql';
import { AuthContext } from '../../../../services/user/AuthProvider';

const EditSchool = ({ schoolId }) => {

    const { currentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [firstCountry, setFirstCountry] = useState(null);


    const { data, error, loading } = useQuery(GET_SCHOOL_QUERY, {
        variables: {
            schoolId
        }
    });

    const { data: data2, error: error2, loading: loading2 } = useQuery(GET_COUNTRIES, {
        onCompleted: (data) => {
            setCountries(data.countriesList);
        }
    });

    useEffect(() => {
        if (countries.length > 0 && currentUser) {
            setFirstCountry(currentUser.countryAdmin === 'IND' ? countries.find(c => c.code === 'IND') : countries.find(c => c.code === 'GBR'));
        }
    }, [countries]);


    const [editSchool, { loading: savingSchool }] = useMutation(EDIT_SCHOOL_MUTATION, {
        awaitRefetchQueries: true,
        update: (_, { data }) => {
            if (data.editSchool.errors && data.editSchool.errors.length !== 0) {
                setFormErrors(data.editSchool.errors);
                return;
            }
            if (data.editSchool.school.id) {
                navigate(`/school/${schoolId}`);
            }
        },
        refetchQueries: [{ query: GET_SCHOOL_QUERY, variables: { schoolId } }]
    })

    const schema = Yup.object().shape({
        name: Yup.string().required('Please enter a school name'),
        address: Yup.string().required('Please enter address'),
        zip: Yup.string().required('Please enter zip/postcode'),
        city: Yup.string().required('Please enter city'),
        country: Yup.string().required('Please select a country'),
        schoolPhoneNumber: Yup.string().required('Please enter phone number'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: 'onSubmit'
    });

    const onSubmit = values => {
        const { name, address, city, zip, address2, country, schoolPhoneNumber } = values;
        editSchool({
            variables: {
                name,
                address,
                address2,
                city,
                schoolId,
                zip,
                country,
                schoolPhoneNumber
            }
        });
    }

    if (error || error2) {
        return <p>{error.message}</p>;
    }

    if (loading || loading2) {
        return <Loader />
    }

    if (data && data.school && data2) {
        return (
            <div className='u-m-top-3 u-m-base-2'>
                <h1 className='u-m-base-2'>Edit School</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='basic-form__group-full'>
                        <label htmlFor="name" className='basic-form__text-label'>Official school name</label>

                        <input
                            name='name'
                            placeholder='School Name'
                            defaultValue={data.school.name}
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.name && <p className='basic-form__hint u-m-top-0'>{errors.name.message}</p>}


                    <h2 className='light u-m-base-1 u-m-top-2'>School Address</h2>

                    <label htmlFor="address" className='basic-form__text-label'>Street Address </label>
                    <div className='basic-form__group-full'>
                        <input
                            name='address'
                            placeholder='Street Address'
                            defaultValue={data.school.address}
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.address && <p className='basic-form__hint u-m-top-0'>{errors.address.message}</p>}

                    <div className='basic-form__group-full'>
                        <label htmlFor="address2" className='basic-form__text-label'>Address 2</label>
                        <input
                            name='address2'
                            placeholder='Address 2'
                            defaultValue={data.school.address2}
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    <div className='basic-form__group-full'>
                        <label htmlFor="city" className='basic-form__text-label'>Town/City</label>

                        <input
                            name='city'
                            placeholder='City'
                            defaultValue={data.school.city}
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.city && <p className='basic-form__hint u-m-top-0'>{errors.city.message}</p>}

                    <div className='basic-form__group-full'>
                        <label htmlFor="zip" className='basic-form__text-label'>Postcode</label>

                        <input
                            name='zip'
                            placeholder='Postcode'
                            className='basic-form__text-box'
                            defaultValue={data.school.zip}
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.zip && <p className='basic-form__hint u-m-top-0'>{errors.zip.message}</p>}

                    {countries.length > 0 &&
                        <div className='basic-form__group-full'>
                            <label className='basic-form__text-label' htmlFor='country'>Country</label>
                            {currentUser?.countryAdmin === 'IND' ? <>
                                <p className='basic-form__text-box'>{firstCountry?.name}</p>
                                <input ref={register} name='country' defaultValue={firstCountry?.code} type='hidden' />
                            </>
                                :
                                <select
                                    name="country"
                                    ref={register}
                                    defaultValue={data.school?.country?.code}
                                    className="basic-form__text-select"
                                >
                                    <option value=''>Please select country</option>
                                    {firstCountry && (
                                        <option value={firstCountry.code}>{firstCountry.name}</option>
                                    )}
                                    <option disabled>──────────</option>
                                    {countries.map(country => (
                                        <option key={`c-${country.code}`} value={country.code}>{country.name}</option>
                                    ))}
                                </select>
                            }
                        </div>
                    }
                    {errors.country && <p className='basic-form__hint u-m-top-0'>{errors.country.message}</p>}


                    <div className='basic-form__group-full'>
                        <label htmlFor="schoolPhoneNumber" className='basic-form__text-label'>Telephone</label>
                        <input
                            name='schoolPhoneNumber'
                            placeholder='e.g. School Office Number'
                            className='basic-form__text-box'
                            ref={register}
                            defaultValue={data.school.schoolPhoneNumber}
                            type='text'
                        />
                    </div>
                    {errors.schoolPhoneNumber && <p className='basic-form__hint u-m-top-0'>{errors.schoolPhoneNumber.message}</p>}


                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                    <div className='u-display-flex u-align-center u-m-base-3 u-m-top-2'>
                        <Button type='submit' className='u-m-right-1'>{loading || savingSchool ? 'Saving...' : 'Save School'}</Button>
                        <Button type='button' outline onClick={() => navigate(`/school/${schoolId}`)}>Cancel</Button>
                    </div>
                </form>
            </div>
        )
    }
}

export default EditSchool;