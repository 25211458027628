import React from 'react';
import BackLink from '../../components/BackLink';

import BookTypesQuery from './components/BookTypesQuery'
const Books = () => {
    return (
        <>
        <BackLink to='/' text='Main Menu' />
        <BookTypesQuery />
        </>
    )
}

export default Books;