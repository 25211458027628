import React, { useState, useEffect } from "react";
import styles from './ViewReadInvoice.module.scss';
import { Link } from '@reach/router';
import InvoiceNotes from "../InvoiceNotes";
import moment from 'moment';
import cx from 'classnames';
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getInvStatusLabel } from "../../../../services/utils";
import { useForm } from 'react-hook-form';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { MARK_INVOICE_PAID,DELETE_INVOICE, MARK_INVOICE_ISSUED, GET_UNPROCESSED_INVOICES, SEARCH_INVOICES, EDIT_INVOICE, EDIT_BILLING_PROFILE, GET_COUNTRIES, GET_INVOICE, UPDATE_INVOICE_STUDENTS_AND_DURATION } from "../../../../services/graphql"
import * as Yup from 'yup';
import { Edit, ArrowRight, Copy } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from "react-tooltip";

import { INVOICE_STATUS, SUBSCRIPTION_STATUS } from '@axeedge/go-shared-utils';
import UpdateStudentsDuration from "./updateStudentsDuration";

const ViewReadInvoice = ({close, invoice, previous, viewBySource, viewBySearch }) => {

    const currentSub = invoice.subscription || (invoice.renewal && invoice.renewal.schoolSubscription);

    const [showEditBilling, setShowEditBilling] = useState(false)
    const [showUpdate, setShowUpdate] = useState(false);
    const [formErrors, setFormErrors] = useState(null)
    const [showExternalRef, setShowExternalRef] = useState(!invoice.externalId);
    const [tempRef, setTempRef] = useState(invoice.externalId)
    const [copied, setCopied] = useState(null);

    const [showDelete, setShowDelete] = useState(false);

    const [countries, setCountries] = useState([]);
    const [uk, setUk] = useState(null);

    const vatAmountFromGross = (nGrossAmount) => {
        return nGrossAmount / (1 + (1 / 0.2));
    }

    const invoiceAmount = invoice.VAT ? invoice.amount : invoice.amount - vatAmountFromGross(invoice.amount);

    const { data, loading, error } = useQuery(GET_COUNTRIES, {
        onCompleted: (data) => {
            setCountries(data.countriesList);
        }
    });

    useEffect(() => {
        if (countries.length > 0) {
            setUk(countries.find(c => c.code === 'GBR'));
        }
    }, [countries]);

    function compareDate(a, b) {
        if (b.expiresAt < a.expiresAt) {
            return -1;
        }
        if (b.expiresAt > a.expiresAt) {
            return 1;
        }
        return 0;
    }

    const previousSorted = previous.sort(compareDate)

    const invoicesQueries = () => {
        return [
            { query: GET_INVOICE, variables: { invoiceRequestId: invoice.id } },
            ...viewBySource ? [{ query: GET_UNPROCESSED_INVOICES, variables: { source: viewBySource.app, startFrom: viewBySource.startFrom, } }] : [],
            ...viewBySearch ? [{ query: SEARCH_INVOICES, variables: { search: viewBySearch.term, app: viewBySearch.app, } }] : []
        ]
    }

    const [markInvoicePaid, { loading: payingInvoice }] = useMutation(MARK_INVOICE_PAID, {
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GET_INVOICE,
            variables: {
                invoiceRequestId: invoice.id
            }
        }],
        update: (_, { data }) => {
            if (data.markInvoiceRequestAsPaid.errors && data.markInvoiceRequestAsPaid.errors.length !== 0) {
                setFormErrors(data.markInvoiceRequestAsPaid.errors);
                return;
            }
        }
    });

    const [deleteInvoice, { loading: deleting }] = useMutation(DELETE_INVOICE, {
        awaitRefetchQueries: true,
        update: (_, { data }) => {
            if (data?.deleteInvoiceRequest?.deleted) {
                close();
            }
            if (data.deleteInvoiceRequest.errors && data.deleteInvoiceRequest.errors.length !== 0) {
                setFormErrors(data.deleteInvoiceRequest.errors);
                return;
            }
        },
        refetchQueries: () => invoicesQueries()
    });

    const [editInvoice, { loading: editingInvoice }] = useMutation(EDIT_INVOICE, {
        awaitRefetchQueries: true,
        update: (_, { data }) => {
            if (data.editInvoiceRequest.errors && data.editInvoiceRequest.errors.length !== 0) {
                setFormErrors(data.editInvoiceRequest.errors);
                return;
            }
            setShowExternalRef(false);
        },
        refetchQueries: () => invoicesQueries()
    });

   

    const [markInvoiceIssued, { loading: issuing }] = useMutation(MARK_INVOICE_ISSUED, {
        awaitRefetchQueries: true,
        update: (_, { data }) => {
            if (data.markInvoiceRequestAsIssued.errors && data.markInvoiceRequestAsIssued.errors.length !== 0) {
                setFormErrors(data.markInvoiceRequestAsIssued.errors);
                return;
            }
            setShowExternalRef(false);
        },
        refetchQueries: () => invoicesQueries()
    });

    const [updateBilling, { loading: updating }] = useMutation(EDIT_BILLING_PROFILE, {
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GET_INVOICE,
            variables: {
                invoiceRequestId: invoice.id
            }
        }],
        update: (_, { data }) => {
            if (data.editSchoolBillingProfile.errors && data.editSchoolBillingProfile.errors.length !== 0) {
                setFormErrors(data.editSchoolBillingProfile.errors);
                return;
            }
            setShowEditBilling(false)
        }
    });

    const schema = Yup.object().shape({
        companyName: Yup.string().required('Please enter a company name'),
        address: Yup.string().required('Please enter an address'),
        city: Yup.string().required('Please enter a city'),
        zip: Yup.string().required('Please enter a zip'),
        country: Yup.string().required('Please enter a country'),
        contactPerson: Yup.string().required("Please enter contact person"),
        contactPhone: Yup.string().required("Please enter phone"),
        contactEmail: Yup.string().required("Please enter email")
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = values => {
        const { companyName, contactPerson, contactPhone, contactEmail, address, address2, city, zip, country } = values;
        updateBilling({
            variables: {
                companyName,
                schoolId: invoice.school.id,
                contactPerson,
                contactPhone,
                contactEmail,
                address,
                address2,
                city,
                zip,
                country
            }
        });
    }

    const handleInvoiceNum = () => {
        if (!tempRef) {
            window.alert('Please enter an invoice number')
        } else {
            if (invoice.externalId) {
                editInvoice({
                    variables: {
                        invoiceRequestId: invoice.id,
                        schoolId: invoice.school.id,
                        externalId: tempRef
                    }
                })
            } else {
                markInvoiceIssued({
                    variables: {
                        invoiceRequestId: invoice.id,
                        externalId: tempRef
                    }
                })
            }
        }
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [showEditBilling]);

    const billingAddressText = `${invoice.school.billingProfile.address}\n${invoice.school.billingProfile.address2 || ''}\n${invoice.school.billingProfile.city}\n${invoice.school.billingProfile.zip}\n${invoice.school.billingProfile.country?.name || ''}`


    const initialCostPerStudent = invoiceAmount / invoice.studentsCounter

    if (loading) {
        return <Loader />
    }

    if (data) {
        return (
            <div className={styles.wrapper}>
                <div>
                    {currentSub?.status === SUBSCRIPTION_STATUS.cancelled && <p className="label label-accent-red">Subscription cancelled</p>}
                    <div className={styles.header}>
                        {invoice.source === 'GoRead' ?
                            <h1>Read subscription: {invoice.schoolHasTwoOrMoreBoomreaderSubscription && 'Renewal'}</h1> :
                            invoice.source === 'Data Dashboard Access' ?
                                <h1>Data Dashboard & Wonde Integration</h1> :
                                invoice.source === 'Wonde Integration' && <h1>Wonde Integration</h1>
                        }
                        <span className={styles.invStatus}>{getInvStatusLabel(invoice.status)}</span>
                    </div>
                    <div className={styles.invList}>
                        <p><b>Submitted:</b> {moment(new Date(invoice.orderedAt.substring(0, 10))).format('DD MMM YYYY')}</p>
                        {currentSub && <p><b>Renewal:</b> {moment(new Date(currentSub.expiresAt.substring(0, 10))).format('DD MMM YYYY')}</p>}
                    </div>
                    <div className={cx(styles.panel, 'u-m-base-2')}>
                        <p><Link className={styles.invoiceLink} to={`/school/${invoice.school.id}`}>{invoice.school.name} <ArrowRight size="14" /></Link></p>
                        <p>{invoice.school.address}{invoice.school.address2 && `, ${invoice.school.address2}`}, {invoice.school.city}, {invoice.school.zip}</p>
                        {
                            currentSub && (
                                <>
                                    {currentSub.createdBy ? <p>Ordered By: {currentSub.createdBy.firstName} {currentSub.createdBy.lastName} ({currentSub.createdBy.email})</p> :
                                        <p>Ordered By: {invoice.contactPerson} ({invoice.contactEmail})</p>}
                                </>
                            )
                        }
                    </div>

                    {currentSub && <h2>Subscription: {currentSub.duration && <>{currentSub.duration} {currentSub.duration === 1 ? 'year' : 'years'}</>} </h2>}
                   
                    {
                        showUpdate ? (
                            <div>
                                <UpdateStudentsDuration 
                                    invoice={invoice}
                                    currDuration={currentSub.duration}  
                                    close={() => setShowUpdate(false)}  
                                    invoicesQueries={() => invoicesQueries()}                            
                                />

                            </div>
                        ) : <button onClick={() => setShowUpdate(true)} className="btn-reset link-underline">Edit duration /  seats</button>
                    }
                    {invoice.source !== 'Wonde Integration' &&
                        <div className={cx(styles.panel, 'u-m-base-2')}>
                            <ul>
                                <li className={styles.invList}>Total Students: <span>{invoice.studentsCounter}</span></li>
                            </ul>
                        </div>
                    }
                    <ul className="u-p-2">
                        <li className={styles.invList}>Cost/Student <span>£{parseFloat(initialCostPerStudent.toFixed(3))}</span></li>
                        {invoice.wondeAmount && <li className={styles.invList}>Wonde <span>£{invoice.wondeAmount}</span></li>}
                        {invoice.dataDashboardAccessAmount && <li className={styles.invList}>Data dashboard <span>£{invoice.dataDashboardAccessAmount}</span></li>}

                        <li className={styles.invList}>Net<span>£{invoiceAmount.toFixed(2)}</span></li>
                        <li className={styles.invList}>VAT @ 20% <span>£{(invoiceAmount / 100 * 20).toFixed(2)}</span></li>
                        <li className={styles.invList}><b>Total Cost</b>: <span><b>£{(invoiceAmount + (invoiceAmount / 100 * 20)).toFixed(2)}</b></span></li>
                    </ul>
                    <ReactTooltip />
                    {
                        showEditBilling ?
                            <div className={styles.editPanel}>
                                <form onSubmit={handleSubmit(onSubmit)} className='u-m-base-3'>
                                    <div className='basic-form__group'>
                                        <label className='basic-form__text-label' htmlFor='address'>Company Name</label>
                                        <input
                                            name='companyName'
                                            placeholder='Company Name'
                                            defaultValue={invoice.school.billingProfile.companyName}
                                            className='basic-form__text-box'
                                            ref={register}
                                            type='text'
                                        />
                                    </div>
                                    {errors.companyName && <p className='basic-form__hint'>{errors.companyName.message}</p>}
                                    <div className='basic-form__group'>
                                        <label className='basic-form__text-label' htmlFor='address'>Address 1</label>
                                        <input
                                            name='address'
                                            placeholder='Address'
                                            defaultValue={invoice.school.billingProfile.address}
                                            className='basic-form__text-box'
                                            ref={register}
                                            type='text'
                                        />
                                    </div>
                                    {errors.address && <p className='basic-form__hint'>{errors.address.message}</p>}
                                    <div className='basic-form__group'>
                                        <label className='basic-form__text-label' htmlFor='address2'>Address 2</label>
                                        <input
                                            name='address2'
                                            placeholder='Address 2'
                                            defaultValue={invoice.school.billingProfile.address2}
                                            className='basic-form__text-box'
                                            ref={register}
                                            type='text'
                                        />
                                    </div>
                                    <div className='basic-form__group'>
                                        <label className='basic-form__text-label' htmlFor='city'>City</label>
                                        <input
                                            name='city'
                                            placeholder='City'
                                            defaultValue={invoice.school.billingProfile.city}
                                            className='basic-form__text-box'
                                            ref={register}
                                            type='text'
                                        />
                                    </div>
                                    {errors.city && <p className='basic-form__hint'>{errors.city.message}</p>}
                                    <div className='basic-form__group'>
                                        <label className='basic-form__text-label' htmlFor='zip'>Zip</label>
                                        <input
                                            name='zip'
                                            placeholder='Zip'
                                            defaultValue={invoice.school.billingProfile.zip}
                                            className='basic-form__text-box'
                                            ref={register}
                                            type='text'
                                        />
                                    </div>
                                    {errors.zip && <p className='basic-form__hint'>{errors.zip.message}</p>}

                                    {countries.length > 0 &&
                                        <div className='basic-form__group'>
                                            <label className='basic-form__text-label' htmlFor='zip'>Country</label>
                                            <select
                                                defaultValue={invoice.school.billingProfile.country?.code}
                                                name="country"
                                                ref={register}
                                                className="basic-form__text-select"
                                            >
                                                <option value=''>Please select country</option>
                                                {uk && (
                                                    <option value={uk.code}>{uk.name}</option>
                                                )}
                                                <option disabled>──────────</option>
                                                {countries.map((country, i) => (
                                                    <option key={`c-${country.code}`} value={country.code}>{country.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                    {errors.country && <p className='basic-form__hint'>{errors.country.message}</p>}
                                    <div className='basic-form__group'>
                                        <label className='basic-form__text-label' htmlFor='country'>Contact Person</label>
                                        <input
                                            name='contactPerson'
                                            placeholder='Contact Person'
                                            defaultValue={invoice.school.billingProfile.contactPerson}
                                            className='basic-form__text-box'
                                            ref={register}
                                            type='text'
                                        />
                                    </div>
                                    {errors.contactPerson && <p className='basic-form__hint'>{errors.contactPerson.message}</p>}
                                    <div className='basic-form__group'>
                                        <label className='basic-form__text-label' htmlFor='country'>Contact Email</label>
                                        <input
                                            name='contactEmail'
                                            placeholder='Contact Email'
                                            defaultValue={invoice.school.billingProfile.contactEmail}
                                            className='basic-form__text-box'
                                            ref={register}
                                            type='text'
                                        />
                                    </div>
                                    {errors.contactEmail && <p className='basic-form__hint'>{errors.contactEmail.message}</p>}
                                    <div className='basic-form__group'>
                                        <label className='basic-form__text-label' htmlFor='country'>Contact Phone</label>
                                        <input
                                            name='contactPhone'
                                            placeholder='Contact Phone'
                                            defaultValue={invoice.school.billingProfile.contactPhone}
                                            className='basic-form__text-box'
                                            ref={register}
                                            type='text'
                                        />
                                    </div>
                                    {errors.contactPhone && <p className='basic-form__hint'>{errors.contactPhone.message}</p>}
                                    {formErrors && <FormErrors errors={formErrors} />}
                                    <Button type="submit" className='u-m-right-2'>{updating ? 'Saving' : 'Save'}</Button>
                                    <Button outline onClick={() => setShowEditBilling(false)} type='button'>Cancel</Button>
                                </form>
                            </div>
                            :
                            <>
                                <h2>Invoice to:</h2>

                                <div className="u-m-base-1">
                                    <p className="u-display-flex u-align-center">{invoice.school.billingProfile.contactPerson}
                                        <CopyToClipboard text={invoice.school.billingProfile.contactPerson}
                                            onCopy={() => setCopied('contactPerson')}>
                                            <Copy className='u-m-left-1' size={16} data-tip={copied === 'contactPerson' ? 'Copied!' : 'Copy'} />
                                        </CopyToClipboard>
                                    </p>
                                    <p className="u-display-flex u-align-center">{invoice.school.billingProfile.companyName}
                                        <CopyToClipboard text={invoice.school.billingProfile.companyName}
                                            onCopy={() => setCopied('companyName')}>
                                            <Copy className='u-m-left-1' size={16} data-tip={copied === 'companyName' ? 'Copied!' : 'Copy'} />
                                        </CopyToClipboard>
                                    </p>

                                    <p className="u-display-flex u-align-center">{invoice.school.billingProfile.address}
                                        <CopyToClipboard text={billingAddressText}
                                            onCopy={() => setCopied('address')}>
                                            <Copy className='u-m-left-1' size={16} data-tip={copied === 'address' ? 'Copied!' : 'Copy'} />
                                        </CopyToClipboard>
                                    </p>
                                    <p>{invoice.school.billingProfile.address2}</p>
                                    <p>{invoice.school.billingProfile.city}</p>
                                    <p>{invoice.school.billingProfile.zip}</p>
                                    <p>{invoice.school.billingProfile.country?.name}</p>
                                </div>
                                <div className="u-m-base-2">
                                    <p className="u-display-flex u-align-center">Email: {invoice.school.billingProfile.contactEmail}
                                        <CopyToClipboard text={invoice.school.billingProfile.contactEmail}
                                            onCopy={() => setCopied('email')}>
                                            <Copy size={16} className='u-m-left-1' data-tip={copied === 'email' ? 'Copied!' : 'Copy'} />
                                        </CopyToClipboard>
                                    </p>
                                    <p className="u-display-flex u-align-center">Phone: {invoice.school.billingProfile.contactPhone}
                                        <CopyToClipboard text={invoice.school.billingProfile.contactPhone}
                                            onCopy={() => setCopied('phone')}>
                                            <Copy className='u-m-left-1' size={16} data-tip={copied === 'phone' ? 'Copied!' : 'Copy'} />
                                        </CopyToClipboard>
                                    </p>
                                </div>
                            </>
                    }
                    <p className={styles.updateBilling} onClick={() => setShowEditBilling(!showEditBilling)}>Update Billing Profile</p>
                    <p className='u-display-flex u-align-center'>
                        Purchase Order Number: {invoice.purchaseOrderReferenceNumber ? invoice.purchaseOrderReferenceNumber : 'NA'}
                        {invoice.purchaseOrderReferenceNumber && <CopyToClipboard text={invoice.purchaseOrderReferenceNumber}
                            onCopy={() => setCopied('purchaseOrderReferenceNumber')}>
                            <Copy className='u-m-left-1' size={16} data-tip={copied === 'purchaseOrderReferenceNumber' ? 'Copied!' : 'Copy'} />
                        </CopyToClipboard>
                        }
                    </p>
                    {showExternalRef ?
                        <div className='basic-form__group'>
                            <label className='basic-form__text-label' htmlFor='payref'>Invoice Number:</label>
                            <input className='basic-form__text-box' type="text" value={tempRef || ''} onChange={(e) => setTempRef(e.target.value)} />
                            <Button disabled={issuing || editingInvoice} onClick={() => handleInvoiceNum()} className='u-m-right-2 btn-small'>{editingInvoice ? 'Saving' : 'Save'}</Button>
                        </div>
                        :
                        <p className={styles.updateInvoice} onClick={() => setShowExternalRef(true)}>Invoice Number: {invoice.externalId} <Edit className="u-m-left-1" /></p>
                    }
                    {invoice.externalId &&
                        <div className="basic-form__group icon-title u-m-top-2">
                            <p><b>Paid</b></p>
                            <div className="basic-form__switch u-m-left-2">
                                <input
                                    id="paid"
                                    className="switch-input"
                                    type="checkbox"
                                    defaultChecked={invoice.status === INVOICE_STATUS.paid}
                                    name="paid"
                                    disabled={!invoice.externalId || invoice.status === INVOICE_STATUS.paid}
                                    onChange={() => markInvoicePaid({
                                        variables: {
                                            invoiceRequestId: invoice.id
                                        }
                                    })}
                                />
                                <label htmlFor="paid" className="switch-label"></label>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <InvoiceNotes invoice={invoice} />
                    {previous.length > 0 &&
                        <>
                            <h3 className="u-m-top-2">Previous Subscriptions</h3>
                            <div className="u-m-top-1">
                                {previousSorted.map(sub => {
                                    return (
                                        <div key={sub.id} className={styles.prevSub}>
                                            <p><span className="u-bold">{sub.academicYear}</span> {sub.isTrial && '(trial)'}<br />
                                                Started: {moment(new Date(sub.invoiceRequest.orderedAt.substring(0, 10))).format('DD MMM YYYY')}
                                            </p>
                                            <p style={{ marginLeft: 'auto' }}>Students: <span className="u-bold">{sub.invoiceRequest ? sub.invoiceRequest.studentsCounter : 'na'}</span></p>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }

                    <div className="u-m-top-2">
                        {showDelete ? (
                            <>
                                <h3 className="heavy u-m-base-2">Are you sure?</h3>
                                <Button disbaled={deleting} onClick={() => deleteInvoice({
                                    variables: {
                                        invoiceRequestId: invoice.id
                                    }
                                })}>Yes</Button>
                                <Button className='u-m-left-2' outline onClick={() => setShowDelete(false)}>No</Button>

                            </>
                    ) : <Button outline onClick={() => setShowDelete(true)}>Delete Invoice</Button>}
                    </div>
                </div>
            
            </div>
        )
    }
    return null;
}

export default ViewReadInvoice;