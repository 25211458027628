import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import {  EDIT_INVOICE, GET_INVOICE } from '../../../../services/graphql';
import { EDIT_SCHOOL_MUTATION } from '../../../../../../services/school/graphql';

const EditDetails = ({ invoice, setEditDetails }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [editInvoice, { loading }] = useMutation(EDIT_INVOICE, {
        update: (_, { data }) => {
            if (data.editInvoiceRequest.errors && data.editInvoiceRequest.errors.length !== 0) {
                setFormErrors(data.editInvoiceRequest.errors);
                return;
            } else {
                setEditDetails(false);
            }
        }
    });

    const [editSchool, { loading: savingSchool }] = useMutation(EDIT_SCHOOL_MUTATION, {
        update: (_, { data }) => {
            if (data.editSchool.errors && data.editSchool.errors.length !== 0) {
                setFormErrors(data.editSchool.errors);
                return;
            } else {
                setEditDetails(false);
            }
        },
        refetchQueries: [{query: GET_INVOICE, variables: { invoiceRequestId: invoice.id }}]
    })

    const schema = Yup.object().shape({
        name: Yup.string().required('Please enter a school name'),
        contactPerson: Yup.string().required("Please enter contact person"),
        contactPhone: Yup.string().required("Please enter phone"),
        contactEmail: Yup.string().required("Please enter email")  
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = values => {
        const { contactPerson, contactPhone, contactEmail, name, address, address2, city, zip, localAuthority } = values;
        editInvoice({
            variables: {
                invoiceRequestId: invoice.id,
                schoolId: invoice.school.id,
                contactPerson,
                contactPhone,
                contactEmail
            }
        });

        editSchool({
            variables: {
                schoolId: invoice.school.id,
                name,
                address,
                address2,
                city,
                zip,
                localAuthority
            }
        });
    }

    return(
        <Fragment>
            <h2 className='u-m-base-3'>Edit contact details</h2>
            <form onSubmit={handleSubmit(onSubmit)} className='u-m-base-3'>  
                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='name'>School name</label>
                    <input
                        name='name'
                        placeholder='School Name'
                        defaultValue={invoice.school.name}
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='address'>Address 1</label>
                    <input
                        name='address'
                        placeholder='Street Address'
                        defaultValue={invoice.school.address}
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='address2'>Address 2</label>
                    <input
                        name='address2'
                        placeholder='Address 2'
                        defaultValue={invoice.school.address2}
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='city'>City</label>
                    <input
                        name='city'
                        placeholder='City'
                        defaultValue={invoice.school.city}
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='zip'>Postcode</label>
                    <input
                        name='zip'
                        placeholder='Postcode'
                        className='basic-form__text-box'
                        defaultValue={invoice.school.zip}
                        ref={register}
                        type='text'
                    />
                </div>
                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='zip'>Local Authority</label>
                    <input
                        name='localAuthority'
                        placeholder='Local Authority'
                        className='basic-form__text-box'
                        defaultValue={invoice.school.localAuthority}
                        ref={register}
                        type='text'
                    />
                </div>
                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='contactPerson'>Contact person</label>
                    <input
                        type="text" 
                        name="contactPerson"
                        ref={register}
                        defaultValue={invoice.contactPerson}
                        placeholder="Contact person"
                        className='basic-form__text-box'
                    />
                </div>
                {errors.contactPerson && <p className='basic-form__hint'>{errors.contactPerson.message}</p>}

                <div className='basic-form__group'>
                    <label className='basic-form__text-label'  htmlFor='contactEmail'>Contact Email</label>
                    <input
                        type="text" 
                        name="contactEmail"
                        ref={register}
                        defaultValue={invoice.contactEmail}
                        placeholder="Contact email"
                        className='basic-form__text-box'
                    />
                </div>
                {errors.contactEmail && <p className='basic-form__hint'>{errors.contactEmail.message}</p>}

                <div className='basic-form__group'>
                    <label className='basic-form__text-label'  htmlFor='contactPhone'>Contact Phone</label>
                    <input
                        type="text" 
                        name="contactPhone"
                        ref={register}
                        defaultValue={invoice.contactPhone}
                        placeholder="Contact phone"
                        className='basic-form__text-box'
                    />
                </div>
                {errors.contactPhone && <p className='basic-form__hint'>{errors.contactPhone.message}</p>}
                
                {formErrors && <FormErrors errors={formErrors} />}
                <Button type='submit' className='u-m-right-2'>{loading || savingSchool ? 'Saving': 'Save'}</Button>
                <Button outline onClick={() => setEditDetails(false)} type='button'>Cancel</Button>
            </form>
        </Fragment>
    )
}

export default EditDetails;