import React, {useEffect, useState} from 'react'
import { GET_BEE_SCHOOLS_IN_PROGRESS, GET_BEE_SCHOOLS_OVERSUBBED, GET_BEE_SCHOOLS_COMPLETED, GET_BEE_SCHOOLS } from '../services/graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import ListItem from "../../../components/ListItem";
import { Users } from "react-feather";

const FilteredSchools = ({filter}) => {

    const[schools, setSchools] = useState(null)

    const [getSchools, { data, loading, error }] = useLazyQuery(GET_BEE_SCHOOLS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setSchools(data.activeContest.schools)
        }
    });

    const [getSchoolsInProgress, { data: progressData, loading: progressLoading, error: progresserror }] = useLazyQuery(GET_BEE_SCHOOLS_IN_PROGRESS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setSchools(data.activeContest.inProgress)
        }
    });

    const [getSchoolsCompleted, { data: completedData, loading: completedLoading, error: completedError }] = useLazyQuery(GET_BEE_SCHOOLS_COMPLETED, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setSchools(data.activeContest.withCompletedBooks)
        }
    });

    const [getSchoolsOversubbed, { data: overSubbedData, loading: overSubbedLoading, error: overSubbedError }] = useLazyQuery(GET_BEE_SCHOOLS_OVERSUBBED, {
        onCompleted: (data) => {
            setSchools(data.activeContest.oversubscribedSchools)
        }
    });

    useEffect(() => {
        switch(filter) {
            case 'schools_counter':
                getSchools();
                break;
            case 'books_in_progress':
                getSchoolsInProgress();
                break;
            case 'books_completed':
                getSchoolsCompleted();
                break;
            case 'oversubscribed':
                getSchoolsOversubbed();
                break
            default:
                return null
        }
    }, [filter])

    if (loading || progressLoading || completedLoading || overSubbedLoading) {return <p>Loading...</p>}

    if (schools) {
        return schools.length > 0 ? schools.map(s => (
            <ListItem
                sideLabel={<><Users size="20" />{s.actualStudentsCounter}/{s.studentsCounter}</>}
                key={`school-${s.school.id}`}
                linkPath={`/school/${s.school.id}`}
                mainText={<>{s.school.name} <span className='light body-text'>{s.school.schoolCode}</span></>}
                subText={<><b>{s.school.city}</b> <span className='light smaller-text'>{s.school.address} {s.school.address2} &ndash; {s.school.zip}</span></>}
            />
        )) : <p>No matching schools</p>
    }
    return null
}

export default FilteredSchools;