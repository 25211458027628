// Queries and mutations for the Login module
import { gql } from 'apollo-boost';

export const LOGIN = gql`
mutation superadminLogIn($email: String!, $password: String!) {
    superadminLogIn(email: $email, password: $password) {
        user {
            id
            active
            email
            firstName
            lastName
            countryAdmin
        }
        token
        errors
    }
}
`;


export const UPDATE_PASSWORD_MUTATION = gql`
mutation updatePassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    updatePassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
        user {
            id
            active
            email
            firstName
            lastName
            countryAdmin
        }
        errors
        token
    }
}
`;

export const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
        reset
        errors
    }
}
`;