// Queries and mutations used by Add School Scene only;

import { gql } from 'apollo-boost';

//not used anymore, replaced by createPromoSchool
export const ADD_SCHOOL_MUTATION = gql`
mutation createSchool($name: String!, $address: String!, $city: String!, $address2: String!, $zip: String!) {
    createSchool(name: $name, address: $address, city: $city, address2: $address2, zip: $zip) {
        school {
            id
            address
            city
            address2
            name
            zip
        }
        errors
    }
}
`;

export const CREATE_PROMO_SCHOOL_MUTATION = gql`
mutation createPromoSchool($name: String!, $address: String!, $address2: String, $city: String!, $zip: String!,  $roleId: Int!, $country: String!, $schoolPhoneNumber: String!, $weekStart: Int, $avatarUrl: String) {
    createPromoSchool(name: $name, address: $address, address2: $address2, city: $city, zip: $zip, roleId: $roleId, country: $country, schoolPhoneNumber: $schoolPhoneNumber, weekStart: $weekStart, avatarUrl: $avatarUrl) {
        school {
            id
            address
            address2
            name
            city
            country {
                id
                name
                code
            }
            zip
            schoolPhoneNumber
            admins {
                id
                firstName
                lastName
            }
        }
        errors
    }
}
`;