import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { INVOICE_STATUS } from '@axeedge/go-shared-utils';
import { MARK_INVOICE_ISSUED, EDIT_INVOICE, GET_UNPROCESSED_INVOICES, SEARCH_INVOICES, GET_INVOICE } from '../../../../services/graphql';

const InvoiceStatus = ({ invoice, viewBySource, viewBySearch }) => {

    const [formErrors, setFormErros] = useState([]);
    const [showExternalRef, setShowExternalRef] = useState(invoice.externalId);

    const invoicesQueries = () => {
        return [
            { query: GET_INVOICE, variables: { invoiceRequestId: invoice.id } },
            ...viewBySource ? [{ query: GET_UNPROCESSED_INVOICES, variables: { source: viewBySource.app, startFrom: viewBySource.startFrom } }] : [],
            ...viewBySearch ? [{ query: SEARCH_INVOICES, variables: { search: viewBySearch.term, app: viewBySearch.app } }] : []
        ]
    }

    const [markInvoiceAsIssued, { loading: markingIssued }] = useMutation(MARK_INVOICE_ISSUED, {
        update: (_, { data }) => {
            if (data.markInvoiceRequestAsIssued.errors && data.markInvoiceRequestAsIssued.errors.length !== 0) {
                setFormErros(data.markInvoiceRequestAsIssued.errors);
                return;
            }
            setShowExternalRef(data.markInvoiceRequestAsIssued.invoiceRequest.externalId);
        },
        refetchQueries: () => invoicesQueries()

    });

    const [editInvoice, { loading: editingInvoice }] = useMutation(EDIT_INVOICE, {
        update: (_, { data }) => {
            if (data.editInvoiceRequest.errors && data.editInvoiceRequest.errors.length !== 0) {
                setFormErros(data.editInvoiceRequest.errors);
                return;
            }
            setShowExternalRef(data.editInvoiceRequest.invoiceRequest.externalId)
        },
        refetchQueries: () => invoicesQueries()

    });

    const schema = Yup.object().shape({
        showExternalIdField: Yup.boolean(),
        externalId: Yup.string().when('showExternalIdField', {
            is: true,
            then: Yup.string().required("Please enter the Quick Books Ref")
        })
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = values => {
        const { externalId, paid } = values;
        if (invoice.status === INVOICE_STATUS.new) {
            markInvoiceAsIssued({
                variables: {
                    invoiceRequestId: invoice.id,
                    externalId
                }
            })
        } else {
            editInvoice({
                variables: {
                    invoiceRequestId: invoice.id,
                    schoolId: invoice.school.id,
                    externalId: externalId,
                    status: paid ? INVOICE_STATUS.paid : INVOICE_STATUS.issued
                }
            })
        }
    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)} className='u-m-base-3'>
                <input
                    type="hidden"
                    name="showExternalIdField"
                    ref={register}
                    defaultValue={!showExternalRef}
                />
                {
                    showExternalRef ? (
                        <p className='u-m-base-3'>
                            <b>QB Ref: {invoice.externalId}</b>
                            <button onClick={() => setShowExternalRef(false)} className='btn-reset u-text-tertiary u-m-left-2'>edit</button>
                        </p>
                    ) : (
                        <Fragment>
                            <div className='basic-form__group'>
                                <input
                                    type="text"
                                    name="externalId"
                                    ref={register}
                                    defaultValue={invoice.externalId}
                                    placeholder="Quick Book Ref"
                                    className='basic-form__text-box'
                                />
                            </div>
                            {errors.externalId && <p className='basic-form__hint'>{errors.externalId.message}</p>}
                        </Fragment>
                    )
                }

                {
                    invoice.status !== INVOICE_STATUS.new && (
                        <div className="basic-form__group icon-title">
                            <p><b>Paid</b></p>
                            <div className="basic-form__switch u-m-left-2">
                                <input
                                    id="paid"
                                    className="switch-input"
                                    type="checkbox"
                                    defaultChecked={invoice.status === INVOICE_STATUS.paid}
                                    name="paid"
                                    ref={register}
                                />
                                <label htmlFor="paid" className="switch-label"></label>
                            </div>
                        </div>
                    )
                }

                {formErrors && <FormErrors errors={formErrors} />}
                <Button outline>{markingIssued || editingInvoice ? 'Saving' : 'Save'}</Button>
            </form>
        </Fragment>
    )
}

export default InvoiceStatus;