import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { WTR_STATS } from "./services/graphql";
import { Loader } from '@axeedge/go-teacher-components';

import styles from './WriteToRaise.module.scss';

const WtrStats = () => {


    const { data, loading, error } = useQuery(WTR_STATS);

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.writeToRaiseStats) {

        const { writeToRaiseStats } = data;

        return (
            <>
       
                <div className="u-m-base-3 u-display-flex u-m-top-3">
                    <div className={styles.stat}>
                        <p className={styles.statLabel}>Schools showing interest</p>
                        <p className={styles.statCount}>{writeToRaiseStats.find(s => s.key === 'schools_showing_interest').value}</p>
                    </div>
                    <div className={styles.stat}>
                        <p className={styles.statLabel}>Schools signed up</p>
                        <p className={styles.statCount}>{writeToRaiseStats.find(s => s.key === 'schools_signed_up').value}</p>
                    </div>
                    <div className={styles.stat}>
                        <p className={styles.statLabel}>Total no. of books published</p>
                        <p className={styles.statCount}>{writeToRaiseStats.find(s => s.key === 'total_no_books_published').value}</p>
                    </div>
                    <div className={styles.stat}>
                        <p className={styles.statLabel}>Total no. of books purchased</p>
                        <p className={styles.statCount}>{writeToRaiseStats.find(s => s.key === 'total_no_books_purchased')?.value}</p>
                    </div>
                </div>
            </>
        )
    }
    return null;
}

export default WtrStats