import React from 'react';
import styles from '../books.module.scss';
import cx from 'classnames'
import { useQuery } from '@apollo/react-hooks';
import { GET_STATS_QUERY } from '../services/graphql'

const BookStats = ({type}) => {
    const {data} = useQuery(GET_STATS_QUERY, {variables: {
        bookTemplateCategoryId: type !== "ALL" ? type : null,
    }, fetchPolicy: 'network-only'})

    return (
        <div className={cx(styles.counts, "u-m-base-4")}>
            <div className={styles.count}>
                <p>Total</p>
                <p className={styles.countNum}>{data && data.bookStats.find(s => s.key === 'total').value}</p>
                </div>
            <div className={styles.count}>
                <p>Completed</p>
                <p className={styles.countNum}>{data && data.bookStats.find(s => s.key === 'completed').value}</p></div>
            <div className={styles.count}>
                <p>Published</p>
                <p className={styles.countNum}>{data && data.bookStats.find(s => s.key === 'published').value}</p>
            </div>
        </div>
    )
}

export default BookStats;