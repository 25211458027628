// Queries and mutations for the Staff scene

import { gql } from 'apollo-boost';

export const ADD_TEACHER_MUTATION = gql`
mutation addTeacher($schoolId: ID!, $firstName: String!, $lastName: String!, $displayName: String!, $email: String!, $isSchoolAdmin: Boolean!, $roleId: Int) {
    addTeacher(schoolId: $schoolId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, isSchoolAdmin: $isSchoolAdmin, roleId: $roleId) {
        teacher {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
            token
            expiredToken
            roleId
            school {
                id
            }
            token
        }
        errors
    }
}
`;

export const EDIT_TEACHER_MUTATION = gql`
mutation editTeacher($teacherId: ID!, $schoolId: ID!, $firstName: String!, $lastName: String!, $displayName: String!, $email: String!, $isSchoolAdmin: Boolean!, $roleId: Int) {
    editTeacher(teacherId: $teacherId, schoolId: $schoolId, firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, isSchoolAdmin: $isSchoolAdmin, roleId: $roleId) {
        teacher {
            active
            displayName
            email
            firstName
            id
            isSchoolAdmin
            lastName
            token
            expiredToken
            roleId
            school {
                id
            }
            token
        }
        errors
    }
}
`;


export const RESEND_INVITE_EMAIL = gql`
query resendInviteEmail($teacherId: ID!) {
    resendInviteEmail(teacherId: $teacherId)
}
`;



export const MOVE_TEACHER_TO_SCHOOL = gql`
mutation moveTeacherToSchool($teacherId: ID!, $schoolId: ID!) {
    moveTeacherToSchool(teacherId: $teacherId, schoolId: $schoolId) {
        teacher {
            id
            displayName
            school {
                id
                name
            }
        }
        errors
    }
}
`;