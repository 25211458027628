import { INVOICE_STATUS } from '@axeedge/go-shared-utils';

// group invoices by status (ignore partially_paid for now) 
export const groupInvoices = inv => {
    let groups = [];
    const statusGroups = Object.entries(INVOICE_STATUS).filter(e => e[1] !== INVOICE_STATUS.partially_paid);
    statusGroups.forEach(stat => groups.push({categ: stat[1], invoices: []} ));
    inv.forEach(invoice => {
        const group = groups.find(g => g.categ === invoice.status);
        if (group) {
            group.invoices.push(invoice)
        }
    })
   return groups
}

// invoice status label
export const getInvStatusLabel = status => {
    switch(status) {
        case INVOICE_STATUS.new:
            return 'New';
        case INVOICE_STATUS.issued:
            return 'Pending';
        // case INVOICE_STATUS.trial: //No more trial subs on invoices
        //     return 'Trial';
        case INVOICE_STATUS.paid:
        case INVOICE_STATUS.partially_paid:
            return 'Paid';  
        default:
            return '-';
    }
}

// get misc invoice totals from packs array
export const invoiceStatsByKey = (arr, key) => {
    return arr.map(item => item[key]).reduce((acc, value) => acc + +value, 0);
}

// group purchased packs by pack offer
export const groupPurchasedPacks = packs => {
    let offers = [...new Set(packs.map(p => p.packOffer))];
    let groupedPurchases = [];
    offers.map(offer => groupedPurchases.push({
        title: offer.name,
        price: offer.price,
        id: offer.id,
        packs: []
    }))
    
    packs.forEach(pack => {
        const group = groupedPurchases.find(g => g.id === pack.packOffer.id);
        if (group) {
            const extraStudents = pack.maxNumberOfStudents - pack.packOffer.defaultNumberOfStudentsPerInstance * pack.instances;
            const extraAmount = pack.price - pack.packOffer.price;
            group.packs.push({...pack, extraStudents, extraAmount});
        }
     })
    return groupedPurchases
}

// itemized purchases
const parseItemizedOffers = purchasedPack => {
    const classItem = [];
    Object.entries(JSON.parse(purchasedPack.status)).map(e => classItem.push({
        id: e[0] + purchasedPack.id, 
        maxStudents: e[1].counter, 
        studentsClass: e[1].students_class,
        studentsCounter: e[1].students_counter,
        orderId: e[1].order_id,
        offerName: purchasedPack.packOffer.name
    }))
    return classItem
}

export const getItemizedPurchases = (packs) => {
    const itemized = []
    packs.forEach(pPack => {
        itemized.push({
            classes: parseItemizedOffers(pPack)
        })
    })
    return itemized;
}