import React, {useState, useRef, useEffect} from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { GET_BEE_STATS, SEARCH_BEE_SCHOOLS } from "./services/graphql";
import styles from './WritingBee.module.scss'
import cx from 'classnames'
import FilteredSchools from './components/filteredSchools'
import ListItem from "../../components/ListItem";
import { Users, XCircle } from "react-feather";
const ViewSchools = ({beeId}) => {

    const [selectedTab, setSelectedTab] = useState('schools_counter')
    const [showSearch, setShowSearch] = useState(false)
    const searchTerm = useRef(null);
    const getLabel = (term) => {
        switch(term) {
            case 'schools_counter':
                return 'Total'
                break;
            case 'books_in_progress':
                return 'In Progress'
                break;
            case 'books_completed':
                return 'Completed'
                break;
            case 'oversubscribed':
                return 'Over Subbed'
                break
            default:
                return 'something weird'
        }
    }

    const { data, error, loading } = useQuery(GET_BEE_STATS, {
        variables: {
            contestId: beeId
        }
    });

    const [searchSchools, { data: searchData, loading: searchLoading, error: searchError }] = useLazyQuery(SEARCH_BEE_SCHOOLS, {
        fetchPolicy: 'network-only',
        onError: (error) => {
            console.log(error)
        }
    });

    const handleSearch = (e) => {
        e.preventDefault()
        setShowSearch(true)
        searchSchools({variables:{
            contestId: beeId,
            search: searchTerm.current.value
        }})
    }

    const handleTab = (t) => {
        setSelectedTab(t)
        setShowSearch(false)
    }

    if(loading) {
        return <p>Fetching Bee data...</p>
    }
    if (error) {
        return <p>Something went wrong.</p>
    }

    if (data) {
        return (
            <>
                <h2>Bee schools for round 1</h2>
                <div className={styles.statsBar}>
                    <form onSubmit={(e) => handleSearch(e)} className={styles.statsBarSearch}>
                        <input className={styles.searchBar} type="text" ref={searchTerm} placeholder="School Name"/>
                        <button className={styles.searchButton} type="submit">Search</button>
                    </form>
                    <div className={styles.statsBarTabs}>
                        {data.contestStats.map(s => {
                            if (s.key === 'students_counter') {return null}
                            return <button key={s.key} className={cx(styles.statsBarTab, selectedTab === s.key && styles.statsBarTabActive)} onClick={() => handleTab(s.key)}>
                                <span>{s.value}</span>
                                <p>{getLabel(s.key)}</p>
                            </button>
                        })}
                    </div>
                </div>
                {showSearch ?
                        <>
                            {searchLoading && <p>Searching...</p>}
                            {searchError && <p>Something went wrong...</p>}
                            {searchData &&
                                <>
                                    <div className={styles.searchStatus}>
                                        <p>{searchData.searchContestSchools.length} matching schools found</p>
                                        <XCircle onClick={() => setShowSearch(false)}/>
                                    </div>
                                    {searchData.searchContestSchools.map(s => (
                                        <ListItem
                                        sideLabel={<><Users size="20" />{s.actualStudentsCounter}/{s.studentsCounter}</>}
                                        key={`school-${s.school.id}`}
                                        linkPath={`/school/${s.school.id}`}
                                        mainText={<>{s.school.name} <span className='light body-text'>{s.school.schoolCode}</span></>}
                                        subText={<><b>{s.school.city}</b> <span className='light smaller-text'>{s.school.address} {s.school.address2} &ndash; {s.school.zip}</span></>}
                                    />
                                    ))}
                                </>
                            }
                        </>
                        :
                    <FilteredSchools filter={selectedTab} />
                }
            </>
        )
    }
    return null
}

export default ViewSchools;