import React from 'react';
import styles from './OrderItem.module.scss';
import cx from 'classnames';
import { Link } from '@reach/router';
import { getOrderTypeLabel, getOrderStatusLabel } from '../../../../services/utils';
import { Calendar } from 'react-feather';
import { useApolloClient } from '@apollo/react-hooks';
import axios from 'axios';
import { Dropdown } from '@axeedge/go-teacher-components';
import { BOOK_ORDER_TYPE } from '@axeedge/go-shared-utils';

import { PRINT_FEED_URL, INDIVIDUAL_FEED_URL } from '../../../../services/graphql';

const OrderItem = ({ order }) => {

    const client = useApolloClient();

    const onDownloadFeed = () => {
        client.query({
            query: PRINT_FEED_URL,
            variables: {
                printBookOrderId: order.id
            }
        }).then(r => {
            axios({
                method: 'get',
                url: r.data.bookOrderPrintFeedUrl,
                responseType: 'arraybuffer'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/zip' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = order.orderType === BOOK_ORDER_TYPE.class ? order.classBook.bookCode : order.orderNumber;
                link.setAttribute('download', fileName + '.zip');
                document.body.appendChild(link);
                link.click();
            }))
        });
    }

    const onDownloadIndividualFeed = () => {
        client.query({
            query: INDIVIDUAL_FEED_URL,
            variables: {
                printBookOrderId: order.id
            }
        }).then(r => {
            axios({
                method: 'get',
                url: r.data.individualBookOrderPrintFeedUrl,
                responseType: 'arraybuffer'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/zip' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `individual_order_${order.orderNumber}`;
                link.setAttribute('download', fileName + '.zip');
                document.body.appendChild(link);
                link.click();
            }))
        });
    }


    return(
        <div className={styles.item}>
            <Link to={`/orders/${order.id}`} className={styles.itemLink}> </Link>
                <div  className={styles.itemInfo}>
                    <span className={styles.itemRef}>{order.orderNumber}</span>
                    <b>{order.classBook.bookCode} {order.storeCode && `- ${order.storeCode}`}</b>             
                </div>
                <div  className={styles.itemSide}>
                    <span className={styles.itemMeta}><Calendar size="16" /> {order.orderedAt} </span>
                    {
                        order.isCancelled ? (
                            <span className={styles.itemMeta}><span className={cx('label label-accent-red', styles.itemLabel)}>Cancelled</span></span>
                        ) :  <span className={styles.itemMeta}>{getOrderStatusLabel(order.status)}</span>
                    }
                    <span className={cx('label label-muted', styles.itemLabel)}>{getOrderTypeLabel(order.orderType)}</span>
                    <Dropdown classNames='u-m-left-3 u-text-left'>
                        <li><button onClick={() => onDownloadFeed()}>Download feed</button></li>
                        {order.orderType === BOOK_ORDER_TYPE.parent && (
                            <li><button onClick={() => onDownloadIndividualFeed()}>Download individual feed</button></li>
                 
                        )}
                    </Dropdown>
                </div>
           
        </div>
    )
}

export default OrderItem;