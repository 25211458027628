import React from 'react';
import { LogOut } from 'react-feather';
import { Link } from '@reach/router';

import styles from './Header.module.scss';
import LogoutButton from '../LogoutButton';
import logo from '../../images/logos/boomer.svg';

const Header = ({ user }) => {
    return (
        <header className={styles.header}>
            <div className={styles.headerInner}>
                <div  className={styles.headerInnerLeft} >
                    <Link className={styles.headerHomeLink} to="/">
                        <img src={logo} className={styles.headerLogo} alt='Go Apps Logo' />
                        <p className={styles.headerTitle}>Admin</p>
                    </Link>
                    {
                        user && user.countryAdmin !== 'IND' && (
                            <div className={styles.headerLinks}>
                                <Link to='/writeToRaise'>Write To Raise</Link>
                                <Link to='/wtrListSchools'>Write To Raise Schools with Books</Link>
                                <Link to='/users'>All Admins</Link>
                            </div>
                        )
                    }
                </div>


                {
                    user && (
                        <LogoutButton classes={styles.headerLogout}>
                            <LogOut />
                        </LogoutButton>

                    )
                }
            </div>
        </header>
    )
}

export default Header;
