import React, { useState } from "react";
import BackLink from "../../components/BackLink";
import { Lock, ArrowLeft } from "react-feather";
import styles from './WritingBee.module.scss';
import ViewSchools from "./ViewSchools";
import { GET_CONTEST_DATA } from "./services/graphql";
import { useQuery } from "@apollo/react-hooks";

const ManageBee = ({ beeId }) => {

    const [viewSchools, setViewSchools] = useState(false);
    const { data, error, loading } = useQuery(GET_CONTEST_DATA, {
    });
    if(loading) {
        return <p>Fetching Bee data...</p>
    }
    if (error) {
        return <p>Something went wrong.</p>
    }
    if (data) {
        return (
            <>
                {viewSchools ? <button onClick={() => setViewSchools(false)} className={styles.backLink}><ArrowLeft className={styles.backLinkIcon} />{data.activeContest.name}</button> :
                <BackLink to={'/'} text={data.activeContest.name} />}
                {viewSchools ? <ViewSchools close={() => setViewSchools(false)} beeId={data.activeContest.id} /> :
                    <ul className={styles.beeRounds}>
                        <li>
                            <h3>Round 1 </h3>
                            <div className={styles.beeRoundsStats}>
                                <div className="u-m-right-3">
                                    <h3>Schools: {data.activeContest.schoolsCounter}</h3>
                                    <button onClick={() => setViewSchools(true)} className="btn-reset link-underline">View all</button>
                                </div>
                                <div>
                                    <h3>Students: {data.activeContest.studentsCounter}</h3>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h3 className="u-display-flex u-align-center"> Round 2 <Lock className="u-m-left-2" /></h3>
                        </li>
                        <li>
                            <h3 className="u-display-flex u-align-center"> Round 3 <Lock className="u-m-left-2" /></h3>
                        </li>
                    </ul>
                }
            </>
        )
    }
    return <p>Something went wrong.</p>
}

export default ManageBee;