import React, { Fragment, useState } from 'react';
import { Modal, Button, FormErrors } from '@axeedge/go-teacher-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { GET_INVOICE, ADD_INVOICE_COMMENT } from '../../../../services/graphql';
import moment from 'moment';

import styles from '../../ViewInvoice.module.scss';

const InvoiceNotes = ({ invoice }) => {

    const [notesModal, setNotesModal] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const [addInvoiceComment, { loading }] = useMutation(ADD_INVOICE_COMMENT, {
        update: (_, { data }) => {
            if (data.addInvoiceComment.errors && data.addInvoiceComment.errors.length !== 0) {
                setFormErrors(data.addInvoiceComment.errors);
                return;
            } else {
                setNotesModal(false);
            }
        },
        refetchQueries: [{ query: GET_INVOICE, variables: { invoiceRequestId: invoice.id } }]
    })


    const { register, handleSubmit, errors } = useForm({
        validationSchema: Yup.object().shape({
            body: Yup.string().required('Please enter a comment'),
        })
    });


    const onSubmit = values => {
        const { body } = values;
        addInvoiceComment({
            variables: {
                invoiceRequestId: invoice.id,
                body
            }
        });
    }

    return (
        <Fragment>
            <h4 className='u-m-base-1 u-m-top-2'>Notes</h4>
            {invoice.comments.length > 0 && (
                <ul className='u-m-base-3'>
                    {invoice.comments.map(comment => (
                        <li key={comment.id} className={styles.note}>
                            <div className={styles.noteHeader}>
                                {/* <span>{`${comment.author.firstName + ' ' + comment.author.lastName}`}</span> */}
                                <span className={styles.noteDate}>{moment(comment.date.substring(0, 10)).format('DD MMM YYYY')}</span>
                            </div>
                            <p>{comment.body}</p>
                        </li>
                    ))}
                </ul>
            )}

            <Button onClick={() => setNotesModal(true)} outline>Add note</Button>

            {notesModal && (
                <Modal closeModal={() => setNotesModal(false)}>
                    <h2 className='u-m-base-2'>Add note</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <textarea
                            name='body'
                            ref={register}
                            placeholder='Note'
                            className='basic-form__text-box'
                        ></textarea>
                        {errors.body && <p className='basic-form__hint'>{errors.body.message}</p>}
                        {formErrors && <FormErrors errors={formErrors} />}
                        <Button type='submit' className='u-m-right-2'>{loading ? 'Saving' : 'Save'}</Button>
                        <Button outline type='button' onClick={() => setNotesModal(false)}>Cancel</Button>
                    </form>
                </Modal>
            )}
        </Fragment>
    )
}

export default InvoiceNotes;