import React, { useState } from "react";
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { UPDATE_INVOICE_STUDENTS_AND_DURATION } from "../../../../services/graphql"
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { useMutation } from "@apollo/react-hooks";


const UpdateStudentsDuration = ({ invoice, currDuration, close, invoicesQueries }) => {

    const [formErrors, setFormErrors] = useState(null)

    const [updateStudentsDuration, { loading }] = useMutation(UPDATE_INVOICE_STUDENTS_AND_DURATION, {
        awaitRefetchQueries: true,
        update: (_, { data }) => {
            if (data.updateInvoiceStudentsCounterAndDuration.errors && data.updateInvoiceStudentsCounterAndDuration.errors.length !== 0) {
                setFormErrors(data.updateInvoiceStudentsCounterAndDuration.errors);
                return;
            }
            if (data?.updateInvoiceStudentsCounterAndDuration?.invoiceRequest?.id) {
                close()
            }
        },
        refetchQueries: () => invoicesQueries()
    });

    const schema = Yup.object().shape({
        duration: Yup.number().required("Please enter duration"),

        studentsCounter: Yup.number().required("Please enter counter")

    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = values => {
        const { duration, studentsCounter } = values;
        updateStudentsDuration({
            variables: {
                invoiceRequestId: invoice.id,
                duration: +duration,
                studentsCounter: +studentsCounter
            }
        })
    }
    return (

        <div>

            <form onSubmit={handleSubmit(onSubmit)} className='u-m-base-3'>
                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='address'>Students counter</label>
                    <input
                        name='studentsCounter'
                        placeholder='Students Counter'
                        defaultValue={invoice.studentsCounter}
                        className='basic-form__text-box'
                        ref={register}
                        type='number'
                    />
                </div>
                {errors.studentsCounter && <p className='basic-form__hint'>{errors.studentsCounter.message}</p>}

                <div className='basic-form__group'>
                    <label className='basic-form__text-label' htmlFor='address'>Subscription duration</label>
                    <input
                        name='duration'
                        placeholder='Duration'
                        defaultValue={currDuration || 1}
                        className='basic-form__text-box'
                        ref={register}
                        type='number'
                    />
                </div>
                {errors.duration && <p className='basic-form__hint'>{errors.duration.message}</p>}
                {formErrors && <FormErrors errors={formErrors} />}
                <Button disabled={loading} type="submit" className='u-m-right-2'>Save</Button>
                <Button outline onClick={close} type='button'>Cancel</Button>

            </form>

        </div>
    )
}
export default UpdateStudentsDuration;