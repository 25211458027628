import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';

import { APPS, getUrl } from '@axeedge/go-shared-utils';
import { Button, Modal } from '@axeedge/go-teacher-components';

import ListItem from '../../../../../../components/ListItem';
import AddTeacher from './components/AddTeacher';
import EditTeacher from './components/EditTeacher';
import MoveTeacher from './components/MoveTeacher';

import schoolStyles from '../../SchoolHome.module.scss';
import { RESEND_INVITE_EMAIL } from './services/graphql';
import { IMPERSONATE_QUERY } from '../../../../../../services/auth/graphql';

const Staff = ({ school, currentUser }) => {
    const [showAddTeacherModal, setShowAddTeacherModal] = useState(false);
    const [showEditTeacherModal, setShowEditTeacherModal] = useState(false);
    const [showTeacherInfoModal, setShowTeacherInfoModal] = useState(false);
    const [showMoveTeacherModal, setShowMoveTeacherModal] = useState(false);

    const [resendEmail, { loading }] = useLazyQuery(RESEND_INVITE_EMAIL);

    const client = useApolloClient();

    const impersonateTeacher = async (id) => {
        const { data } = await client.query({
            query: IMPERSONATE_QUERY,
            variables: {
                id,
                userType: 'teacher'
            },
            fetchPolicy: 'network-only'
        });
        if (data.loginAs) {
            const { loginAs } = data;
            window.open(`${getUrl(APPS.go_portal_teacher)}/impersonate/${id}/${loginAs}`);
        }
    }

    const closeModal = () => {
        showAddTeacherModal && setShowAddTeacherModal(false);
        showEditTeacherModal && setShowEditTeacherModal(false);
        showTeacherInfoModal && setShowTeacherInfoModal(false);
        showMoveTeacherModal && setShowMoveTeacherModal(false);

    }

    return (
        <Fragment>
            <div className={cx(schoolStyles.subViewTopBar, 'u-m-base-2')}>
                <h1 className="title">Staff</h1>
                {currentUser?.countryAdmin !== 'IND' &&
                    <Button onClick={() => setShowAddTeacherModal(true)} primary>Create Teacher</Button>
                }
            </div>
            <div className="site-admin__list">
                {school.teachers && school.teachers.length ? school.teachers.map(teacher => {
                    return (
                        <ListItem
                            key={`teacher-${teacher.id}`}
                            inactive={!teacher.active}
                            mainText={teacher.displayName}
                            subText={teacher.email}
                            onClick={() => setShowTeacherInfoModal(teacher)}
                            onSettings={() => setShowEditTeacherModal(teacher)}
                            onMove={() => setShowMoveTeacherModal(teacher)}
                            sideLabel={teacher.isSchoolAdmin && <span className='label label-muted label-top'>School admin</span>}
                        />
                    )
                }) : <p>No Staff</p>}
            </div>
            {(showAddTeacherModal || showEditTeacherModal || showTeacherInfoModal || showMoveTeacherModal) && (
                <Modal closeModal={() => closeModal()}>
                    {showAddTeacherModal && <AddTeacher closeModal={() => setShowAddTeacherModal(false)} schoolId={school.id} />}
                    {showEditTeacherModal && <EditTeacher closeModal={() => setShowEditTeacherModal(false)} schoolId={school.id} teacher={showEditTeacherModal} />}
                    {showMoveTeacherModal && <MoveTeacher closeModal={() => setShowMoveTeacherModal(false)} teacher={showMoveTeacherModal} />}

                    {showTeacherInfoModal && (
                        <Fragment>
                            <h1 className="u-m-base-2">{showTeacherInfoModal.displayName}</h1>
                            {
                                showTeacherInfoModal.active ? <p className="u-m-base-2">Account activated!</p> : (
                                    <Fragment>
                                        <p className="u-m-base-2">Account not activated: resend the email invite</p>
                                        <Button
                                            className='u-m-right-2'
                                            onClick={() => resendEmail({
                                                variables: { teacherId: showTeacherInfoModal.id }
                                            })}
                                        >
                                            {loading ? 'Resending...' : 'Resend invite'}
                                        </Button>
                                    </Fragment>
                                )
                            }
                            <Button outline onClick={() => setShowTeacherInfoModal(false)}>Done</Button>
                            <div className='u-m-top-2'>
                                <Button onClick={() => impersonateTeacher(showTeacherInfoModal.id)}>Impersonate</Button>
                            </div>
                        </Fragment>
                    )}
                </Modal>
            )}
        </Fragment>
    )
}

export default Staff;