import React from 'react';

import { Button } from '@axeedge/go-teacher-components';
import { Link } from '@reach/router';

const ResetSent = () => {


    return (
        <div className='login'>
            <div className='u-m-top-3 u-text-center'>
                <h3 className='heavy u-m-y-3 u-text-center'>We've Sent A Link</h3>
                <p className='heavy'>Didn't get the link?</p>
                <p className='u-m-base-2'>Make sure you check your Spam folder, also please make sure you typed in your email address correctly (yeah, we do that too!)</p>
                <Link to='/'><Button>Login</Button></Link>
            </div>
        </div>
    )
}

export default ResetSent;