// This is the root of our app
// Will contain the app client and auth provider and link to router

import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { getClient } from '@axeedge/go-shared-utils';

import { AuthProvider } from '../../services/user/AuthProvider';
import RouterAuthQuery from './components/RouterAuthQuery';
import { APP_NAME } from '../../services/constants';

const Home = () => {
    return (
        <ApolloProvider client={getClient(APP_NAME)} >
            <AuthProvider>
                <RouterAuthQuery />
            </AuthProvider>
        </ApolloProvider>
    )
}

export default Home;