import { gql } from 'apollo-boost';

const bookQuery = `
id
name
description
editableTitle
foreword
isSingleChapter
coverTemplateName
previewUrl
coverUrl
resourcesUrl
isBeeTemplate
ispStarter
videoUrl
chapters {
    id
    content
    description
    guidance
    orderid
    title
}
`;

export const GET_S3_UPLOAD_QUERY = gql`
query s3UploadUrlFor($name: String, $fileType: String, $object: String!) {
    s3UploadUrlFor(name: $name, fileType: $fileType, object: $object) {
        name
        uploadUrl
    }
}
`;


export const GET_BOOK_QUERY = gql`
query bookTemplate($bookTemplateId: ID!) {
    bookTemplate(bookTemplateId: $bookTemplateId) {
        ${bookQuery}
    }
}
`;

export const ADD_BOOK_TEMPLATE_MUTATION = gql`
mutation addBookTemplate($bookTemplateCategoryId: ID!, $name: String!, $description: String!, $editableTitle: Boolean!, $foreword: String!, $coverTemplateName: String, $previewUrl: String, $coverUrl: String, $resourcesUrl: String, $isSingleChapter: Boolean!, $videoUrl: String, $ispStarter: Boolean) {
    addBookTemplate(bookTemplateCategoryId: $bookTemplateCategoryId, name: $name, description: $description, editableTitle: $editableTitle, foreword: $foreword, coverTemplateName: $coverTemplateName, previewUrl: $previewUrl, coverUrl: $coverUrl, resourcesUrl: $resourcesUrl, isSingleChapter: $isSingleChapter, videoUrl: $videoUrl, ispStarter: $ispStarter) {
        bookTemplate {
            id
            chapters {
                id
            }
        }
        errors
    }
}
`;

export const ADD_BOOK_TO_PACK_MUTATION = gql`
mutation addBookTemplateToPack($packId: ID!, $name: String!, $description: String!, $foreword: String!) {
    addBookTemplateToPack(packId: $packId, name: $name, description: $description, foreword: $foreword) {
        bookTemplate {
            id
            chapters {
                id
            }
        }
        errors
    }
}
`;

export const EDIT_BOOK_MUTATION = gql`
mutation editBookTemplate($bookTemplateId: ID!, $bookTemplateCategoryId: ID!, $name: String!, $description: String!, $editableTitle: Boolean!, $foreword: String!, $coverTemplateName: String, $previewUrl: String, $coverUrl: String, $resourcesUrl: String, $isSingleChapter:Boolean, $isBeeTemplate: Boolean!, $videoUrl: String, $ispStarter: Boolean) {
    editBookTemplate(bookTemplateId: $bookTemplateId, bookTemplateCategoryId: $bookTemplateCategoryId, name: $name, description: $description, editableTitle: $editableTitle, foreword: $foreword, coverTemplateName: $coverTemplateName, previewUrl: $previewUrl, coverUrl: $coverUrl, resourcesUrl: $resourcesUrl, isSingleChapter: $isSingleChapter, isBeeTemplate: $isBeeTemplate, videoUrl: $videoUrl, ispStarter: $ispStarter) {
        bookTemplate {
            ${bookQuery}
        }
        errors
    }
}
`;

// Chapters
export const ADD_CHAPTER_TO_BOOK_MUTATION = gql`
mutation addBookTemplateChapter($bookTemplateId: ID!, $title: String!, $description: String, $content: String, $guidance: String, $orderid: Int!) {
    addBookTemplateChapter(bookTemplateId: $bookTemplateId, title: $title, description: $description, content: $content, guidance: $guidance, orderid: $orderid) {
        chapter {
            id
            title
            bookTemplate {
                id
                chapters {
                    id
                }
            }
        }
        errors
    }
}
`;

export const EDIT_CHAPTER_MUTATION = gql`
mutation editBookTemplateChapter($bookTemplateChapterId: ID!, $bookTemplateId: ID!, $title: String!, $description: String, $content: String, $guidance: String, $orderid: Int!) {
    editBookTemplateChapter(bookTemplateChapterId: $bookTemplateChapterId, bookTemplateId: $bookTemplateId, title: $title, description: $description, content: $content, guidance: $guidance, orderid: $orderid) {
        chapter {
            id
        }
        errors
    }
}
`;

export const DELETE_CHAPTER_MUTATION = gql`
mutation deleteBookTemplateChapter($bookTemplateChapterId: ID!) {
    deleteBookTemplateChapter(bookTemplateChapterId: $bookTemplateChapterId) {
        deleted
        errors
    }
}`;