import React, {useState} from 'react'
import BackLink from '../../components/BackLink';
import styles from './Trials.module.scss';
import { TRIAL_SCHOOLS, TRIALS_CSV } from './services/graphql';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import _ from 'lodash'
import moment from 'moment'
import { useNavigate } from '@reach/router';
import * as Icon from 'react-feather'
import axios from 'axios';
import { Button } from '@axeedge/go-teacher-components';
const Trials = () => {
    const [estimate, setEstimate] = useState(0)
    const [total, setTotal] = useState(0)
    const navigate = useNavigate()
    const {data: schoolsData, error: schoolsError, loading: schoolsLoading} = useQuery(TRIAL_SCHOOLS, {
        onCompleted: ({invoiceRequests}) => {
            let estimateStudents = 0;
            let totalStudents = 0
            invoiceRequests.forEach(invoice => {
                estimateStudents = estimateStudents + invoice.studentsCounter;
                totalStudents = totalStudents + invoice.school.studentsCounter;
            })
            setEstimate(estimateStudents);
            setTotal(totalStudents);
        }
    });

    const [getTrialsList, { loading: csvLoading }] = useLazyQuery(TRIALS_CSV, {
        onCompleted: (data) => {
            axios({
                method: 'get',
                url: data.trialInvoicesList,
                responseType: 'blob'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `Trial_Schools`;
                link.setAttribute('download', fileName + '.csv');
                document.body.appendChild(link);
                link.click();
            }));
        }
    })

    if (schoolsLoading) {
        return (
            <Loader />
        )
    }
    if (schoolsError) {
        return <p>{schoolsError.message}</p>
    }

    if (schoolsData) {
        const {invoiceRequests: invoices} = schoolsData;
        return (
            <>
                <BackLink to='/' text='Main Menu' />
                <div className={styles.section}>
                    <div to='/invoices' className={styles.sectionStat}>
                        <div className={styles.sectionCount}>{invoices.length}</div>
                        <p className={styles.sectionTitle}>Schools</p>
                    </div>
                    <div to='/invoices' className={styles.sectionStat}>
                        <div className={styles.sectionCount}>{estimate}</div>
                        <p className={styles.sectionTitle}>Estimated Pupils</p>
                    </div>
                    <div to='/invoices' className={styles.sectionStat}>
                        <div className={styles.sectionCount}>{total}</div>
                        <p className={styles.sectionTitle}>Actual Pupils</p>
                    </div>
                </div>
                <div className={styles.sectionHeader}>
                    <h1>Schools</h1>
                    <Button type='button' onClick={() => getTrialsList()}>{csvLoading ? 'Loading...' : 'Download CSV'}</Button>
                </div>
                {_.map([...invoices].reverse(), invoice => {
                    return (
                            <div key={`trial${invoice.id}`} className={styles.tableRow} onClick={() => navigate(`/school/${invoice.school.id}`)}>
                                <div className={styles.tableMain}> 
                                    <span className={styles.tHeading}>{invoice.school.name}</span><br/>
                                    <span className={styles.tableTeacher}>{invoice.contactPerson}</span> - {invoice.contactEmail}
                                </div>
                                <div className={styles.tableDate}><Icon.Calendar size="18" style={{marginRight: '5px'}}/>{moment(invoice.orderedAt.substring(0,10)).format('DD/MM/YYYY')}</div>
                                <div>{invoice.school.studentsCounter}/{invoice.studentsCounter}</div>
                            </div>
                    )
                })}
            </>
        )
    }
    return <p>Something went wrong</p>
}

export default Trials; 