import React from 'react';
import { ArrowLeft } from 'react-feather';

import styles from './BackButton.module.scss';

const BackButton = ({ text, onClick }) => {
    return (
        <button 
            onClick={onClick}
            className={styles.backButton}
        >
            <ArrowLeft className={styles.backButtonIcon} />{text}
        </button>
    )
}

export default BackButton;
