import React, { Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import ClassItem from './components/ClassItem';
import { GET_ARCHIVED_CLASSES } from '../../../../../../services/school/graphql';

const ArchivedClasses = ({ school }) => {

    const { data, error, loading } = useQuery(GET_ARCHIVED_CLASSES, {
        variables: {
            schoolId: school.id
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>Loading...</p>
    }

    if (data && data.school) {

        const { school } = data;

        return (
            <Fragment>
                {school.archivedClasses && school.archivedClasses.length > 0 ? (
                    <>
                        {_.sortBy(school.archivedClasses, c => { return c.name.toLowerCase() }).map(studentsClass => {
                            return <ClassItem
                                key={`class-${studentsClass.id}`}
                                classData={studentsClass}
                                school={school}
                                archivedTab={true}
                            />
                        })}
                    </>
                ) : <p>No archived classes</p>}
            </Fragment>
        );
    }
    return null;
}

export default ArchivedClasses;
