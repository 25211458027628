import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { MOVE_TEACHER_TO_SCHOOL } from '../../services/graphql';
import { SEARCH_SCHOOLS } from '../../../../../../../../services/school/graphql';
import styles from './MoveTeacher.module.scss';
import { X } from 'react-feather';
import { navigate } from '@reach/router';
import loader from '../../../../../../../../images/loader.svg';

const MoveTeacher = ({ closeModal, teacher }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [movedToSchool, setMovedToSchool] = useState(null);

    const intervalRef = useRef(null);

    const [searchSchools, { loading, error }] = useLazyQuery(SEARCH_SCHOOLS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.schoolsSearch) {
                setResults(data.schoolsSearch);
            }
        }
    });


    const [moveTeacher, { loading: movingTecher }] = useMutation(MOVE_TEACHER_TO_SCHOOL, {
        update: (_, { data }) => {
            if (data.moveTeacherToSchool.errors && data.moveTeacherToSchool.errors.length !== 0) {
                setFormErrors(data.editTeacher.errors);
                return;
            }
            if (data.moveTeacherToSchool && data.moveTeacherToSchool.teacher && data.moveTeacherToSchool.teacher.id) {
                setMovedToSchool(data.moveTeacherToSchool);
            }
        },
    })

    const getSuffix = email => {
        return email.substring(email.lastIndexOf("@") + 1);
    }

    useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            intervalRef.current = setTimeout(() => {
                searchSchools({ variables: { text: searchTerm } })
            }, 600);
        } else {
            setResults([]);
            clearTimeout(intervalRef.current);
        }
        return () => clearTimeout(intervalRef.current);
    }, [searchTerm]);

    return (
        <div className='site-admin__modal__content'>
            <h2 className='title u-m-base-2'>Move to another school</h2>
            <div>
                {
                    movedToSchool ? (
                        <Fragment>
                            <h3 className='u-m-base-3'>{movedToSchool.teacher.displayName} has been transferred to {movedToSchool.teacher.school.name}</h3>
                            <Button onClick={() => { closeModal(); navigate(`/school/${movedToSchool.teacher.school.id}`) }}>View</Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h3 className='heavy u-m-base-3'>Find the school you wish to transfer {teacher.displayName} to.</h3>
                            {
                                selectedSchool ? (
                                    <Fragment>
                                        <div className='u-display-flex u-justify-between u-align-center'>
                                            <h3 className='heavy'>
                                                {selectedSchool.name}
                                                {selectedSchool.financeOfficerEmail && <span className='u-text-primary'>({getSuffix(selectedSchool.financeOfficerEmail)})</span>}
                                            </h3>
                                            <div className='u-display-flex u-align-center'>
                                                <Button onClick={() => {
                                                    moveTeacher({
                                                        variables: {
                                                            teacherId: teacher.id,
                                                            schoolId: selectedSchool.id
                                                        }
                                                    });
                                                }}>{movingTecher ? 'Saving ...' : 'Select'}</Button>
                                                <button onClick={() => { setSelectedSchool(null); setSearchTerm(""); }} className='btn-reset link-underline u-m-left-2'><X /></button>
                                            </div>
                                        </div>
                                        {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <div className='basic-form__group'>
                                            <input
                                                name='search'
                                                className='basic-form__text-box'
                                                type='text'
                                                autoComplete='off'
                                                placeholder='Type to search by school name'
                                                value={searchTerm}
                                                style={{ marginRight: '40px' }}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            {loading && <img width="30" src={loader} alt='loading' />}
                                        </div>
                                        {error && <p>{error.message}</p>}
                                        {results.length > 0 && (
                                            <div className={styles.resultsWrapper}>
                                                <ul className={styles.resultsWrapperList}>
                                                    {results.map(result => (
                                                        <li key={result.id}>
                                                            <h4 onClick={() => { setSelectedSchool(result); setResults([]) }}>
                                                                {result.name} {result.financeOfficerEmail && <span className='u-text-primary'>({getSuffix(result.financeOfficerEmail)})</span>}
                                                            </h4>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </Fragment>
                                )
                            }
                        </Fragment>
                    )
                }
            </div>
        </div>
    )
}

export default MoveTeacher;

