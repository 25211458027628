import React, { useState } from "react";
import { Search } from 'react-feather';
import { useLazyQuery } from "@apollo/react-hooks";
import { MAIN_SEARCH_SCHOOLS } from "../services/graphql";
import styles from '../Schools.module.scss';

const SearchSchools = ({ india = false, setResults, setNoResults }) => {

    const [searchTerm, setSearchTerm] = useState('');

    const [searchSchools] = useLazyQuery(MAIN_SEARCH_SCHOOLS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.schoolsSearch) {
                if (data.schoolsSearch.length > 0) {
                    setResults(data.schoolsSearch);
                    setNoResults(false);
                } else {
                    setResults([]);
                    setNoResults(true);
                }
            }
        }
    });

    const search = (e) => {
        e.preventDefault();
        if (searchTerm.length > 0) {
            searchSchools({ variables: { text: searchTerm } })
        }
    }

    return (
        <>
            <form className={styles.formSearch} onSubmit={search}>
                <input
                    name='search'
                    className='basic-form__text-box'
                    type='text'
                    placeholder='Search by name / school code / zip code'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className={styles.formSearchBtn}><Search size='18' /></button>
            </form>
        </>
    )
}

export default SearchSchools;