import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';
import { getRoleLabel, usaOnlyRoles } from '../../../../../../../../services/utils';
import { EDIT_TEACHER_MUTATION } from '../../services/graphql';
import { GET_SCHOOL_QUERY } from '../../../../../../../../services/school/graphql';

const EditTeacher = ({ closeModal, schoolId, teacher }) => {
    const [formErrors, setFormErrors] = useState([]);

    const [editTeacher, { loading: savingTeacher }] = useMutation(EDIT_TEACHER_MUTATION, {
        update: (_, { data }) => {
            if (data.editTeacher.errors && data.editTeacher.errors.length !== 0) {
                setFormErrors(data.editTeacher.errors);
                return;
            }

            if (data.editTeacher.teacher.id) {
                closeModal();
            }
        },
        refetchQueries: [{ query: GET_SCHOOL_QUERY, variables: { schoolId } }]
    })

    const schema = Yup.object().shape({
        firstName: Yup.string().required('Please enter first name e.g. Penny'),
        lastName: Yup.string().required('Please enter first name e.g. Lane'),
        displayName: Yup.string().required('Please enter a display name e.g. Mrs Lane'),
        email: Yup.string().email().required('Please enter an email address e.g. pennylane@yourschool.ac.uk'),
        roleId: Yup.string().required('Please select a role'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: 'onBlur'
    });

    const onSubmit = values => {
        const { firstName, lastName, displayName, email, isSchoolAdmin, roleId } = values;
        editTeacher({
            variables: {
                firstName, 
                lastName, 
                displayName, 
                email, 
                isSchoolAdmin,
                schoolId,
                teacherId: teacher.id,
                roleId: +roleId
            }
        });
        closeModal();
    }

    return (
        <div className='site-admin__modal__content'>
            <h1 className='title u-m-base-2'>Edit Teacher</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    <input
                        name='firstName'
                        placeholder='First Name e.g. Penny'
                        className='basic-form__text-box'
                        ref={register}
                        defaultValue={teacher.firstName}
                        type='text'
                    />
                </div>
                {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                <div className='basic-form__group'>
                    <input
                        name='lastName'
                        placeholder='Last Name e.g. Lane'
                        className='basic-form__text-box'
                        ref={register}
                        defaultValue={teacher.lastName}
                        type='text'
                    />
                </div>
                {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}
                <div className='basic-form__group'>
                    <input
                        name='displayName'
                        placeholder='Display Name e.g. Mrs Lane'
                        className='basic-form__text-box'
                        ref={register}
                        defaultValue={teacher.displayName}
                        type='text'
                    />
                </div>
                {errors.displayName && <p className='basic-form__hint'>{errors.displayName.message}</p>}
                <div className='basic-form__group'>
                    <input
                        name='email'
                        placeholder='Email e.g. pennylane@yourschool.ac.uk'
                        className='basic-form__text-box'
                        ref={register}
                        defaultValue={teacher.email}
                        type='email'
                    />
                </div>
                {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}
                <div className='basic-form__group basic-form__group--check'>
                    <input
                        name='isSchoolAdmin'
                        className='basic-form__check-box'
                        ref={register}
                        defaultChecked={teacher.isSchoolAdmin}
                        type='checkbox'
                    />
                    <label className='basic-form__check-label' htmlFor='isSchoolAdmin'>Administrator</label>
                </div>

                <p>Select the teacher's role at the school</p>
                <div className='basic-form__group u-m-base-2'>
                    <select defaultValue={teacher.roleId} name="roleId" ref={register} className="basic-form__text-select">
                        <option value=''>Select role</option>
                        {
                            Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                            })
                        }
                    </select>
                    {errors.roleId && <p className="basic-form__hint u-m-top-0">{errors.roleId.message}</p>}
                </div>

                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className='u-display-flex u-align-center'>
                    <Button type='submit' className='u-m-right-1'>{savingTeacher ? 'Saving...' : 'Save changes'}</Button>
                    <Button type='button' outline onClick={() => closeModal()} >Cancel</Button>
                </div>
            </form>
        </div>
        
    )
}

export default EditTeacher;

