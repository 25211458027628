import React from 'react';
import BackLink from '../../components/BackLink';
import styles from './WritingBee.module.scss';
import ListItem from '../../components/ListItem';
import { navigate } from '@reach/router';

const WritingBees = () => {

    const bees = [ {
        id: 123434,
        name: 'National Writing Bees',
        status: 'active'
    }]

    return(
        <>
            <BackLink to='/' text='Writing Bees' />
            <ul className={styles.beeList}>
                {bees.map(bee => (
                    <ListItem
                        onClick={() => navigate(`writingBees/${bee.id}`)}
                         key={bee.id}
                         mainText={bee.name}
                         sideLabel={bee.status}
                     />
                ))}
            </ul>
        </>
    )

}

export default WritingBees;