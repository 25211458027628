import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { Link } from '@reach/router';
import { Calendar } from 'react-feather';
import moment from 'moment';

import { Button, FormErrors, Dropdown } from '@axeedge/go-teacher-components';
import { BOOK_ORDER_STATUS, BOOK_ORDER_TYPE } from '@axeedge/go-shared-utils';

import { getOrderStatusLabel, getOrderTypeLabel, getStatusSelectOpts } from '../../../../services/utils';
import styles from '../../ViewOrder.module.scss';
import axios from 'axios';
import cx from 'classnames';
import { APPS, getUrl } from '@axeedge/go-shared-utils';
import { IMPERSONATE_QUERY } from '../../../../../../services/auth/graphql';
import CancelOrder from '../CancelOrder';

import {
    SEND_ORDER_TO_PRINTER,
    SEND_ORDER_TO_DESTINATION,
    PRINT_FEED_URL,
    INDIVIDUAL_FEED_URL,
    SCHOOL_FEED_URL
} from '../../../../services/graphql';

const OrderStatus = ({ order, parentClassOrder }) => {

    const [formErrors, setFormErros] = useState([]);
    const [showTracking, setShowTracking] = useState(+order.status === BOOK_ORDER_STATUS.delivered);
    const [showCancel, setShowCancel] = useState(false);

    const client = useApolloClient();

    const [sendOrderToPrinter, { loading: sendingToPrinter }] = useMutation(SEND_ORDER_TO_PRINTER, {
        update: (_, { data }) => {
            if (data.sendPrintBookOrderToPrinter.errors && data.sendPrintBookOrderToPrinter.errors.length !== 0) {
                setFormErros(data.sendPrintBookOrderToPrinter.errors);
                return;
            }
        }
    });

    const [sendOrderToDestination, { loading: sendingToDestination }] = useMutation(SEND_ORDER_TO_DESTINATION, {
        update: (_, { data }) => {
            if (data.sendPrintBookOrderToDestination.errors && data.sendPrintBookOrderToDestination.errors.length !== 0) {
                setFormErros(data.sendPrintBookOrderToDestination.errors);
                return;
            }
        }
    });

    const schema = Yup.object().shape({
        status: Yup.string().required('Please select a status')
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema
    });

    const onSubmit = ({ status, trackingNumber }) => {
        switch (+status) {
            case BOOK_ORDER_STATUS.to_printer:
                return sendOrderToPrinter({ variables: { printBookOrderId: order.id } });
            case BOOK_ORDER_STATUS.delivered:
                return sendOrderToDestination({ variables: { printBookOrderId: order.id, trackingNumber } });
            default:
                return null
        }
    }

    const checkShowTracking = e => {
        setShowTracking(+e.target.value === BOOK_ORDER_STATUS.delivered);
    }

    const impersonateTeacher = async (id) => {
        const { data } = await client.query({
            query: IMPERSONATE_QUERY,
            variables: {
                id,
                userType: 'teacher'
            },
            fetchPolicy: 'network-only'
        });
        if (data.loginAs) {
            const { loginAs } = data;
            window.open(`${getUrl(APPS.go_portal_teacher)}/impersonate/${id}/${loginAs}`);
        }
    }

    const onDownloadFeed = () => {
        client.query({
            query: PRINT_FEED_URL,
            variables: {
                printBookOrderId: order.id
            }
        }).then(r => {
            axios({
                method: 'get',
                url: r.data.bookOrderPrintFeedUrl,
                responseType: 'arraybuffer'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/zip' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = order.orderType === BOOK_ORDER_TYPE.class ? order.classBook.bookCode : order.orderNumber;
                link.setAttribute('download', fileName + '.zip');
                document.body.appendChild(link);
                link.click();
            }))
        });
    }

    const onDownloadIndividualFeed = () => {
        client.query({
            query: INDIVIDUAL_FEED_URL,
            variables: {
                printBookOrderId: order.id
            }
        }).then(r => {
            axios({
                method: 'get',
                url: r.data.individualBookOrderPrintFeedUrl,
                responseType: 'arraybuffer'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/zip' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `individual_order_${order.orderNumber}`;
                link.setAttribute('download', fileName + '.zip');
                document.body.appendChild(link);
                link.click();
            }))
        });
    }

    const onDownloadSchoolFeed = () => {
        client.query({
            query: SCHOOL_FEED_URL,
            variables: {
                schoolId: order.school.id
            }
        }).then(r => {
            axios({
                method: 'get',
                url: r.data.schoolPrintFeedUrl,
                responseType: 'arraybuffer'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/zip' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `school_order_${order.orderNumber}`;
                link.setAttribute('download', fileName + '.zip');
                document.body.appendChild(link);
                link.click();
            }))
        });
    }

    return (
        <Fragment>
            <div className='u-text-right'>
                {
                    order.isCancelled ? <span className='label u-m-right-1 label-accent-red'>Cancelled</span> :
                        <span className={cx('label u-m-right-1 label-muted', { 'label-accent-red': order.status === BOOK_ORDER_STATUS.pending })}>{getOrderStatusLabel(order.status)}</span>
                }

                <span className='label label-muted u-m-right-1'>{getOrderTypeLabel(order.orderType)}</span>
                <Dropdown titleLeft="Options" classNames='u-m-left-1 u-text-left'>
                    <li><button onClick={() => { impersonateTeacher(order.classBook.studentsClass.teachers[0].id) }}>Impersonate Teacher</button></li>

                    {order.orderType === 2 ? (
                        <li><button onClick={() => onDownloadSchoolFeed()}>Download feed</button></li>
                    ) : (
                        <>
                            {order.orderType === BOOK_ORDER_TYPE.class && order.invoiceRequest && <li><Link to={`/invoices/${order.invoiceRequest.id}`}>View Invoice</Link></li>}
                            <li><button onClick={() => onDownloadFeed()}>Download feed</button></li>
                            {order.orderType === BOOK_ORDER_TYPE.parent && (
                                <>
                                    <li><button onClick={() => onDownloadIndividualFeed()}>Download individual feed</button></li>
                                    <li><button onClick={() => setShowCancel(true)}>{order.isCancelled ? 'Un-cancel order' : 'Cancel Order'}</button></li>
                                    {parentClassOrder && <li><Link to={`/orders/${parentClassOrder.id}`}>View Class Order</Link></li>}
                                </>
                            )}
                        </>
                    )}


                </Dropdown>
            </div>

            {order.status !== BOOK_ORDER_STATUS.pending && !order.isCancelled && (
                <form onSubmit={handleSubmit(onSubmit)} className='u-m-top-3 u-m-base-3'>
                    <p><b>Set status:</b></p>
                    <div className="basic-form__group">
                        <select
                            onChange={(e) => checkShowTracking(e)}
                            defaultValue={order.status}
                            ref={register}
                            name='status'
                            className='basic-form__text-select'
                        >
                            <option value=''>Select status</option>
                            {
                                getStatusSelectOpts.map(opt => (
                                    <option key={`opt-${opt.value}`} value={opt.value}>{opt.label}</option>
                                ))
                            }
                        </select>
                    </div>
                    {errors.status && <p className='basic-form__hint'>{errors.status.message}</p>}

                    {
                        showTracking && (
                            <div className='u-m-base-2'>
                                Tracking Details:
                                <input
                                    type='text'
                                    placeholder="Add tracking link"
                                    className='basic-form__text-box'
                                    ref={register}
                                    name='trackingNumber'
                                    defaultValue={order.trackingNumber}
                                />
                            </div>
                        )
                    }
                    {formErrors && <FormErrors errors={formErrors} />}
                    <Button outline>{sendingToPrinter || sendingToDestination ? 'Saving ' : 'Update'}</Button>
                </form>
            )}


            <ul className={styles.orderTimeline}>
                <li>
                    <div>Ordered:</div>
                    <div><Calendar className='u-m-right-1' />{moment(new Date(order.orderedAt)).format('DD/MM/YYYY')}</div>
                </li>
                <li>
                    <div>Sent to printer:</div>
                    <div><Calendar className='u-m-right-1' />{order.sendToPrinterAt ? moment(new Date(order.sendToPrinterAt)).format("DD/MM/YYYY") : '--'} </div>
                </li>
                <li>
                    <div>Shipped:</div>
                    <div><Calendar className='u-m-right-1' /> {order.sendToDestinationAt ? moment(new Date(order.sendToDestinationAt)).format("DD/MM/YYYY") : '--'}</div>
                </li>

                {order.isCancelled && (
                    <li>
                        <div>Cancelled:</div>
                        <div><Calendar className='u-m-right-1' /> {moment(new Date(order.cancelledAt)).format("DD/MM/YYYY")}</div>
                    </li>
                )}
            </ul>

            {showCancel && <CancelOrder order={order} closeCancel={() => setShowCancel(false)} />}
        </Fragment>
    )
}

export default OrderStatus;