import { gql } from 'apollo-boost';

export const FIND_PARENT_QUERY = gql`
query searchParent($email: String!) {
    searchParent(email: $email) {
        id
        firstName
        lastName
        email
        activationLink
        active
        requestedDeletion
        parentLoginCode
        children {
            id
            student {
                id
                firstName
                lastName
                school {
                    id
                    name
                }
            }
        }
    }
}
`;

export const FIND_TEACHER_QUERY = gql`
query searchTeacher($email: String!) {
    searchTeacher(email: $email) {
        id
        firstName
        lastName
        email
        activationLink
        displayName
        active
        isSchoolAdmin
        school {
            id
            name
        }
    }
}
`;

export const REACTIVATE_PARENT = gql`
mutation updateParentRequestDeletion($parentId: ID!) {
    updateParentRequestDeletion(parentId: $parentId) {
        parent {
            id
            firstName
            lastName
            email
            activationLink
            active
            requestedDeletion
        }
        errors
    }
}
`;


export const DELETE_PARENT = gql`
mutation deleteParent($parentId: ID!) {
    deleteParent(parentId: $parentId) {
        deleted
        errors
    }
}
`;


export const DELETE_TEACHER = gql`
mutation deleteTeacher($teacherId: ID!) {
    deleteTeacher(teacherId: $teacherId) {
        deleted
        errors
    }
}
`;