import React, { useState, useEffect } from 'react';
import BackLink from '../../../../components/BackLink';
import { Loader } from '@axeedge/go-teacher-components';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_UNPROCESSED_INVOICES, SEARCH_INVOICES } from '../../services/graphql';
import { Search } from 'react-feather';
import Highlighted from '../../components/highlight';
import SideModal from '../../../../components/SideModal';
import ViewInvoice from '../ViewInvoice';
import { SOURCE_INVOICES } from '../../../../services/constants';
import moment from 'moment';
import styles from '../../Invoices.module.scss';
import cx from 'classnames';

const InvoicesBySource = ({ source }) => {

    const [type, setType] = useState('all');
    const [startFrom, setStartFrom] = useState(90);
    const [sortOption, setSortOption] = useState('oldest');
    const [showInvoice, setShowInvoice] = useState(null);

    const [invoices, setInvoices] = useState([]);
    const [visibleInvoices, setVisibleInvoices] = useState(invoices);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearch, setIsSearch] = useState(false);

    const [fetchInvoices, { data, loading, error }] = useLazyQuery(GET_UNPROCESSED_INVOICES, {
        fetchPolicy: 'network-only'
    });

    const [searchInvoices, { data: dataSearch }] = useLazyQuery(SEARCH_INVOICES, {
        fetchPolicy: 'network-only',
        onCompleted: () => setIsSearch(true)
    });

    useEffect(() => {
        fetchInvoices({
            variables: {
                source: +source,
                ...(+startFrom !== 90 && { startFrom: +startFrom })
            }
        });
    }, [startFrom]);

    useEffect(() => {
        if (data && data.unprocessedInvoiceRequests) {
            setInvoices(data.unprocessedInvoiceRequests)
        }
    }, [data]);

    useEffect(() => {
        if (dataSearch && dataSearch.searchInvoiceRequests) {
            setSearchResults(dataSearch.searchInvoiceRequests);
        }
    }, [dataSearch]);


    useEffect(() => {
        let results = isSearch ? [...searchResults] : [...invoices];
        if (sortOption === 'oldest') {
            setVisibleInvoices(results.sort((a, b) => a.orderedAt?.localeCompare(b.orderedAt)).filter(inv => (type === 'renewal' ? (inv.schoolHasTwoOrMoreBoomreaderSubscription || inv.renewal) : type === 'new' ? (!inv.schoolHasTwoOrMoreBoomreaderSubscription && !inv.renewal) : inv)));
        } else if (sortOption === 'newest') {
            setVisibleInvoices(results.sort((a, b) => b.orderedAt?.localeCompare(a.orderedAt)).filter(inv => type === 'renewal' ? (inv.schoolHasTwoOrMoreBoomreaderSubscription || inv.renewal) : type === 'new' ? (!inv.schoolHasTwoOrMoreBoomreaderSubscription && !inv.renewal) : inv));
        } else if (sortOption === 'highest_amount') {
            setVisibleInvoices(results.sort((a, b) => b.amount - a.amount).filter(inv => type === 'renewal' ? (inv.schoolHasTwoOrMoreBoomreaderSubscription || inv.renewal) : type === 'new' ? (!inv.schoolHasTwoOrMoreBoomreaderSubscription && !inv.renewal) : inv));
        } else if (sortOption === 'lowest_amount') {
            setVisibleInvoices(results.sort((a, b) => a.amout - b.amount).filter(inv => type === 'renewal' ? (inv.schoolHasTwoOrMoreBoomreaderSubscription || inv.renewal) : type === 'new' ? (!inv.schoolHasTwoOrMoreBoomreaderSubscription && !inv.renewal) : inv));
        }
    }, [sortOption, type, setVisibleInvoices, invoices, searchResults]);

    const handleSearch = e => {
        e.preventDefault();
        searchInvoices({
            variables: {
                search: searchTerm,
                app: +source
            }
        })
    }

    const resetSearch = () => {
        setIsSearch(false);
        setSearchResults([]);
        setSearchTerm('');
    }


    const getTitleText = () => {
        switch (+source) {
            case SOURCE_INVOICES.boom_reader:
                return 'BoomReader Subscriptions'
            case SOURCE_INVOICES.boom_math:
                return 'BoomMath Subscriptions'
            case SOURCE_INVOICES.boom_writer_plus:
                return "Write+ Subscriptions"
            case SOURCE_INVOICES.book_packs:
                return 'Book Packs '
            case SOURCE_INVOICES.data_dash:
                return 'Data Dashboard & Wonde Integration'
            case SOURCE_INVOICES.wonde:
                return 'Wonde Integration '
            default:
                return 'Invoices'
        }
    }

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.unprocessedInvoiceRequests) {

        return (
            <div className='u-m-top-2'>
                <BackLink to='/invoices' text={getTitleText()} />
                <div className={styles.container}>
                    <div className={styles.containerSide}>
                        <ul className={styles.invoiceTypes}>
                            <li><button className={cx(styles.invoiceTypesBtn, { [styles.invoiceTypesBtnActive]: type === 'all' })} onClick={() => { setType('all'); resetSearch() }}>All ({invoices.length})</button></li>
                            {+source === SOURCE_INVOICES.boom_reader && (
                                <>
                                    <li><button className={cx(styles.invoiceTypesBtn, { [styles.invoiceTypesBtnActive]: type === 'new' })} onClick={() => { setType('new'); resetSearch() }}>New ({invoices.filter(inv => !inv.schoolHasTwoOrMoreBoomreaderSubscription && !inv.renewal).length})</button></li>
                                    <li><button className={cx(styles.invoiceTypesBtn, { [styles.invoiceTypesBtnActive]: type === 'renewal' })} onClick={() => { setType('renewal'); resetSearch() }}>Renewal ({invoices.filter(inv => inv.schoolHasTwoOrMoreBoomreaderSubscription || inv.renewal).length})</button></li>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className={styles.containerMain}>
                        <div className={styles.invoiceActions}>
                            <div className={styles.invoiceActionsSearch}>
                                <form onSubmit={handleSearch}>
                                    <input value={searchTerm} onChange={(e) => setSearchTerm(e.currentTarget.value)} className={styles.invoiceActionsSearchField} type='text' placeholder='Invoice # / School / Postcode' />
                                    <button type="submit" className={cx('btn-reset', styles.invoiceActionsSearchBtn)}><Search /></button>
                                </form>
                            </div>
                            <div className={styles.invoiceActionsSort}>
                                <div className="basic-form__group u-m-right-2">
                                    <select
                                        disabled={isSearch}
                                        onChange={(e) => setStartFrom(e.target.value)}
                                        defaultValue={startFrom}
                                        name='sort'
                                        className='basic-form__text-select'
                                    >
                                        <option value={30}>Last 30 days</option>
                                        <option value={60}>Last 60 days</option>
                                        <option value={90}>Last 90+ days</option>
                                    </select>
                                </div>

                                <div className="basic-form__group">
                                    <select
                                        onChange={(e) => setSortOption(e.target.value)}
                                        defaultValue={sortOption}
                                        name='sort'
                                        className='basic-form__text-select'
                                    >
                                        <option value={'oldest'}>Submitted (Oldest first)</option>
                                        <option value={'newest'}>Submitted (Newest first)</option>
                                        <option value={'highest_amount'}>Highest amount</option>
                                        <option value={'lowest_amount'}>Lowest amount</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <table className={styles.invoiceTable}>
                            <thead>
                                <tr>
                                    <th className={styles.invoiceTableNo}>Invoice #</th>
                                    <th className={styles.invoiceTableMain}>School</th>
                                    <th>Submitted</th>

                                    {+source === SOURCE_INVOICES.boom_reader && (
                                        <>
                                            <th>Plan</th>
                                            <th>Type</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {visibleInvoices.map(invoice => (
                                    <tr key={invoice.id} onClick={() => setShowInvoice(invoice)}>
                                        <td className={styles.invoiceTableNo}>{invoice.externalId || '--'}</td>
                                        <td className={styles.invoiceTableMain}>
                                            <h4 className={styles.invoiceTableTitle}>
                                                {isSearch ?
                                                    <Highlighted text={invoice.school.name} highlight={searchTerm} /> :
                                                    invoice.school.name
                                                }
                                            </h4>
                                            <p className='u-m-base-1'>{invoice.school.city}, {invoice.school.zip}</p>
                                            {invoice.school.billingProfile &&
                                                <p>Invoice:
                                                    {isSearch ?
                                                        <>
                                                            <Highlighted text={` ${invoice.school.billingProfile.companyName}`} highlight={searchTerm} />,
                                                            <Highlighted text={` ${invoice.school.billingProfile.zip}`} highlight={searchTerm} />
                                                        </> :
                                                        <>{invoice.school.billingProfile.companyName}, {invoice.school.billingProfile.zip}</>
                                                    }
                                                </p>
                                            }
                                        </td>
                                        <td>{moment(new Date(invoice.orderedAt.substring(0, 10))).format('DD MMM YYYY')}</td>

                                        {+source === SOURCE_INVOICES.boom_reader && (
                                            <>
                                                <td>

                                                    <p>{invoice.subscription ? (invoice.subscription.duration || 1) : invoice.renewal?.schoolSubscription?.duration} year</p>
                                                    {(invoice.subscription?.duration === 2 || invoice.renewal?.schoolSubscription?.duration) && (
                                                        <i>({invoice.renewal ? '2' : '1'} of 2)</i>
                                                    )}
                                                </td>
                                                <td><span className={styles.invoiceLabel}>{(invoice.schoolHasTwoOrMoreBoomreaderSubscription || invoice.renewal) ? 'Renewal' : 'New'}</span></td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {showInvoice && (
                    <SideModal closeModal={() => setShowInvoice(null)}>
                        <div className="row">
                            <div className="col-lg-8">
                                <ViewInvoice
                                    viewBySource={!isSearch && { ...(+startFrom !== 90 && { startFrom: +startFrom }), app: +source }}
                                    viewBySearch={isSearch && { term: searchTerm, app: +source }}
                                    isModal={true}
                                    invoiceId={showInvoice.id}
                                    close={() => setShowInvoice(null)}
                                />
                            </div>
                        </div>
                    </SideModal>
                )}
            </div>
        )
    }
    return null;
}

export default InvoicesBySource;

