import React from 'react';
import { Delete, Watch, Settings, Move, PlusCircle } from 'react-feather';
import { Link } from '@reach/router';
import cx from 'classnames';

import styles from './ListItem.module.scss';

const ListItem = ({ inactive, linkPath, mainText, short, onClick, onDelete, onSettings, onMove, onAdd, subText, sideLabel }) => {
    return (
        <div className={cx(styles.listItem, { [styles.short]: short })}>
            <div className={styles.listItemLeft}>
                <div className={styles.listItemLeftMain}>
                    {
                        inactive && <Watch className="u-m-right-1" />
                    }
                    {
                        linkPath && (
                            <Link className={styles.listItemMainText} to={linkPath}>
                                {mainText}
                            </Link>
                        )
                    }
                    {
                        onClick && (
                            <p className={styles.listItemMainText} onClick={onClick}>
                                {mainText}
                            </p>
                        )
                    }
                </div>
                {
                    subText && <p className={styles.listItemSubText}>{subText}</p>
                }
            </div>
            <div className={styles.listItemRight}>
                {sideLabel && <>{sideLabel}</>}
                {onSettings && <Settings className={styles.listItemRightButton} onClick={onSettings} />}
                {onDelete && <Delete className={styles.listItemRightButton} onClick={onDelete} />}
                {onMove && <Move className={styles.listItemRightButton} onClick={onMove} />}
                {onAdd && <PlusCircle className={styles.listItemRightButton} onClick={onAdd} />}

            </div>
        </div>
    );
}

export default ListItem;
