import { gql } from 'apollo-boost';

export const ADMIN_STATS = gql`
query adminStats{
    adminStats {
        id
        key
        value
    }
}
`;