import React, { useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { DELETE_PARENT, FIND_PARENT_QUERY, REACTIVATE_PARENT } from './services/graphql';
import { IMPERSONATE_QUERY } from '../../services/auth/graphql';
import FindTeacher from './FindTeacher';
import { Link } from '@reach/router';
import _ from 'lodash';

import { Button, Modal } from '@axeedge/go-teacher-components';
import { APPS, getUrl } from '@axeedge/go-shared-utils';
import SaveConfirm from '../../components/SaveConfirm';
import styles from './FindParent.module.scss';
import cx from 'classnames';

const FindParent = () => {

    const [parentEmail, setParentEmail] = useState('');
    const [parents, setParents] = useState([]);

    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [deleteParent, setDeleteParent] = useState(null);

    const [formErrors, setFormErrors] = useState(null);

    const client = useApolloClient();

    const findParents = async () => {
        const { data } = await client.query({
            query: FIND_PARENT_QUERY,
            variables: { email: parentEmail },
            fetchPolicy: 'network-only'
        });
        setParents(data.searchParent);
    }

    const impersonateParent = async (id) => {
        const { data } = await client.query({
            query: IMPERSONATE_QUERY,
            variables: {
                id,
                userType: 'parent'
            },
            fetchPolicy: 'network-only'
        })
        if (data.loginAs) {
            const { loginAs } = data;
            window.open(`${getUrl(APPS.go_parents_desktop)}/impersonate/${id}/${loginAs}`);
        }
    }

    const [reactivateParent, { loading }] = useMutation(REACTIVATE_PARENT, {
        onCompleted: data => {
            if (data && data.updateParentRequestDeletion && data.updateParentRequestDeletion.errors && data.updateParentRequestDeletion.errors.length > 0) {
                setFormErrors(data.updateParentRequestDeletion.errors[0]);
                return;
            }
            if (data && data.updateParentRequestDeletion && data.updateParentRequestDeletion.parent && data.updateParentRequestDeletion.parent.id) {
                const newParents = [];
                parents.forEach(parent => {
                    if (parent.id === data.updateParentRequestDeletion.parent.id) {
                        newParents.push(data.updateParentRequestDeletion.parent)
                    }
                    else {
                        newParents.push(parent);
                    }
                })
                setParents(newParents);
            }
        },
    })

    const [deleteParentAccount, { loading: deletingParent }] = useMutation(DELETE_PARENT, {
        onCompleted: data => {
            if (data && data.deleteParent && data.deleteParent.errors && data.deleteParent.errors.length > 0) {
                setFormErrors(data.deleteParent.errors[0]);
                return;
            }
            if (data && data.deleteParent?.deleted) {
                setShowSaveConfirm(true);
                setParents([]);
                setDeleteParent(null);
            }
        },
    })

    const groupedBySchool = children => {
        return _.chain(children)
            .groupBy("student.school.id")
            .map((value, key) => ({ school: { id: key, name: value[0].student.school?.name || 'No school' }, children: value }))
            .value()
    }


    const handleSubmit = e => {
        e.preventDefault();
        findParents();
    }

    return (
        <div>
            <h1 className='u-m-base-3 u-m-top-2'>Find Parents</h1>

            {showSaveConfirm && <SaveConfirm hideConfirm={() => setShowSaveConfirm(false)} message={'Parent deleted!'} />}
            <form onSubmit={handleSubmit}>
                <div className="basic-form__group">
                    <input
                        onChange={e => setParentEmail(e.target.value)}
                        value={parentEmail}
                        type='email'
                        placeholder='Parent Email'
                        className={cx('basic-form__text-box', 'u-m-right-2', styles.formInput)}
                    />
                    <Button type='submit'>Find</Button>
                </div>
            </form>
            <div className={styles.listing}>
                {
                    parents && parents.length > 0 && parents.map(parent => (
                        <div key={`p-${parent.id}`} className='card u-m-base-3'>
                            <div className="card-body">
                                <div className='u-display-flex u-justify-between u-m-base-1'>
                                    <h3 className='light'> <b>{parent.firstName} {parent.lastName}</b> ({parent.children.length === 1 ? '1 child' : `${parent.children.length} children`})</h3>
                                    {parent.active ? <span className='label label-primary u-display-flex u-align-center'>Active</span> : <span className='label label-muted u-display-flex u-align-center'>Inactive</span>}
                                </div>
                                <p>Parent Login Code: {parent.parentLoginCode || '--'}</p>
                                {formErrors && <p>{formErrors}</p>}
                                {parent.requestedDeletion ? (
                                    <p>Parent requested deletion:
                                        <Button
                                            className='u-m-left-2'
                                            disabled={loading}
                                            onClick={() => reactivateParent({
                                                variables: {
                                                    parentId: parent.id
                                                }
                                            })}
                                        >{loading ? 'Saving...' : 'Reactivate'}</Button>
                                    </p>
                                ) : (
                                    <>
                                        {parent.activationLink && <p>Activation link: <a href={parent.activationLink}>{parent.activationLink}</a></p>}

                                        {parent.children.length > 0 && (
                                            groupedBySchool(parent.children).map((el, i) => (
                                                <div className='u-m-base-1 u-m-top-1' key={`group-${i}`}>
                                                    <p><b>{el.children.length === 1 ? '1 child' : el.children.length + ' children'} at {
                                                        el.school.id === 'undefined' ? 'No school' :
                                                            <Link className={styles.link} to={`/school/${el.school.id}`}>{el.school.name}</Link>
                                                    }
                                                    </b></p>
                                                    <ul className={styles.childrenList}>
                                                        {el.children.map(child =>
                                                            <li key={child.id}>
                                                                <p>{child.student.firstName} {child.student.lastName}</p>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            ))
                                        )}
                                        <div className='u-m-top-2 u-display-flex u-justify-between'>
                                            <Button onClick={() => impersonateParent(parent.id)}>Impersonate</Button>
                                            <Button outline onClick={() => setDeleteParent(parent)}>Delete parent</Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className='separator'></div>
            <FindTeacher />

            {deleteParent && (
                <Modal closeModal={() => setDeleteParent(null)}>
                    <div className="u-text-center">
                        <h2 className='u-m-base-3'>Are you sure you want to delete {deleteParent.firstName} {deleteParent.lastName}?</h2>

                        <Button disabled={deletingParent} onClick={() => deleteParentAccount({
                            variables: {
                                parentId: deleteParent.id
                            }
                        })}>Yes</Button>
                        <Button outline className='u-m-left-2' onClick={() => setDeleteParent(null)}>No</Button>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default FindParent;