import React from 'react';
import { Link } from '@reach/router';
import styles from '../../Invoices.module.scss';
import { useQuery } from '@apollo/react-hooks';
import { GET_INVOICE_STATS } from '../../services/graphql';
import { Loader } from '@axeedge/go-teacher-components';
import { SOURCE_INVOICES } from '../../../../services/constants';

const InvoicesDashboard = () => {


    const { data, loading, error } = useQuery(GET_INVOICE_STATS);

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.invoiceStats) {
        const { invoiceStats } = data;

        return (
            <>
                <h1 className='u-m-base-2 u-m-top-2'>Subscriptions &amp; Invoicing</h1>
                <h2>Invoices pending</h2>
                <ul className={styles.invoicesTypes}>
                    <li><Link to={`/invoicesBySource/${SOURCE_INVOICES.boom_reader}`} className={styles.invoicesTypesItem}>Reader ({invoiceStats.find(s => s.id === 'boom_reader')?.value})</Link></li>
                    <li><Link to={`/invoicesBySource/${SOURCE_INVOICES.boom_writer_packs}`} className={styles.invoicesTypesItem}>Book Packs ({invoiceStats.find(s => s.id === 'boom_writer_packs')?.value})</Link></li>
                    <li><Link to={`/invoicesBySource/${SOURCE_INVOICES.boom_writer_plus}`} className={styles.invoicesTypesItem}>Write+ ({invoiceStats.find(s => s.id === 'boom_writer_plus')?.value})</Link></li>
                    <li><Link to={`/invoicesBySource/${SOURCE_INVOICES.boom_math}`} className={styles.invoicesTypesItem}>BoomMath+ ({invoiceStats.find(s => s.id === 'boom_math')?.value || 0})</Link></li>
                    <li><Link to={`/invoicesBySource/${SOURCE_INVOICES.data_dash}`} className={styles.invoicesTypesItem}>Data Dashboard & Wonde ({invoiceStats.find(s => s.id === 'data_dashboard')?.value || 0})</Link></li>
                    <li><Link to={`/invoicesBySource/${SOURCE_INVOICES.wonde}`} className={styles.invoicesTypesItem}>Wonde ({invoiceStats.find(s => s.id === 'wonde_integration')?.value || 0})</Link></li>

                </ul>
            </>
        )
    }
    return null;
}

export default InvoicesDashboard;

