import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Dropdown, FormErrors } from '@axeedge/go-teacher-components';
import axios from 'axios';

import ListItem from '../../components/ListItem';
import ButtonLink from '../../components/ButtonLink';
import BackLink from '../../components/BackLink';
import { DELETE_SCHOOL_MUTATION, EXPORT_TEACHER_LIST } from './services/graphql';
import styles from './Schools.module.scss';
import SearchSchools from './components/searchSchools';
import SaveConfirm from '../../components/SaveConfirm';

const Schools = () => {


    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);

    const [formErrors, setFormErrors] = useState([]);


    const [deleteSchool] = useMutation(DELETE_SCHOOL_MUTATION, {
        update: (_, { data }) => {
            if (data.deleteSchool.errors && data.deleteSchool.errors.length > 0) {
                setFormErrors(data.deleteSchool.errors);
                return;
            }
            if (data.deleteSchool.deleted) {
                setShowSaveConfirm(true);
            }
        },
    });

    useEffect(() => {
        if (toDelete) {
            const updatedResults = results.filter(r => r.id !== toDelete.id);
            setResults(updatedResults);
            setToDelete(null);
        }
    }, [toDelete]);



    const onDeleteSchool = school => {
        let goAhead = window.confirm(`Are you sure you want to delete ${school.name}?`);
        if (goAhead) {
            setToDelete(school);
            deleteSchool({
                variables: {
                    schoolId: school.id
                }
            });
        }
    }

    const [getAllTeachers, { loading: gettingTeachers }] = useLazyQuery(EXPORT_TEACHER_LIST, {
        onCompleted: (data) => {
            axios({
                method: 'get',
                url: data.exportTeacherList,
                responseType: 'blob'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `Teacher_List`;
                link.setAttribute('download', fileName + '.csv');
                document.body.appendChild(link);
                link.click();
            }));
        }
    });


    const [getAdmins, { loading: gettingAdmins }] = useLazyQuery(EXPORT_TEACHER_LIST, {
        onCompleted: (data) => {
            axios({
                method: 'get',
                url: data.exportTeacherList,
                responseType: 'blob'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `Admin_List`;
                link.setAttribute('download', fileName + '.csv');
                document.body.appendChild(link);
                link.click();
            }));
        }
    });




    return (
        <Fragment>
            <BackLink to='/' text='Main Menu' />

            <div className={styles.sectionHeader}>
                <h1>Schools</h1>
                <div>
                    <ButtonLink to='/school/new'>Add A School</ButtonLink>
                    <Dropdown titleLeft="Download CSV" classNames='u-m-left-3 u-text-left'>
                        <li><button onClick={() => getAdmins({ variables: { adminOnly: true } })}>{gettingAdmins ? 'Loading...' : 'All School Admins'}</button></li>
                        <li><button onClick={() => getAllTeachers({ variables: { adminOnly: false } })}>{gettingTeachers ? 'Loading...' : 'All Teachers'}</button></li>
                    </Dropdown>
                </div>
            </div>
            <div className={styles.sectionListContainer}>

                <SearchSchools setResults={setResults} setNoResults={setNoResults} />

                {formErrors && formErrors.length > 0 && <FormErrors errors={formErrors} />}

                {showSaveConfirm && <SaveConfirm  message='School deleted!' hideConfirm={() => setShowSaveConfirm(false)} />}

                {results.length ? (
                    <div className={styles.sectionList}>
                        {
                            results.map(school => {
                                return (
                                    <ListItem
                                        key={`school-${school.id}`}
                                        linkPath={`/school/${school.id}`}
                                        mainText={<>{school.name} <span className='light body-text'>{school.schoolCode}</span> {school.canAccessWonde && <span className='u-m-left-2 light body-text label label-muted' >Wonde</span>}</>}
                                        subText={<><b>{school.city}</b> <span className='light smaller-text'>{school.address} {school.address2} &ndash; {school.zip}</span></>}
                                        onDelete={() => onDeleteSchool(school)}
                                    />
                                )
                            })
                        }
                    </div>
                ) : noResults && <p className="u-m-top-3"><b>No schools found</b></p>}
            </div>
        </Fragment>
    )
}

export default Schools;
