import { TEACHER_JOB_ROLES } from '@axeedge/go-shared-utils';

// for getting info about purchased packs
export const getNumberOfPurchasedPacks = purchasedPacks => {
    let count = 0;
    if (purchasedPacks && purchasedPacks.length) {
        purchasedPacks.forEach(pack => {
            count = count + pack.instances;
        });
    }
    return count;
}

export const getInstancesOfPack = (purchasedPacks, packId) => {
    let count = 0;
    if (purchasedPacks && purchasedPacks.length) {
        purchasedPacks.forEach(pPack => {
            if (pPack.pack.id === packId) {
                count = count + pPack.instances;
            }
        });
    }
    return count;
}

// for getting number about available packs for a school
export const getNumberOfAvailablePacks = purchasedPacks => {
    let count = 0;
    if (purchasedPacks && purchasedPacks.length) {
        purchasedPacks.forEach(pack => {
            count = count + pack.instances - (pack.studentsClasses && pack.studentsClasses.length);
        });
    }
    return count;
}

export const usaOnlyRoles = [TEACHER_JOB_ROLES.tutor, TEACHER_JOB_ROLES.math_lead, TEACHER_JOB_ROLES.curriculum_specialist]
export const ukOnlyRoles = [TEACHER_JOB_ROLES.english_lead, TEACHER_JOB_ROLES.teacher_assistant]


export const getRoleLabel = role => {
    switch (role) {
        case TEACHER_JOB_ROLES.teacher:
            return 'Teacher';
        case TEACHER_JOB_ROLES.teacher_assistant:
            return 'Teaching Assistant (TA)';
        case TEACHER_JOB_ROLES.senior_leadership_team:
            return 'Senior Leadership Team';
        case TEACHER_JOB_ROLES.office:
            return 'Office';
        case TEACHER_JOB_ROLES.english_lead:
            return 'English/Reading/Literacy Lead';
        case TEACHER_JOB_ROLES.volunteer:
            return 'Volunteer';
        case TEACHER_JOB_ROLES.tutor:
            return 'Tutor';
        case TEACHER_JOB_ROLES.math_lead:
            return 'Math Lead/Math Coach';
        case TEACHER_JOB_ROLES.curriculum_specialist:
            return 'Curriculum Specialist';
        default:
            return '--'
    }
}

export const WEEK_STARTS = {
    1: 'Monday - Monday',
    2: 'Tuesday - Tuesday',
    3: 'Wednesday - Wednesday',
    4: 'Thursday - Thursday',
    5: 'Friday - Friday',
    6: 'Saturday - Saturday',
    7: 'Sunday - Sunday'
}

