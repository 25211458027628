import React from "react";
import { useQuery } from "@apollo/react-hooks";
import BackLink from "../../components/BackLink";
import styles from '../Trials/Trials.module.scss';
import { WONDE_SCHOOLS } from './services/graphql';
import { navigate } from "@reach/router";
import moment from "moment";
import { AlertCircle, CheckCircle, Info } from "react-feather";

const WONDE_SYNC_STEPS = {
    syncTeachers: 0,
    syncStudentsUpn: 1,
    syncStudentsSuggested: 2,
    syncStudentsManual: 3,
    syncClasses: 4,
    syncExtraStaff: 5,
    syncCompleted: 99
}

const WondeSchools = () => {

    const { data, loading, error } = useQuery(WONDE_SCHOOLS);

    const renderSyncStep = (step) => {
        switch (step) {
            case WONDE_SYNC_STEPS.syncTeachers:
                return <span className="u-display-flex u-align-center"><AlertCircle size={18} className="u-m-right-1" />Sync not started</span>;
            case WONDE_SYNC_STEPS.syncStudentsUpn:
            case WONDE_SYNC_STEPS.syncStudentsSuggested:
            case WONDE_SYNC_STEPS.syncStudentsManual:
                return <span className="u-display-flex u-align-center"><Info size={18} className="u-m-right-1" />Teachers Synced</span>;
            case WONDE_SYNC_STEPS.syncClasses:
                return <span className="u-display-flex u-align-center"><Info size={18} className="u-m-right-1" />Student Synced</span>;
            case WONDE_SYNC_STEPS.syncExtraStaff:
                return <span className="u-display-flex u-align-center"><Info size={18} className="u-m-right-1" />Classes Synced</span>;
            case WONDE_SYNC_STEPS.syncCompleted:
                return <span className="u-display-flex u-align-center"><CheckCircle size={18} className="u-m-right-1" />Sync Completed!</span>
        }
    }
    if (loading) {
        return <p>Loading</p>
    }
    if (error) {
        return <p>{error.message}</p>
    }
    if (data?.wondeSchools) {
        const { wondeSchools } = data;

        return (
            <>
                <BackLink to='/' text='Main Menu' />
                <h1>Wonde Schools ({wondeSchools.length})</h1>
                {wondeSchools.map(school => {
                    return (
                        <div key={`s${school.id}`} className={styles.tableRow} onClick={() => navigate(`/school/${school.id}`)}>
                            <div className={styles.tableMain}>
                                <span className={styles.tHeading}>{school.name}</span><br />

                                <div className={`${styles.tableTeacher} ${'u-m-top-1'}`}>
                                    {school.wondeAccessApprovedAt && <span>Access approved at: {moment(school.wondeAccessApprovedAt.substring(0, 10)).format('MMMM DD, YYYY')}</span>}

                                </div>
                            </div>
                            <div className={styles.tableDate}>
                                {renderSyncStep(school.wondeSyncStep)}
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }
}

export default WondeSchools;