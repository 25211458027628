import React, { useState } from 'react';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { GET_BOOK_TYPES_QUERY } from '../../../services/pack/graphql';
import { Dropdown } from '@axeedge/go-teacher-components';
import { GET_BOOKS_QUERY } from '../services/graphql';
import styles from '../books.module.scss'
import * as Icon from 'react-feather';
import cx from 'classnames';
import BookStats from './BookStatsQuery'
import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import { useApolloClient } from '@apollo/react-hooks';
import { IMPERSONATE_QUERY } from '../../../services/auth/graphql';
import { APPS, getUrl } from '@axeedge/go-shared-utils';

const BookTypesQuery = () => {
    const {data, error, loading} = useQuery(GET_BOOK_TYPES_QUERY);

    const [filter, setFilter] = useState({
        type: "ALL",
        term: '',
        status: "ALL",
        page: 1,
    });

    const [results, setResults] = useState(null);

    const client = useApolloClient();


    const findBooks = async () => {
        setFilter({...filter, page: 1})
        const { data: bookData} = await client.query({
            query: GET_BOOKS_QUERY,
            variables: {
                search: filter.term,
                bookTemplateCategoryId: filter.type !== "ALL" ? filter.type: null,
                status: filter.status !== "ALL" ? parseInt(filter.status) : null,
                page: 1,
            },
            fetchPolicy: 'network-only'
        });
        setResults(bookData.bookList);
    }

    const loadMore = async () => {
        setFilter({...filter, page: filter.page + 1})
        const { data: bookData} = await client.query({
            query: GET_BOOKS_QUERY,
            variables: {
                search: filter.term,
                bookTemplateCategoryId: filter.type !== 0 ? filter.type: null,
                status: filter.status !== -1 ? parseInt(filter.status) : null,
                page: filter.page,
            },
            fetchPolicy: 'network-only'
        });
        setResults(results.concat(bookData.bookList));
    }

    const impersonateTeacher = async (id) => {
        const { data } = await client.query({
            query: IMPERSONATE_QUERY,
            variables: { 
                id,
                userType: 'teacher' 
            },
            fetchPolicy: 'network-only'
        });
        if (data.loginAs) {
            const { loginAs } = data;
            window.open(`${getUrl(APPS.go_portal_teacher)}/impersonate/${id}/${loginAs}`);
        }
    }

    const handleSearch = e => {
        e.preventDefault();
        findBooks();
    }

    const getStatus = (status) => {
        switch (status) {
            case BOOK_STATUS.new:
                return <Icon.Edit />
            case BOOK_STATUS.started:
                return <Icon.Play />
            case BOOK_STATUS.completed:
                return <Icon.CheckCircle />
            default: 
                return <Icon.Book /> 
        }
    }

    if (loading) {
        return <p>Loading...</p>
    }
    if (data) {
        return (
            <>
            <div className={cx(styles.labelSet, "u-m-base-4")}>
                <label className="u-m-right-1">Book Type:</label>
                <select value={filter.type} onChange={(e) => setFilter({...filter, type: e.currentTarget.value})}>
                    <option value={"ALL"}>All Books</option>
                    {data.bookTemplateCategories.map(type => {
                        return (
                            <option key={`type${type.id}`} value={type.id}>{type.name}</option>
                        )
                    })}
                </select>
            </div>
            <BookStats type={filter.type} />
            <div className={cx(styles.labelSet)}>
                <form onSubmit={handleSearch} className={cx(styles.labelSet, "u-m-right-2")}>
                    <input type="text" placeholder="Order/Email/School Name" className={styles.searchBar} value={filter.term} onChange={(e) => setFilter({...filter, term: e.currentTarget.value})} />
                    <button type="submit" className={styles.searchBtn}>
                        <Icon.Search size="20"/>
                    </button>
                </form>
                <div className={cx(styles.labelSet, styles.labelMid)}>
                    <label className="u-m-right-1">Status:</label>
                    <select  value={filter.status} onChange={(e) => setFilter({...filter, status: e.currentTarget.value})}> 
                        <option value={"ALL"}>All Books</option>
                        <option value={BOOK_STATUS.new}>Draft</option>
                        <option value={BOOK_STATUS.started}>Started</option>
                        <option value={BOOK_STATUS.completed}>Completed</option>
                        <option value={3}>Published</option>{/*custom value */}
                    </select>
                </div>
            </div>
            <div className="u-m-top-2">
                {results ?
                    <>
                        {results.items.length === 0 ? 
                        <p>No matching books found.</p> :
                        <>
                            <div className={cx(styles.bookRow, styles.bookRowHead)}>
                                <div className={styles.bookRowCode}>Code</div>
                                <div className={styles.bookRowMeta}>Details</div>
                                <div className={styles.bookRowClass}>Class</div>
                                <div className={styles.bookRowClass}>Parent</div>
                                <div className={styles.bookRowClass}>Status</div>
                                <div className={styles.bookRowClass}>&nbsp;</div>
                            </div>
                            {results.items.map(b => {
                                return (
                                <div key={b.id} className={styles.bookRow}>
                                    <div className={styles.bookRowCode}>{b.bookCode}</div>
                                    <div className={styles.bookRowMeta}>{b.bookTemplate.name}, {b.studentsClass.school.name}<br />{b.studentsClass.name}, {b.studentsClass.teachers.length > 0 ? b.studentsClass.teachers[0].displayName: 'Teacher name not available'}</div>
                                    <div className={styles.bookRowClass}>{b.orderStats.classOrder > 0 ? <Icon.CheckCircle /> : <Icon.XCircle /> }</div>
                                    <div className={styles.bookRowClass}>{b.orderStats.parentOrders}</div>
                                    <div className={styles.bookRowClass}>{getStatus(b.status)}</div>
                                    <div className={styles.bookRowClass}>
                                        <Dropdown>
                                            {b.studentsClass.teachers.length > 0 && <li><button onClick={()=> {impersonateTeacher(b.studentsClass.teachers[0].id)}}>Impersonate &amp; View</button></li> }
                                            {/*<li><Link to={`/orders/`}>View All Orders</Link></li>*/}
                                            <li><Link to={`/school/${b.studentsClass.school.id}`}>View School</Link></li>
                                        </Dropdown>
                                    </div>
                                </div>
                                )
                            })}
                        </>
                        }
                    </>
                    :
                    <p>Search for something</p>
                }
            </div>
            </>
        )
    }

}

export default BookTypesQuery;