import { gql } from 'apollo-boost';

export const GET_SCHOOL_QUERY = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        name
        classes {
            id
            name
            students {
                id
            }
        }
    }
}
`;

export const GET_CONTEST = gql`
    query activeContest{
        activeContest {
            id
        }
    }
`

export const GET_CONTEST_DATA = gql`
    query activeContest{
        activeContest {
            id
            name
            schoolsCounter
            studentsCounter
        }
    }
`

export const GET_BEE_STATS = gql`
    query beeStats($contestId: ID!) {
        contestStats(contestId: $contestId) {
            id
            key
            value
        }
    }
`

export const ADD_TO_BEE = gql`
mutation enrollSchoolToContest($schoolId: ID!, $contestId: ID!, $studentsClassId: ID!, $studentsCounter: ID!) {
    enrollSchoolToContest(schoolId: $schoolId, contestId: $contestId, studentsClassId: $studentsClassId, studentsCounter: $studentsCounter) {
        errors
        school {
            id
        }
    }
}
`
const contestMembership = `
    id
    studentsCounter
    actualStudentsCounter
    school {
        id
        name
        schoolCode
        city
        address
        zip
    }
    studentsClass {
        id
    }
`

export const GET_BEE_SCHOOLS = gql`
    query activeContest{
        activeContest {
            id
            schools {
                ${contestMembership}
            }
        }
    }
`

export const GET_BEE_SCHOOLS_IN_PROGRESS = gql`
    query activeContest{
        activeContest {
            id
            inProgress {
                ${contestMembership}
            }
        }
    }
`

export const GET_BEE_SCHOOLS_COMPLETED = gql`
    query activeContest{
        activeContest {
            id
            withCompletedBooks {
                ${contestMembership}
            }
        }
    }
`

export const GET_BEE_SCHOOLS_OVERSUBBED = gql`
    query activeContest{
        activeContest {
            id
            oversubscribedSchools {
                ${contestMembership}
            }
        }
    }
`
export const SEARCH_BEE_SCHOOLS = gql`
    query searchContestSchools($contestId: ID!, $search: String!) {
        searchContestSchools(contestId: $contestId, search: $search) {
            ${contestMembership}
        }
    }
`