// Pack mutations and queries used in more than one place
import { gql } from 'apollo-boost';

export const GET_PACKS_QUERY = gql`
query packs {
    packs {
        id
        name
    }
}
`;

export const GET_BOOK_TYPES_QUERY = gql`
query bookTemplateCategories {
    bookTemplateCategories {
        id
        name
    }
}
`;