import React, { useState } from "react";
import { CREATE_SCHOOL_PRINT_BOOK_ORDER, WTR_SCHOOLS_WITH_BOOKS, WTR_SCHOOL_CSV } from "./services/graphql";
import { Link } from '@reach/router';
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useApolloClient } from '@apollo/react-hooks';
import { IMPERSONATE_QUERY } from "../../services/auth/graphql";
import { APPS, getUrl } from '@axeedge/go-shared-utils';
import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import { FormErrors } from "@axeedge/go-teacher-components";
import ButtonLink from "../../components/ButtonLink";
import axios from 'axios';

import styles from './WriteToRaise.module.scss';
import cx from 'classnames';

const WtrSchool = ({ school }) => {
    const client = useApolloClient();

    const [formErrors, setFormErrors] = useState([]);


    const impersonateTeacher = async (id) => {
        const { data } = await client.query({
            query: IMPERSONATE_QUERY,
            variables: {
                id,
                userType: 'teacher'
            },
            fetchPolicy: 'network-only'
        });
        if (data.loginAs) {
            const { loginAs } = data;
            window.open(`${getUrl(APPS.go_portal_teacher)}/impersonate/${id}/${loginAs}`);
        }
    }

    const [getCSV, { loading: csvLoading }] = useLazyQuery(WTR_SCHOOL_CSV, {
        variables: {
            schoolId: school.id
        },
        onCompleted: (data) => {
            axios({
                method: 'get',
                url: data.schoolWtrCsv,
                responseType: 'blob'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `parents_order_summary`;
                link.setAttribute('download', fileName + '.csv');
                document.body.appendChild(link);
                link.click();
            }));
        }
    })

    const getBookLabel = status => {
        switch (status) {
            case BOOK_STATUS.completed:
                return 'Completed';
            case BOOK_STATUS.started:
                return 'In progress';
            case BOOK_STATUS.printOrdered:
                return 'Published';
            default:
                return '-';
        }
    }

    const [createSchoolPrintBookOrder, { loading }] = useMutation(CREATE_SCHOOL_PRINT_BOOK_ORDER, {
        onCompleted: ({ createSchoolPrintBookOrder }) => {
            if (createSchoolPrintBookOrder.errors && createSchoolPrintBookOrder.errors.length > 0) {
                setFormErrors(createSchoolPrintBookOrder.errors);
                return
            }
        },
        refetchQueries: [{ query: WTR_SCHOOLS_WITH_BOOKS }]
    });

    return (
        <li className={styles.school}>
            <div className={styles.schoolHeader}>
                <h1 className={styles.schoolHeaderMain}><Link to={`/school/${school.id}`}>{school.name}</Link></h1>
                <div className={styles.schoolHeaderSide}>
                <button className={cx(styles.csvBtn)} onClick={() => getCSV()}> Orders CSV</button>
                    {school.printBookOrders.filter(order => order.orderType === 2).length === 0 ?
                        <button disabled={loading} onClick={() => createSchoolPrintBookOrder({
                            variables: {
                                schoolId: school.id
                            }
                        })}>{loading ? 'Saving' : 'Create order'}</button> :
                        <ButtonLink className="link-underline u-text-primary" to={`/orders/${school.printBookOrders.find(o => o.orderType === 2).id}`}>View order</ButtonLink>
                    }
                </div>
            </div>
            {formErrors && <FormErrors errors={formErrors} />}
            <div className="u-m-base-2">
                <span className={cx("u-m-right-3", styles.schoolStats)}>Sales: {school.wtrSales}</span>
                <span className={cx("u-m-right-3", styles.schoolStats)}>Total: £{(school.wtrParentsSales * 7.99).toFixed(2)}</span>
                <span className={cx("u-m-right-3", styles.schoolStats)}>Raised: £{(school.wtrParentsSales * (7.99 - 3)).toFixed(2)}</span>
            </div>
            {school.wtrBooks.map(book => (
                <div key={`book-${book.id}`} className={styles.schoolBook}>
                    <div className={styles.schoolBookHeader}>
                        <h2 className="u-m-base-0">{book.bookCode} - {book.studentsClass.name}</h2>
                        <span className={cx("label label-muted", styles.schoolBookStatus)}>{getBookLabel(book.status)}</span>
                    </div>
                    <ul className="u-m-base-1">
                        {book.studentsClass.teachers.map(teacher => (
                            <li key={`t-${teacher.id}`}>
                                {teacher.name} - {teacher.email}
                                <button className="btn-reset link-underline u-m-left-2" onClick={() => { impersonateTeacher(teacher.id) }}>Impersonate</button>
                            </li>
                        ))}
                    </ul>
                    <div>
                        <span className={cx(" u-m-right-3", styles.schoolStats)}>Sales: {book.wtrSales.total}</span>
                        <span className={cx("u-m-right-3", styles.schoolStats)}>Class order: {book.wtrSales.classSales}</span>
                        <span className={cx("u-m-right-3", styles.schoolStats)}>Parent sales: {book.wtrSales.parentSales}</span>
                    </div>
                </div>
            ))}
        </li>
    )
}

export default WtrSchool