import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import cx from 'classnames';
import styles from './EditReadSubscription.module.scss';
import { UPDATE_READ_SUBSCRIPTION } from '../../../../../../services/school/graphql'
import { SUBSCRIPTION_STATUS } from '@axeedge/go-shared-utils';

const EditReadSubscription = ({ subscription, closeModal }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [updateGoReadSubscription, { loading }] = useMutation(UPDATE_READ_SUBSCRIPTION, {
        update: (_, { data }) => {
            if (data.updateGoReadSubscription.errors && data.updateGoReadSubscription.errors.length !== 0) {
                setFormErrors(data.updateGoReadSubscription.errors);
                return;
            }
            if (data.updateGoReadSubscription.schoolSubscription.id) {
                closeModal();
            }
        }
    });

    const { register, handleSubmit } = useForm();

    const onSubmit = values => {
        const { active } = values;
        updateGoReadSubscription({
            variables: {
                schoolSubscriptionId: subscription.id,
                status: active ? SUBSCRIPTION_STATUS.active : SUBSCRIPTION_STATUS.cancelled
            }
        })
    }

    return(
        <Fragment>
            <h2 className='u-m-base-2'>Read subscription</h2>
            <h3>{subscription.status === SUBSCRIPTION_STATUS.cancelled ? 'Activate' : 'Cancel/Suspend'} this subscription:</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group u-m-top-2">
                    <div className={cx('basic-form__switch', styles.subscriptionReadToggle)}>
                        <input
                            id="active"
                            className={cx('switch-input', styles.subscriptionReadSwitch)}
                            type="checkbox"
                            name="active"
                            ref={register}
                            defaultChecked={subscription.status === SUBSCRIPTION_STATUS.active}
                        />
                        <label htmlFor="active" className={cx('switch-label', styles.subscriptionReadLabel)}></label>
                    </div>
                </div>
                {formErrors && <FormErrors errors={formErrors} />}
                <Button type='submit' className='u-m-right-1'>{loading ? 'Saving' : 'Update'}</Button>
                <Button type='button' outline onClick={() => closeModal()}>Cancel </Button>
            </form>
        </Fragment>
    )
}

export default EditReadSubscription;