import { APPS } from '@axeedge/go-shared-utils';

export const APP_NAME = APPS.go_admin;

export const SOURCE_INVOICES = {
    boom_writer_plus: 1,
    boom_reader: 2,
    boom_math: 3,
    boom_writer_packs: 4,
    data_dash: 5,
    wonde: 6
}