import React from 'react';
import { Delete, PlusCircle } from 'react-feather';
import { useMutation } from '@apollo/react-hooks';
import cx from 'classnames';

import { ADD_CHAPTER_TO_BOOK_MUTATION, DELETE_CHAPTER_MUTATION, GET_BOOK_QUERY } from '../../services/graphql';
import styles from './BookSidebar.module.scss';

const BookSidebar = ({ book, selectedChapter, onSelectChapter }) => {
    const [addChapter, { loading }] = useMutation(ADD_CHAPTER_TO_BOOK_MUTATION, {
        variables: {
            bookTemplateId: book.id,
            title: `Chapter ${book.chapters.length + 1}`,
            description: '',
            content: '',
            guidance: '',
            orderid: book.chapters.length
        },
        refetchQueries: [{ query: GET_BOOK_QUERY, variables: { bookTemplateId: book.id } }],
        awaitRefetchQueries: true
    });

    const [deleteChapter] = useMutation(DELETE_CHAPTER_MUTATION, {
        update: (_, { data }) => {
            if (data.deleteBookTemplateChapter.errors && data.deleteBookTemplateChapter.errors.length) {
                alert(data.deleteBookTemplateChapter.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_BOOK_QUERY, variables: { bookTemplateId: book.id } }],
        awaitRefetchQueries: true
    })

    const onDeleteChapter = chapter => {
        let goAhead = window.confirm(`Are you sure you want to delete ${chapter.title}?`);
        if (goAhead) {
            deleteChapter({
                variables: {
                    bookTemplateChapterId: chapter.id
                }
            })
        }
    }

    return (
        <div className={styles.bookSidebar}>
            <div 
                className={cx(styles.bookSidebarOption, { [styles.selected]: selectedChapter === -1 })}
            >
                <p className={styles.bookSidebarName} onClick={() => onSelectChapter(-1)}>Book Settings</p>
            </div>
            {
                book.chapters.map(chapter => {
                    return (
                        <div 
                            key={`chap-${chapter.orderid}`}
                            className={cx(styles.bookSidebarOption, { [styles.selected]: selectedChapter === chapter.orderid }, {[styles.disabled]: !book.id} )}
                        >
                            <p className={styles.bookSidebarName} onClick={() => onSelectChapter(chapter.orderid)}>{chapter.title}</p>
                            {chapter.orderid > 1 && chapter.orderid === (book.chapters.length - 1) && <Delete className={styles.deleteButton} onClick={() => onDeleteChapter(chapter)} />}
                        </div>
                    )
                })
            }
            {/* {
                book.id && !book.isSingleChapter && (
                    <div 
                        className={cx(styles.bookSidebarOption, styles.add)}
                        onClick={() => {!loading && addChapter()}}
                    >
                        <PlusCircle />
                        <p className="u-m-left-1">{loading ? "Adding..." : "Add Chapter"}</p>
                    </div>
                )
            } */}
        </div>
    );
}

export default BookSidebar;
