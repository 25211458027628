import React from "react";
import styles from '../Invoices.module.scss';


const escapeRegExp = (str = '') => (
    str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
);

const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
        return <span>{text}</span>
    }
    const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
        <span>
            {parts.filter(part => part).map((part, i) => (
                regex.test(part) ? <mark className={styles.markTerm} key={i}>{part}</mark> : <span key={i}>{part}</span>
            ))}
        </span>
    )
}

export default Highlighted;