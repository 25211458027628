import React, { useEffect, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { getUrl, APPS } from '@axeedge/go-shared-utils';
import { FORCED_LOGOUT, ERROR } from '../services/graphql';
import ErrorPage from '../../../components/ErrorPage';
import { AuthContext } from '../../../services/user/AuthProvider';

const RouteCapture = ({ children }) => {

    const client = useApolloClient();

    const { data: fl } = useQuery(FORCED_LOGOUT);
    const { data: clientError } = useQuery(ERROR);

    const { setCurrentUser } = useContext(AuthContext);


    const forceLogout = () => {
        ls.clear();
        client.clearStore();
        setCurrentUser(null);
        window.location.href = `${getUrl(APPS.go_admin)}`;
    }

    useEffect(() => {
        if (ls('go_educator_user') && ls('go_educator_user') === 'undefined') {
            forceLogout();
        }
    });

    if (fl && fl.forcedLogout) {
        forceLogout();
    }

    if (clientError && clientError.error) {
        return <ErrorPage />
    }

    return children || null;
}

export default RouteCapture;