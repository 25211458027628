import React, { Fragment } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { Loader } from '@axeedge/go-teacher-components';

import ListItem from '../../../../components/ListItem';
import BackLink from '../../../../components/BackLink';

import ButtonLink from '../../../../components/ButtonLink';
import { GET_BOOK_TYPES_QUERY } from '../../../../services/pack/graphql';
import { DELETE_TEMPLATE_CATEG_MUTATION } from '../../services/graphql';
import styles from './PacksHome.module.scss';

const PacksHome = () => {
    const {data, error, loading} = useQuery(GET_BOOK_TYPES_QUERY);
    
    const [deleteTemplateCateg] = useMutation(DELETE_TEMPLATE_CATEG_MUTATION, {
        update: (_, { data }) => {
            if (data.deleteBookTemplateCategory.errors && data.deleteBookTemplateCategory.errors.length) {
                alert(data.deleteBookTemplateCategory.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_BOOK_TYPES_QUERY}],
        awaitRefetchQueries: true
    });


    const onDeleteCateg = categ => {
        let goAhead = window.confirm(`Are you sure you want to delete ${categ.name}?`);
        if (goAhead) {
            deleteTemplateCateg({
                variables: {
                    bookTemplateCategoryId: categ.id
                }
            });
        }
    }

    return (
        <Fragment>
            <BackLink to='/' text='Main Menu' />

            <div className={styles.sectionHeader}>
                <h1>Book Types</h1>
                <ButtonLink to='/pack/new'>Add A Book Type</ButtonLink>
            </div>
            <div>
                {error && <p>{error.message}</p>}
                {loading && <Loader />}
                {data && data.bookTemplateCategories && data.bookTemplateCategories.length ? (
                    <div className={styles.sectionList}>
                        {
                            data.bookTemplateCategories.map(pack => {
                                return (
                                    <ListItem
                                        key={`pack-${pack.id}`}
                                        linkPath={`/pack/${pack.id}`}
                                        mainText={pack.name}
                                        onDelete={() => onDeleteCateg(pack)}
                                    />
                                )
                            })
                        }
                    </div>
                ) : data && <p>No book types</p>}
            </div>
        </Fragment>
    )
}

export default PacksHome;
