import React, { useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { DELETE_TEACHER, FIND_TEACHER_QUERY } from './services/graphql';
import { IMPERSONATE_QUERY } from '../../services/auth/graphql';
import MoveTeacher from '../School/scenes/SchoolHome/scenes/Staff/components/MoveTeacher';
import { Link } from '@reach/router';
import { Button, Modal } from '@axeedge/go-teacher-components';
import { APPS, getUrl } from '@axeedge/go-shared-utils';
import { Move } from 'react-feather';
import cx from 'classnames';
import SaveConfirm from '../../components/SaveConfirm';


import styles from './FindParent.module.scss';

const FindTeacher = () => {

    const [teacherEmail, setTeacherEmail] = useState('');

    const [teachers, setTeachers] = useState([]);
    const [moveTeacher, setMoveTeacher] = useState(null);

    const [formErrors, setFormErrors] = useState(null);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [deleteTeacher, setDeleteTeacher] = useState(null);

    const client = useApolloClient();

    const findTeachers = async () => {
        const { data } = await client.query({
            query: FIND_TEACHER_QUERY,
            variables: { email: teacherEmail },
            fetchPolicy: 'network-only'
        });
        setTeachers(data.searchTeacher);
    }

    const impersonateTeacher = async (id) => {
        const { data } = await client.query({
            query: IMPERSONATE_QUERY,
            variables: {
                id,
                userType: 'teacher'
            },
            fetchPolicy: 'network-only'
        });
        if (data.loginAs) {
            const { loginAs } = data;
            window.open(`${getUrl(APPS.go_portal_teacher)}/impersonate/${id}/${loginAs}`);
        }
    }

    const [deleteTeacherAccount, { loading: deletingTeacher }] = useMutation(DELETE_TEACHER, {
        onCompleted: data => {
            if (data && data.deleteTeacher && data.deleteTeacher.errors && data.deleteTeacher.errors.length > 0) {
                setFormErrors(data.deleteTeacher.errors[0]);
                return;
            }
            if (data && data.deleteTeacher?.deleted) {
                setShowSaveConfirm(true);
                setTeachers([]);
                setDeleteTeacher(null);
            }
        },
    })

    const handleSubmit = e => {
        e.preventDefault();
        findTeachers();
    }


    return (
        <div>

            <h1 className='u-m-base-3 u-m-top-2'>Find Teachers</h1>
            {showSaveConfirm && <SaveConfirm hideConfirm={() => setShowSaveConfirm(false)} message={'Teacher deleted!'} />}
            {formErrors && <p>{formErrors}</p>}

            <form onSubmit={handleSubmit}>
                <div className="basic-form__group">
                    <input
                        onChange={e => setTeacherEmail(e.target.value)}
                        value={teacherEmail}
                        type='email'
                        placeholder='Teacher Email'
                        className={cx('basic-form__text-box', 'u-m-right-2', styles.formInput)}
                    />
                    <Button type='submit'>Find</Button>
                </div>
            </form>
                <div className={styles.listing}>
                    {
                        teachers.length > 0 && teachers.map(teacher => (
                            <div key={`t-${teacher.id}`} className='card u-m-base-3'>
                                <div className="card-body">
                                    <div className='u-display-flex u-justify-between u-m-base-1'>
                                        <h3 className='light'> <b>{teacher.firstName} {teacher.lastName}</b> ({teacher.displayName} {teacher.isSchoolAdmin && <span className='u-m-left-1'>- School admin</span>})</h3>
                                        {teacher.active ? <span className='label label-primary u-display-flex u-align-center'>Active</span> : <span className='label label-muted u-display-flex u-align-center'>Inactive</span>}
                                    </div>
                                    {teacher.school && <h3 className='u-m-base-1'>School: <Link className={styles.link} to={`/school/${teacher.school.id}`} >{teacher.school.name}</Link></h3>}

                                    {teacher.activationLink && <p className='u-m-base-1'>Activation link: <a href={teacher.activationLink}>{teacher.activationLink}</a></p>}

                                    <div className='u-m-top-3 u-m-base-2'>
                                        <Button className='u-m-right-2' onClick={() => impersonateTeacher(teacher.id)}>Impersonate</Button>
                                        <Button outline onClick={() => setMoveTeacher(teacher)}><Move size="18" /> Move Teacher</Button>
                                    </div>
                                    <Button outline onClick={() => setDeleteTeacher(teacher)}>Delete teacher</Button>
                                </div>
                            </div>
                        ))
                    }
            </div>

            {moveTeacher &&
                <Modal closeModal={() => setMoveTeacher(null)}>
                    <MoveTeacher closeModal={() => setMoveTeacher(null)} teacher={moveTeacher} />
                </Modal>
            }

            {deleteTeacher && (
                <Modal closeModal={() => setDeleteTeacher(null)}>
                    <div className="u-text-center">
                        <h2 className='u-m-base-3'>Are you sure you want to delete {deleteTeacher.firstName} {deleteTeacher.lastName}?</h2>

                        <Button disabled={deletingTeacher} onClick={() => deleteTeacherAccount({
                            variables: {
                                teacherId: deleteTeacher.id
                            }
                        })}>Yes</Button>
                        <Button outline className='u-m-left-2' onClick={() => setDeleteTeacher(null)}>No</Button>
                    </div>
                </Modal>
            )}

        </div>
    )
}

export default FindTeacher;