// Queries and mutations unique to the Dashboard module
import { gql } from 'apollo-boost';

// Schools
export const DELETE_SCHOOL_MUTATION = gql`
mutation deleteSchool($schoolId: ID!) {
    deleteSchool(schoolId: $schoolId) {
        deleted
        errors
    }
}
`;

// Schools
export const EXPORT_TEACHER_LIST = gql`
query exportTeacherList($adminOnly: Boolean!) {
    exportTeacherList(adminOnly: $adminOnly)
}
`;

export const WONDE_SCHOOLS = gql`
query wondeSchools {
    wondeSchools {
        id
        name
        wondeAccessApprovedAt
        wondeSyncStep
    }
}
`;

export const MAIN_SEARCH_SCHOOLS = gql`
query schoolsSearch($text: String!) {
    schoolsSearch(text: $text) {
        id
        name
        address
        address2
        zip
        city
        canAccessWonde
        country {
            id
            name
        }
        schoolCode
    }
}
`;