import React, { Fragment } from 'react';
import { Calendar } from 'react-feather';
import { groupPurchasedPacks, invoiceStatsByKey } from '../../../../services/utils';
import moment from 'moment';

import styles from '../../ViewInvoice.module.scss';

const InvoiceSummaryWrite = ({ invoice }) => {
    return (
        <Fragment>
            <h3 className='u-m-base-3'>{invoice.source === 'GoWrite' ? 'Write+ ' : 'Book packs (Go Write)'}</h3>
            <ul className={styles.invoiceOfferDetails}>
                <li className='u-m-base-2'>
                    <div>Ordered: <Calendar size="18" /> {moment(new Date(invoice.orderedAt.substring(0, 10))).format('DD MMM YYYY')}</div>
                    <div>Year:{invoice.academicYear}</div>
                </li>
                <li className='u-m-base-2'>Purchase order no: {invoice.purchaseOrderReferenceNumber || '--'}</li>
                {invoice.source === 'BoomWriter Packs' &&
                    <li>
                        <div>Total books:</div>
                        <div>{invoiceStatsByKey(invoice.purchasedPacks, 'maxNumberOfStudents')}</div>
                    </li>
                }
                <li className={styles.invoiceOfferDetailsTotal}>
                    <div>Total price:</div>
                    <div>£{invoice.amount}</div>
                </li>
                {
                    groupPurchasedPacks(invoice.purchasedPacks).map(groupedPacks => (
                        <li className={styles.invoiceOfferDetailsPack} key={`grouped-${groupedPacks.id}`}>
                            <div className={styles.invoiceOfferDetailsMain}>
                                <div>{groupedPacks.packs.length} x {groupedPacks.title}</div>
                                <div>£{groupedPacks.price * groupedPacks.packs.length}</div>
                            </div>
                            {invoiceStatsByKey(groupedPacks.packs, 'extraStudents') > 0 && (
                                <div className={styles.invoiceOfferDetailsExtra}>
                                    <div>{invoiceStatsByKey(groupedPacks.packs, 'extraStudents')} x extra copies</div>
                                    <div>£{invoiceStatsByKey(groupedPacks.packs, 'extraAmount').toFixed(2)}</div>
                                </div>
                            )}
                        </li>
                    ))
                }
            </ul>
        </Fragment >
    )
}

export default InvoiceSummaryWrite;