import { BOOK_ORDER_STATUS, BOOK_ORDER_TYPE } from '@axeedge/go-shared-utils';

// order status label
export const getOrderStatusLabel = status => {
    switch(status) {
        case BOOK_ORDER_STATUS.pending:
            return 'Pending';
        case BOOK_ORDER_STATUS.new:
            return 'New';
        case BOOK_ORDER_STATUS.to_printer:
            return 'With Printer';
        case BOOK_ORDER_STATUS.delivered:
            return 'Shipped';    
        default:
            return '-';
    }
}


// order type label
export const getOrderTypeLabel = type => {
    switch(type) {
        case BOOK_ORDER_TYPE.class:
            return 'Class';
        case BOOK_ORDER_TYPE.parent:
            return 'Parent'; 
        case 2:
            return 'School'
        default:
            return '-';
    }
}

// set order status filters
export const ordersNavFilters = Object.entries(BOOK_ORDER_STATUS)
    .map(entry =>  {
        return { value : entry[1], label: getOrderStatusLabel(entry[1]) }
    });

// set order status options
export const getStatusSelectOpts = Object.entries(BOOK_ORDER_STATUS)
    .filter(e => e[1] > BOOK_ORDER_STATUS.new )
    .map(entry =>  {
        return { value : entry[1], label: getOrderStatusLabel(entry[1]) }
    });


// list order copies from json
export const getOrderCopies = order => {
    const copies = [];
    Object.entries(JSON.parse(order.orderDetails)).map(e => copies.push({
        id: e[0],
        copies: e[1],
        student: order.classBook.studentsClass.students.find(student => student.id === e[0]),
        bookCode: order.classBook.bookCode
    }))
    return copies
}


// sum all order copies
export const getTotalOrderedCopies = order => {
    const items = [];
    Object.entries(JSON.parse(order.orderDetails)).map(e => items.push({copies: e[1]}));
    return items.map(item => item.copies).reduce((acc, value) => acc + +value, 0);
}


// get no. of students for this order pack
export const getPackStudentsCounter = order => {
    const invoicePacks = [];
    order.invoiceRequest.purchasedPacks.forEach(p => Object.entries(JSON.parse(p.status)).map(e => invoicePacks.push({orderId: e[1].order_id, maxNumberOfStudents: e[1].counter})))
    return invoicePacks.find(p => p.orderId === order.id).maxNumberOfStudents
}