import React, { useState, useEffect } from "react";
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import BackLink from "../../components/BackLink";
import { Dropdown, Loader } from '@axeedge/go-teacher-components';
import { useQuery } from "@apollo/react-hooks";
import { WTR_SCHOOLS } from "./services/graphql";
import { Link } from "@reach/router";

import styles from './WriteToRaise.module.scss';
import WtrStats from "./WtrStats";
import WtrShownInterest from "./WtrShownInterest";

const TABS = {
    signedup: 1,
    interest: 2
}

const SORT_OPTS = {
    az: 1,
    mostStarted: 2,
    leastStarted: 3,
    mostPublished: 4,
    leastPublished: 5,
    mostPurchased: 6,
    leastPurchased: 7
}

const WriteToRaise = () => {

    const [selectedTab, setSelectedTab] = useState(TABS.signedup);
    const [sortOption, setSortOption] = useState(SORT_OPTS.az);
    const [sortOrder, setSortOrder] = useState('desc');

    const [wtrSchools, setWtrSchools] = useState([]);
    const [visibleWtrSchools, setVisibleWtrSchools] = useState(wtrSchools);

    const viewOptions = [
        { text: 'A-Z', type: SORT_OPTS.az, action: () => setSortOption(SORT_OPTS.az) },
        { text: 'Most books started', type: SORT_OPTS.mostStarted, action: () => { setSortOption(SORT_OPTS.mostStarted); setSortOrder('desc') } },
        { text: 'Least books started', type: SORT_OPTS.leastStarted, action: () => { setSortOption(SORT_OPTS.leastStarted); setSortOrder('asc') } },
        { text: 'Most books published', type: SORT_OPTS.mostPublished, action: () => { setSortOption(SORT_OPTS.mostPublished); setSortOrder('desc') } },
        { text: 'Least books published', type: SORT_OPTS.leastPublished, action: () => { setSortOption(SORT_OPTS.leastPublished); setSortOrder('asc') } },
        { text: 'Most books purchased', type: SORT_OPTS.mostPurchased, action: () => { setSortOption(SORT_OPTS.mostPurchased); setSortOrder('desc') } },
        { text: 'Least books purchased', type: SORT_OPTS.leastPurchased, action: () => { setSortOption(SORT_OPTS.leastPurchased); setSortOrder('asc') } },
    ]
    const viewByTitle = viewOptions.find(o => o.type === sortOption).text || '';

    const { data, loading, error } = useQuery(WTR_SCHOOLS);

    useEffect(() => {
        if (data && data.writeToRaiseSchools) {
            setWtrSchools(data.writeToRaiseSchools)
        }
    }, [data])


    useEffect(() => {
        switch (sortOption) {
            case SORT_OPTS.az:
                setVisibleWtrSchools([...wtrSchools].sort((a, b) => a.name.localeCompare(b.name)));
                break;
            case SORT_OPTS.mostStarted:
            case SORT_OPTS.leastStarted:
                setVisibleWtrSchools([...wtrSchools].sort((a, b) => sortOrder === 'desc' ? b.wtrBooksStartedCount - a.wtrBooksStartedCount : a.wtrBooksStartedCount - b.wtrBooksStartedCount));
                break;
            case SORT_OPTS.mostPublished:
            case SORT_OPTS.leastPublished:
                setVisibleWtrSchools([...wtrSchools].sort((a, b) => sortOrder === 'desc' ? b.wtrBooksPublishedCount - a.wtrBooksPublishedCount : a.wtrBooksPublishedCount - b.wtrBooksPublishedCount));
                break;
            case SORT_OPTS.mostPurchased:
            case SORT_OPTS.leastPurchased:
                setVisibleWtrSchools([...wtrSchools].sort((a, b) => sortOrder === 'desc' ? b.wtrBooksPurchasedCount - a.wtrBooksPurchasedCount : a.wtrBooksPurchasedCount - b.wtrBooksPurchasedCount));
                break;
            default:
                setVisibleWtrSchools(wtrSchools);
                break;
        }
    }, [sortOption, wtrSchools, setVisibleWtrSchools]);

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data && data.writeToRaiseSchools && visibleWtrSchools) {

        return (
            <>
                <BackLink to='/' text='Main Menu' />
                <div className="u-display-flex u-justify-between u-align-center">
                    <h1>Write to Raise</h1>
                    {selectedTab === TABS.signedup && (
                        <Dropdown
                            dropdownOpts={viewOptions}
                            titleLeft={`View by ${viewByTitle}`}
                            classNames='u-m-left-3 u-text-left'
                        />
                    )}
                </div>
               <WtrStats />
                <Tabs className='u-m-top-3 u-m-base-3'>
                    <Tab selected={selectedTab === TABS.signedup} onClick={() => setSelectedTab(TABS.signedup)}>Schools signed Up</Tab>
                    <Tab selected={selectedTab === TABS.interest} onClick={() => setSelectedTab(TABS.interest)}>Shown Interest</Tab>
                </Tabs>

                {selectedTab === TABS.signedup && (
                    <>
                        <table className={styles.wtrTable}>
                            <thead>
                                <tr>
                                    <th className={styles.wtrTableName}></th>
                                    <th>Participating classes</th>
                                    <th>Participating pupils</th>
                                    <th>Books <br />started</th>
                                    <th>Books published</th>
                                    <th>Books purchased</th>
                                </tr>
                            </thead>
                            <tbody>
                                {visibleWtrSchools.map(school => (
                                    <tr key={school.id}>
                                        <td className={styles.wtrTableName}><Link to={`/school/${school.id}`}>{school.name}</Link></td>
                                        <td>{school.wtrParticipatingClassesCount}</td>
                                        <td>{school.wtrParticipatingPupilsCount}</td>
                                        <td>{school.wtrBooksStartedCount}</td>
                                        <td>{school.wtrBooksPublishedCount}</td>
                                        <td>{school.wtrBooksPurchasedCount}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </>
                )}

                {selectedTab === TABS.interest && (
                    <>
                      <WtrShownInterest />
                    </>
                )}
            </>
        )
    }
    return null;
}

export default WriteToRaise