import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import BackLink from '../../../../components/BackLink';
import OrderItem from './components/OrderItem';

import { GET_ORDERS } from '../../services/graphql';
import styles from './ListOrders.module.scss';
import { Search } from 'react-feather';
import { BOOK_ORDER_STATUS } from '@axeedge/go-shared-utils';
import { ordersNavFilters } from '../../services/utils';
import cx from 'classnames';
import { parse } from "query-string";
import { useLocation } from 'react-use';

const ORDERS_ALL = -2;
const ORDERS_CANCELLED = -3;
const INCLUDE_CANCELED = {
    all: null, // all orders
    no: 'N', // only active
    only: 'Y' // only cancelled
}

const PAGE_SIZE = 20;

const OrdersHome = () => {

    const location = useLocation();

    const [orders, setOrders] = useState([]);

    const [searchCode, setSearchCode] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [showLoadMore, setShowLoadMore] = useState();

    const [filter, setFilter] = useState({
        page: 1,
        status: parse(location.search).status ? +(parse(location.search).status) : BOOK_ORDER_STATUS.new,
        cancelled: parse(location.search).status && +(parse(location.search).status) === ORDERS_CANCELLED ? INCLUDE_CANCELED.only: +(parse(location.search).status) === ORDERS_ALL ? INCLUDE_CANCELED.all : INCLUDE_CANCELED.no
    })

    const [loadOrders, { loading, data, error, fetchMore }] = useLazyQuery(GET_ORDERS, {
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (data && data.printBookOrdersQuery && filter.page === 1) {
            setOrders(data.printBookOrdersQuery);
            setShowLoadMore(data.printBookOrdersQuery.length === PAGE_SIZE);
        }
    }, [data, filter.page]);

    useEffect(() => {
        if (filter.page === 1) {
           loadOrders({
                variables: {
                    status: filter.status !== ORDERS_ALL && filter.status !== ORDERS_CANCELLED ? filter.status : null,
                    page: filter.page,
                    cancelled: filter.cancelled
                }
            });
            window.history.pushState('', '', '?status=' + filter.status);
        }
    }, [loadOrders, filter]);

    const handleSearch = (e, type) => {
        e.preventDefault();
        loadOrders({
            variables: {
                page: 1,
                search: type === 'term' ? searchTerm : null,
                bookCode: type === 'code' ? searchCode : null
            }
        });
    }

    const loadMore = () => {
        fetchMore({
            variables: {
              page: filter.page + 1
            },
            updateQuery: (_, { fetchMoreResult }) => {
                if (fetchMoreResult.printBookOrdersQuery.length < PAGE_SIZE) setShowLoadMore(false);
                if (fetchMoreResult.printBookOrdersQuery.length > 0) {
                    setFilter({...filter, page: filter.page + 1})
                    setOrders(orders.concat(fetchMoreResult.printBookOrdersQuery));
                }
            }
        })
    }

    if (error) {
        return <p>{error.message}</p>
    }

    return (
        <div className='u-m-top-3'>
            <BackLink to='/' text='Main Menu' />
            <div className={styles.orders}>
                <div>
                    Filter by Code:
                    <form onSubmit={(e) => handleSearch(e, 'code')} className={cx(styles.ordersSearch, styles.ordersSearchCode)}>
                        <input type="text" placeholder="Book Code" className='basic-form__text-box' value={searchCode} onChange={(e) => setSearchCode(e.currentTarget.value)} />
                        <button className={styles.ordersSearchBtn}><Search size='18' /></button>
                    </form>
                    Search:
                    <form onSubmit={(e) => handleSearch(e, 'term')} className={styles.ordersSearch}>
                        <input type="text" placeholder="Order/Email/School name" className='basic-form__text-box' value={searchTerm} onChange={(e) => setSearchTerm(e.currentTarget.value)} />
                        <button className={styles.ordersSearchBtn}><Search size='18' /></button>
                    </form>
                </div>
                <div className={styles.ordersList}>
                    <div className={styles.ordersListNav}>
                        <ul>
                            <li onClick={() => setFilter({...filter, page: 1, status: ORDERS_ALL, cancelled: null })} className={cx({[styles.ordersListNavActive]: filter.status === ORDERS_ALL})} >All</li>
                            {
                                ordersNavFilters.map(opt => (
                                    <li onClick={() => setFilter({...filter, page: 1, status: opt.value, cancelled: 'N'})} className={cx({[styles.ordersListNavActive]: filter.status === opt.value})} key={`opt-${opt.value}`}>{opt.label}</li>
                                ))
                            }
                            <li onClick={() => setFilter({...filter, page: 1, status: ORDERS_CANCELLED, cancelled: 'Y' })} className={cx({[styles.ordersListNavActive]: filter.status === ORDERS_CANCELLED})} >Cancelled</li>
                        </ul>
                    </div>
                    <div className={styles.ordersListMain}>
                        { 
                            orders.length > 0 ? (
                                <>
                                    {orders.map(order => <OrderItem key={order.id} order={order} />)}
                                    {showLoadMore && <button onClick={() => loadMore()} className={styles.ordersLoadMore}>{loading ? 'Loading' : 'Load more'}</button>}
                                </>
                            ) :  <p>No orders match</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrdersHome;