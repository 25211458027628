// Queries and mutations specifically for the Classes Scene
import { gql } from 'apollo-boost';


export const SEARCH_SCHOOLS = gql`
query schoolsSearch($text: String!) {
    schoolsSearch(text: $text) {
        id
        name
        city
        address
        classes {
            id  
            name
            description
            teachers {
                id  
                displayName
            }
        }
    }
}
`;

export const ADD_STUDENTS_FROM_CLASSES_TO_ANOTHER_CLASS = gql`
mutation addStudentsFromClassesToAnotherClass($studentsClassesIds: [ID!]!, $targetStudentsClassId: ID!) {
    addStudentsFromClassesToAnotherClass(studentsClassesIds: $studentsClassesIds, targetStudentsClassId: $targetStudentsClassId) {
        errors
        targetStudentsClass {
            id
            name
            students {
                id
                name
            }
        }
    }
}
`;

export const ADD_CLASS_MUTATION = gql`
mutation createStudentsClass($name: String!, $description: String!, $schoolId: ID!) {
    createStudentsClass(name: $name, description: $description, schoolId: $schoolId) {
        studentsClass {
            id
            name
            description
            teachers {
                id
                displayName
                active
            }
            classPacks {
                id
                purchasedPack {
                    id
                    pack {
                        id
                        name
                    }
                    
                }
                bookTemplate {
                    id
                }
            }
        }
        errors
    }
}
`;

export const EDIT_CLASS_MUTATION = gql`
mutation editStudentsClass($name: String!, $description: String!, $schoolId: ID!, $studentsClassId: ID!) {
    editStudentsClass(name: $name, description: $description, schoolId: $schoolId, studentsClassId: $studentsClassId) {
        studentsClass {
            id
        }
        errors
    }
}
`;

export const ADD_CLASS_TEACHER_MUTATION = gql`
mutation addClassTeacher($studentsClassId: ID!, $teacherId: ID!) {
    addClassTeacher(studentsClassId: $studentsClassId, teacherId: $teacherId) {
        studentsClass {
            id
            name
            description
            teachers {
                id
                displayName
                active
            }
        }
        errors
    }
}
`;

export const UNARCHIVE_CLASS = gql`
mutation unarchiveStudentsClass($studentsClassId: ID!) {
    unarchiveStudentsClass(studentsClassId: $studentsClassId) {
        studentsClass {
            id
            name
            description
            archived
            teachers {
                id
                displayName
                active
            }
        }
        errors
    }
}
`;