import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Loader } from '@axeedge/go-teacher-components';

import { GET_BOOK_QUERY } from '../services/graphql';
import BookHolder from './BookHolder';

const BookQuery = ({ backLink, bookId, bookTemplateCategoryId }) => {
    const { data, error, loading } = useQuery(GET_BOOK_QUERY, {
        variables: {
            bookTemplateId: bookId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data.bookTemplate) {
        return <BookHolder book={{ ...data.bookTemplate, bookTemplateCategoryId }} isNew={false} backLink={backLink} />
    }
}

export default BookQuery;