import React from 'react';
import cx from 'classnames';
import { Link } from '@reach/router';
import styles from './ButtonLink.module.scss';

const ButtonLink = ({ children, disabled, fullWidth, large, primary, to, state }) => {
    return (
        <Link 
            disabled={disabled} 
            to={to} 
            state={state && state}
            className={cx(styles.button, {[styles.buttonFullWidth]: fullWidth}, {[styles.buttonLarge]: large}, {[styles.buttonPrimary]: primary})}
        >
            {children}
        </Link>
    )
}

export default ButtonLink;
